import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../Store/pageSlice";

const GdprPrivacyPolicy = () => {
  const currentYear = new Date().getFullYear();
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage("gdpr-privacy-policy"));
  }, [dispatch]);

  return (
    <div className={`${isDarkMode ? "darkGreyMode" : "lightGreyMode"} pt-5`}>
      <div className="container footer_adjust mt-5 gdpr-privacy-policy">
        <div className="row">
          <div className="col-md-12 mt-3 gdpr-section">
            <h2 className="text-center mb-3 title">Cookie Settings</h2>
            <br />
            <p >Our Privacy Policy was last updated on July 11, 2024</p>

            <p className='fontColorFCC'>
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You.
            </p>
            <p className='fontColorFCC'>
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy. This Privacy
              Policy was generated by{" "}
              <a
                href="https://www.termsfeed.com/privacy-policy-generator/"
                target="_blank"  rel="noopener noreferrer"
                className='fontColorCE1 text-decoration-none'>
                TermsFeed GDPR Privacy Policy Generator.
              </a>
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h3 className="gdpr-heading">Interpretation and Definitions</h3>
            <h5>Interpretation</h5>
            <p className='fontColorFCC'>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h5>Definitions</h5>
            <p>For the purposes of this Privacy Policy:</p>
            <ul className='fontColorFCC'>
              <li>
                "Account" means a unique account created for You to access our
                Service or parts of our Service.
              </li>

              <li>
                "Company" (referred to as either "the Company", "We", "Us" or
                "Our" in this Agreement) refers to Joulez Inc.,
                <p>
                  For the purpose of the GDPR, the Company is the Data
                  Controller.
                </p>
              </li>

              <li>"Country" refers to United States of America. </li>

              <li>
                "Cookies" are small files that are placed on Your computer,
                mobile device or any other device by a website, containing the
                details of Your browsing history on that website among its many
                uses.
              </li>

              <li>
                "Data Controller", for the purposes of the GDPR (General Data
                Protection Regulation), refers to the Company as the legal
                person which alone or jointly with others determines the
                purposes and means of the processing of Personal Data.
              </li>

              <li>
                "Device" means any device that can access the Service such as a
                computer, a cell phone or a digital tablet.
              </li>

              <li>
                "Personal Data" is any information that relates to an identified
                or identifiable individual.
              </li>

              <li>
                For the purposes of GDPR, Personal Data means any information
                relating to You such as a name, an identification number,
                location data, online identifier or to one or more factors
                specific to the physical, physiological, genetic, mental,
                economic, cultural or social identity.
              </li>

              <li>"Service" refers to the Website.</li>

              <li>
                "Service Provider" means any natural or legal person who
                processes the data on behalf of the Company. It refers to
                third-party companies or individuals employed by the Company to
                facilitate the Service, to provide the Service on behalf of the
                Company, to perform services related to the Service or to assist
                the Company in analyzing how the Service is used. For the
                purpose of the GDPR, Service Providers are considered Data
                Processors.
              </li>

              <li>
                "Usage Data" refers to data collected automatically, either
                generated by the use of the Service or from the Service
                infrastructure itself (for example, the duration of a page
                visit).
              </li>

              <li>
                "Website" refers to Joulez Inc., accessible from{" "}
                <a href="https://drivejoulez.com/" target="_blank"  rel="noopener noreferrer"
                className='fontColorCE1 text-decoration-none'>
                  https://drivejoulez.com
                </a>
              </li>

              <li>
                "You" means the individual accessing or using the Service, or
                the company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </li>
            </ul>
            <p className='fontColorFCC'>
              Under GDPR (General Data Protection Regulation), You can be
              referred to as the Data Subject or as the User as you are the
              individual using the Service.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h3 className="gdpr-heading">
              Collecting and Using Your Personal Data
            </h3>
            <h4 className="gdpr-subheading">Types of Data Collected</h4>
            <h5>Personal Data</h5>
            <p className='fontColorFCC'>
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
            </p>
            <ul className='fontColorFCC'>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>
                Address, Mailing Address, State, Province, ZIP/Postal code, City
              </li>
              <li>Usage Data</li>
            </ul>
          </div>

          <div className="col-md-12 gdpr-section">
            <h5>Usage Data</h5>
            <p className='fontColorFCC'>Usage Data is collected automatically when using the Service.</p>
            <p className='fontColorFCC'>
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
            </p>
            <p className='fontColorFCC'>
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
            </p>
            <p className='fontColorFCC'>
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h5>Tracking Technologies and Cookies</h5>
            <p className='fontColorFCC'>
              We use Cookies and similar tracking technologies to track the
              activity on Our Service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze Our Service. The
              technologies We use may include:
            </p>
            <ul className='fontColorFCC'>
              <li>
                <b className='fontcolorFFF'>Cookies or Browser Cookies.</b> A cookie is a small file
                placed on Your Device. You can instruct Your browser to refuse
                all Cookies or to indicate when a Cookie is being sent. However,
                if You do not accept Cookies, You may not be able to use some
                parts of our Service. Unless you have adjusted Your browser
                setting so that it will refuse Cookies, our Service may use
                Cookies.
              </li>
              <li>
                <b className='fontcolorFFF'>Web Beacons.</b> Certain sections of our Service and our
                emails may contain small electronic files known as web beacons
                (also referred to as clear gifs, pixel tags, and single-pixel
                gifs) that permit the Company, for example, to count users who
                have visited those pages or opened an email and for other
                related website statistics (for example, recording the
                popularity of a certain section and verifying system and server
                integrity).
              </li>
            </ul>

            <p className='fontColorFCC'> 
              Cookies can be "Persistent" or "Session" Cookies. Persistent
              Cookies remain on Your personal computer or mobile device when You
              go offline, while Session Cookies are deleted as soon as You close
              Your web browser.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <p className='fontColorFCC'>
              We use both Session and Persistent Cookies for the purposes set
              out below:
            </p>
            <ul>
              <li className="gdpr-section fontColorFCC">
                <h5 className="gdpr-subheading fontcolorFFF">
                  Necessary / Essential Cookies
                </h5>
                <p className="marginbottom-5">
                  <b className="fontcolorFFF">Type:</b> Session Cookies
                </p>
                <p className="marginbottom-5">
                  <b className="fontcolorFFF">Administered by:</b> Us
                </p>
                <p>
                  <b className="fontcolorFFF">Purpose:</b> These Cookies are essential to provide You
                  with services available through the Website and to enable You
                  to use some of its features. They help to authenticate users
                  and prevent fraudulent use of user accounts. Without these
                  Cookies, the services that You have asked for cannot be
                  provided, and We only use these Cookies to provide You with
                  those services.
                </p>
              </li>
              <li className="gdpr-section fontColorFCC">
                <h5 className="gdpr-subheading fontcolorFFF">
                  Cookies Policy / Notice Acceptance Cookies
                </h5>
                <p className="marginbottom-5">
                  <b className="fontcolorFFF">Type:</b> Persistent Cookies
                </p>
                <p className="marginbottom-5">
                  <b className="fontcolorFFF">Administered by:</b> Us
                </p>
                <p>
                  <b className="fontcolorFFF">Purpose:</b> These Cookies identify if users have accepted
                  the use of cookies on the Website.
                </p>
              </li>
              <li className="gdpr-section fontColorFCC">
                <h5 className="gdpr-subheading fontcolorFFF">Functionality Cookies</h5>
                <p className="marginbottom-5">
                  <b className="fontcolorFFF">Type:</b> Persistent Cookies
                </p>
                <p className="marginbottom-5">
                  <b className="fontcolorFFF">Administered by:</b> Us
                </p>
                <p>
                  <b className="fontcolorFFF">Purpose:</b> These Cookies allow us to remember choices You
                  make when You use the Website, such as remembering your login
                  details or language preference. The purpose of these Cookies
                  is to provide You with a more personal experience and to avoid
                  You having to re-enter your preferences every time You use the
                  Website.
                </p>
              </li>
              <li className="gdpr-section fontColorFCC">
                <h5 className="gdpr-subheading fontcolorFFF">
                  Tracking and Performance Cookies
                </h5>
                <p className="marginbottom-5">
                  <b className="fontcolorFFF">Type:</b> Persistent Cookies
                </p>
                <p className="marginbottom-5">
                  <b className="fontcolorFFF">Administered by:</b> Third-Parties
                </p>
                <p>
                  <b className="fontcolorFFF">Purpose:</b> These Cookies are used to track information
                  about traffic to the Website and how users use the Website.
                  The information gathered via these Cookies may directly or
                  indirectly identify you as an individual visitor. This is
                  because the information collected is typically linked to a
                  pseudonymous identifier associated with the device you use to
                  access the Website. We may also use these Cookies to test new
                  pages, features or new functionality of the Website to see how
                  our users react to them.
                </p>
              </li>
            </ul>

            <p className="fontColorFCC">
              For more information about the cookies we use and your choices
              regarding cookies, please visit our Cookies Policy or the Cookies
              section of our Privacy Policy.
            </p>
          </div>

          <div className="col-md-12 gdpr-section fontColorFCC">
            <h4 className="gdpr-subheading fontcolorFFF">Use of Your Personal Data</h4>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
              <li>
                <b className="fontcolorFFF">To provide and maintain our Service,</b> including to monitor
                the usage of our Service.
              </li>
              <li>
                <b className="fontcolorFFF">To manage Your Account:</b> to manage Your registration as a
                user of the Service. The Personal Data You provide can give You
                access to different functionalities of the Service that are
                available to You as a registered user.
              </li>
              <li>
                <b className="fontcolorFFF">For the performance of a contract:</b> the development,
                compliance and undertaking of the purchase contract for the
                products, items or services You have purchased or of any other
                contract with Us through the Service.
              </li>
              <li>
                <b className="fontcolorFFF">To contact You:</b> To contact You by email, telephone calls,
                SMS, or other equivalent forms of electronic communication, such
                as a mobile application's push notifications regarding updates
                or informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </li>
              <li>
                <b className="fontcolorFFF">To provide You with news,</b> special offers and general
                information about other goods, services and events which we
                offer that are similar to those that you have already purchased
                or enquired about unless You have opted not to receive such
                information.
              </li>
              <li>
                <b className="fontcolorFFF">To manage Your requests:</b> To attend and manage Your
                requests to Us.
              </li>
              <li>
                <b className="fontcolorFFF">For business transfers:</b> We may use Your information to
                evaluate or conduct a merger, divestiture, restructuring,
                reorganization, dissolution, or other sale or transfer of some
                or all of Our assets, whether as a going concern or as part of
                bankruptcy, liquidation, or similar proceeding, in which
                Personal Data held by Us about our Service users is among the
                assets transferred.
              </li>
              <li>
                <b className="fontcolorFFF">For other purposes:</b> We may use Your information for other
                purposes, such as data analysis, identifying usage trends,
                determining the effectiveness of our promotional campaigns and
                to evaluate and improve our Service, products, services,
                marketing and your experience.
              </li>
            </ul>
          </div>

          <div className="col-md-12 gdpr-section fontColorFCC">
            <p className="fontColorFCC">
              We may share Your personal information in the following
              situations:
            </p>
            <ul>
              <li>
                <b className="fontcolorFFF">With Service Providers:</b> We may share Your personal
                information with Service Providers to monitor and analyze the
                use of our Service, for payment processing, to contact You.
              </li>
              <li>
                <b className="fontcolorFFF">For business transfers:</b> We may share or transfer Your
                personal information in connection with, or during negotiations
                of, any merger, sale of Company assets, financing, or
                acquisition of all or a portion of Our business to another
                company.
              </li>
              <li>
                <b className="fontcolorFFF">With Affiliates:</b> We may share Your information with Our
                affiliates, in which case we will require those affiliates to
                honor this Privacy Policy. Affiliates include Our parent company
                and any other subsidiaries, joint venture partners or other
                companies that We control or that are under common control with
                Us.
              </li>
              <li>
                <b className="fontcolorFFF">With business partners:</b> We may share Your information
                with Our business partners to offer You certain products,
                services or promotions.
              </li>
              <li>
                <b className="fontcolorFFF">With other users:</b> when You share personal information or
                otherwise interact in the public areas with other users, such
                information may be viewed by all users and may be publicly
                distributed outside.
              </li>
              <li>
                <b className="fontcolorFFF">With Your consent:</b> We may disclose Your personal
                information for any other purpose with Your consent.
              </li>
            </ul>
          </div>

          <div className="col-md-12 gdpr-section">
            <h4>Retention of Your Personal Data</h4>
            <p className="fontColorFCC">
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </p>

            <p className="fontColorFCC">
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h4>Transfer of Your Personal Data</h4>
            <p className="fontColorFCC">
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
            </p>

            <p className="fontColorFCC">
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </p>

            <p className="fontColorFCC">
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h4 className="gdpr-heading">Disclosure of Your Personal Data</h4>
            <h5>Business Transactions</h5>
            <p className="fontColorFCC">
              If the Company is involved in a merger, acquisition or asset sale,
              Your Personal Data may be transferred. We will provide notice
              before Your Personal Data is transferred and becomes subject to a
              different Privacy Policy.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h5>Law enforcement</h5>
            <p className="fontColorFCC">
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h5>Other legal requirements</h5>
            <p className="fontColorFCC">
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
            </p>
            <ul className="fontColorFCC">
              <li>Comply with a legal obligation</li>
              <li>Protect and defend the rights or property of the Company</li>
              <li>
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li>
                Protect the personal safety of Users of the Service or the
                public
              </li>
              <li>Protect against legal liability</li>
            </ul>
          </div>

          <div className="col-md-12 gdpr-section">
            <h4>Security of Your Personal Data</h4>
            <p className="fontColorFCC">
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h4>
              Detailed Information on the Processing of Your Personal Data
            </h4>
            <p className="fontColorFCC">
              The Service Providers We use may have access to Your Personal
              Data. These third-party vendors collect, store, use, process and
              transfer information about Your activity on Our Service in
              accordance with their Privacy Policies.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h5>Analytics</h5>
            <p className="fontColorFCC">
              We may use third-party Service providers to monitor and analyze
              the use of our Service.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h5>Email Marketing</h5>
            <p className="fontColorFCC">
              We may use Your Personal Data to contact You with newsletters,
              marketing or promotional materials and other information that may
              be of interest to You. You may opt-out of receiving any, or all,
              of these communications from Us by following the unsubscribe link
              or instructions provided in any email We send or by contacting Us.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h5>Payments</h5>
            <p className="fontColorFCC">
              We may provide paid products and/or services within the Service.
              In that case, we may use third-party services for payment
              processing (e.g. payment processors).We will not store or collect
              Your payment card details. That information is provided directly
              to Our third-party payment processors whose use of Your personal
              information is governed by their Privacy Policy. These payment
              processors adhere to the standards set by PCI-DSS as managed by
              the PCI Security Standards Council, which is a joint effort of
              brands like Visa, Mastercard, American Express and Discover.
              PCI-DSS requirements help ensure the secure handling of payment
              information.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h4 className="gdpr-heading">GDPR Privacy Policy</h4>
            <h5>Legal Basis for Processing Personal Data under GDPR</h5>
            <p className="fontColorFCC">We may process Personal Data under the following conditions:</p>
            <ul className="fontColorFCC">
              <li>
                <b>Consent:</b> You have given Your consent for processing
                Personal Data for one or more specific purposes.
              </li>
              <li>
                <b className='fontcolorFFF' >Performance of a contract:</b> Provision of Personal Data is
                necessary for the performance of an agreement with You and/or
                for any pre-contractual obligations thereof.
              </li>
              <li>
                <b className='fontcolorFFF' >Legal obligations:</b> Processing Personal Data is necessary
                for compliance with a legal obligation to which the Company is
                subject.
              </li>
              <li>
                <b className='fontcolorFFF'>Vital interests:</b> Processing Personal Data is necessary in
                order to protect Your vital interests or of another natural
                person.
              </li>
              <li>
                <b className='fontcolorFFF'>Public interests:</b> Processing Personal Data is related to
                a task that is carried out in the public interest or in the
                exercise of official authority vested in the Company.
              </li>
              <li>
                <b className='fontcolorFFF'>Legitimate interests:</b> Processing Personal Data is
                necessary for the purposes of the legitimate interests pursued
                by the Company.
              </li>
            </ul>

            <p className="fontColorFCC">
              In any case, the Company will gladly help to clarify the specific
              legal basis that applies to the processing, and in particular
              whether the provision of Personal Data is a statutory or
              contractual requirement, or a requirement necessary to enter into
              a contract.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h5 className="gdpr-heading">Your Rights under the GDPR</h5>
            <p className="fontColorFCC">
              The Company undertakes to respect the confidentiality of Your
              Personal Data and to guarantee You can exercise Your rights.
            </p>

            <p className="fontColorFCC">
              You have the right under this Privacy Policy, and by law if You
              are within the EU, to:
            </p>

            <ul className="fontColorFCC">
              <li>
                <b className='fontcolorFFF'>Request access to Your Personal Data.</b> The right to
                access, update or delete the information We have on You.
                Whenever made possible, you can access, update or request
                deletion of Your Personal Data directly within Your account
                settings section. If you are unable to perform these actions
                yourself, please contact Us to assist You. This also enables You
                to receive a copy of the Personal Data We hold about You.
              </li>
              <li>
                <b className='fontcolorFFF'>Request correction of the Personal Data</b> that We hold
                about You. You have the right to have any incomplete or
                inaccurate information We hold about You corrected.
              </li>
              <li>
                <b className='fontcolorFFF'>Object to processing of Your Personal Data.</b> This right
                exists where We are relying on a legitimate interest as the
                legal basis for Our processing and there is something about Your
                particular situation, which makes You want to object to our
                processing of Your Personal Data on this ground. You also have
                the right to object where We are processing Your Personal Data
                for direct marketing purposes.
              </li>
              <li>
                <b className='fontcolorFFF'>Request erasure of Your Personal Data.</b> You have the right
                to ask Us to delete or remove Personal Data when there is no
                good reason for Us to continue processing it.
              </li>
              <li>
                <b className='fontcolorFFF'>Request the transfer of Your Personal Data.</b> We will
                provide to You, or to a third-party You have chosen, Your
                Personal Data in a structured, commonly used, machine-readable
                format. Please note that this right only applies to automated
                information which You initially provided consent for Us to use
                or where We used the information to perform a contract with You.
              </li>
              <li>
                <b className='fontcolorFFF'>Withdraw Your consent.</b> You have the right to withdraw
                Your consent on using your Personal Data. If You withdraw Your
                consent, We may not be able to provide You with access to
                certain specific functionalities of the Service.
              </li>
            </ul>
          </div>

          <div className="col-md-12 gdpr-section">
            <h5>Exercising of Your GDPR Data Protection Rights</h5>
            <p className="fontColorFCC">
              You may exercise Your rights of access, rectification,
              cancellation and opposition by contacting Us. Please note that we
              may ask You to verify Your identity before responding to such
              requests. If You make a request, We will try our best to respond
              to You as soon as possible.
            </p>

            <p className="fontColorFCC">
              You have the right to complain to a Data Protection Authority
              about Our collection and use of Your Personal Data. For more
              information, if You are in the European Economic Area (EEA),
              please contact Your local data protection authority in the EEA.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h4>Children's Privacy</h4>
            <p className="fontColorFCC">
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please
              contact Us. If We become aware that We have collected Personal
              Data from anyone under the age of 13 without verification of
              parental consent, We take steps to remove that information from
              Our servers.
            </p>

            <p className="fontColorFCC">
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We
              may require Your parent's consent before We collect and use that
              information.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h4>Links to Other Websites</h4>
            <p className="fontColorFCC">
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
            </p>

            <p className="fontColorFCC">
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h4>Changes to this Privacy Policy</h4>
            <p className="fontColorFCC">
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </p>

            <p className="fontColorFCC">
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              "Last updated" date at the top of this Privacy Policy.
            </p>

            <p className="fontColorFCC">
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
          </div>

          <div className="col-md-12 gdpr-section">
            <h4>Contact Us</h4>
            <p className="fontColorFCC">
              If you have any questions about this Privacy Policy, You can
              contact us:
            </p>
            <ul className="fontColorFCC">
              <li>
                By visiting this page on our website:{" "}
                <a href="https://drivejoulez.com/" className='fontColorCE1 text-decoration-none'>https://drivejoulez.com</a>
              </li>
              <li>
                By sending us an email:{" "}
                <a href="mailto:support@drivejoulez.com" className='fontColorCE1 text-decoration-none'>
                  support@drivejoulez.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GdprPrivacyPolicy;
