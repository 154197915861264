
import React, { useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL } from '../api';
import { setCurrentUserDetails } from '../Store/userDetailsSlice';
import { useDispatch } from 'react-redux';


const AppleLoginSuccessComponent = () => {
  const token = localStorage.getItem("AccessToken");
  const currentUserDetails = JSON.parse(
    localStorage.getItem("CurrentUserDetails")
  );
  const selectedCar = localStorage.getItem("selectedCar");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //to get User details
  const getUserDetails = async () => {
    const token = localStorage.getItem("AccessToken");
    try {
      const userResponse = await axios.get(`${BASE_URL}user/currentUser`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      localStorage.setItem(
        "CurrentUserDetails",
        JSON.stringify(userResponse?.data?.data)
      );
    } catch (error) {
    }
  };
 
  //to get estimated price
  const handleGetEstimatedPrice = async (data) => {
    try {
      const result = await axios.post(
        token && currentUserDetails && currentUserDetails.dob && currentUserDetails.dob !== undefined && currentUserDetails.dob !== null
          ? `${BASE_URL}booking/estimatedPrice`
          : `${BASE_URL}booking/estimatedPrice/open`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            ...(token && currentUserDetails && currentUserDetails.dob && currentUserDetails.dob !== undefined && currentUserDetails.dob !== null
              ? { Authorization: `Bearer ${token}` } : {}),
          },
        }
      );

      localStorage.setItem("bookingInputs",JSON.stringify(result?.data?.data));
      navigate("/booking");
    } catch (error) {
       toast.error(error?.response?.data?.message);
       handleBookingError(error, data, selectedCar);
    }
  };
  
  const handleBookingError = (error, bookingData, selectedCar) => {
    if (
      error?.response?.data?.message ===
        'Please update date of birth to proceed further.' ||
      error?.response?.data?.statusCode === 471
    ) {
      navigate("/booking")
    } else if (
      error?.response?.data?.message ===
      'This car is not applicable to your age-group'
    ) {
      toast.error(error?.response?.data?.message);
      navigate("/cars-list");
    }
  };
  useEffect(() => {
    const res = Cookies.get('res');
    if (res === 'apple_failed') {
      toast.error('Apple signup failed');
      navigate('/');
      return;
    }
    if (res === 'apple_verification_email') {
      toast.error(
        'Your account has been put on-hold, please verify the email we have sent'
      );
      navigate('/');
      return;
    }

    const decoded = atob(res);
    localStorage.setItem('AccessToken', decoded);
    dispatch(setCurrentUserDetails(decoded));
    toast.success('Successfully signed up');
    getUserDetails();
    const selectedCar = localStorage.getItem('selectedCar');
    const bookingString = localStorage.getItem('bookingInputs');
    const bookingData = bookingString ? JSON.parse(bookingString) : null;
    if (selectedCar && bookingData) {
    handleGetEstimatedPrice(bookingData);
    } else {
      navigate('/');
    }
  }, [navigate]);


  return <div>Apple Login Success Component</div>;
};

export default AppleLoginSuccessComponent;
