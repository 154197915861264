import { configureStore } from "@reduxjs/toolkit"
import dateTimeAndLocationReducer from "./locationSlice"
import modalStatusReducer from "./modalStatusSlice"
import dateAndTimeReducer from "./dateAndTimeSlice"
import darkAndLightModeReducer from "./lightAndDarkModeSlice"
import pageReducer from "./pageSlice"
import buttonReducer from "./buttonSlice"
import userDetailsReducer from "./userDetailsSlice"
import emrDateAndTimeReducer from "./emrTimeAndDateSlice"

const appStore = configureStore({
    reducer: {
        currentLocations: dateTimeAndLocationReducer,
        modalStatus: modalStatusReducer,
        currentDatesAndTimings: dateAndTimeReducer,
        currentModeStatus: darkAndLightModeReducer,
        currentPage:pageReducer,
        currentButton: buttonReducer,
        userDetailsSlice: userDetailsReducer,
        emrDatesAndTimings: emrDateAndTimeReducer
    }
})

export default appStore