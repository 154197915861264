import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";

const tomorrowDate = new Date();
tomorrowDate.setDate(tomorrowDate.getDate() + 1);

const emrDateAndTimeSlice = createSlice({
  name: "emrDateAndTime",
  initialState: {
    pickupAndDropDates: {
      pickupDate: format(new Date(), "MMM dd, yyyy"),
      dropDate: format(tomorrowDate, "MMM dd, yyyy"),
    },
    pickupAndDropTimings: {
      pickupTime:"10:00 AM",
      dropTime: "10:30 AM",
    },
  },
  reducers: {
    addEmrPickupAndDropDates: (state, action) => {
      const { pickupDate, dropDate="Select Date" } = action.payload;
      state.pickupAndDropDates.pickupDate = pickupDate;
      state.pickupAndDropDates.dropDate = dropDate;
    },
    addEmrPickupAndDropTimings: (state, action) => {
      const { pickupTime, dropTime } = action.payload;
      if (pickupTime !== undefined) {
        state.pickupAndDropTimings.pickupTime = pickupTime;
      }
      if (dropTime !== undefined) {
        state.pickupAndDropTimings.dropTime = dropTime;
      }
    },
  },
});

export const { addEmrPickupAndDropDates, addEmrPickupAndDropTimings } =
  emrDateAndTimeSlice.actions;

export default emrDateAndTimeSlice.reducer;
