import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DiscoverContainer from '../Components/DiscoverContainer';
import CarouselContainer from '../Components/CarouselContainer';
import ourCar from "../assets/Images/Our_car.svg";
import { setCurrentPage } from '../Store/pageSlice';

const Fleet = () => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage("our-fleet"));
  }, [dispatch]);

  return (
    <div className={`${isDarkMode ? 'bg-dark text-white' : 'bg-light text-dark'} py-5`}>
      {/* Discover Container */}
      <div className="d-flex justify-content-center mb-0 w-100 mt-0">
        <DiscoverContainer marginTop="mt-3" />
      </div>

      {/* Carousel Container */}
      <div className={`${isDarkMode ? 'text-white' : 'text-dark'}`}>
        <CarouselContainer />
      </div>

      {/* Header Section */}
      <div className="text-center my-5">
        <h2 className="fontWeight400 fontAntarctica">What our Tesla customers have to say.</h2>
      </div>

      {/* Testimonial Carousel Section */}
      <div className="container">
        <div id="testimonialCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">

            {/* First Testimonial */}
            <div className="carousel-item active">
              <div className="d-flex justify-content-center">
                <div className="bg-dark text-white shadow-lg border-0 w-100 rounded" style={{ maxWidth: '900px' }}>
                  <div className="row g-0 custom-border paddingTop">
                    {/* Image Section */}
                    <div className="col-md-5">
                      <img
                        src={ourCar}
                        alt="our-car"
                        className="img-fluid rounded-start"
                      />
                    </div>

                    {/* Content Section */}
                    <div className="col-md-7 d-flex flex-column justify-content-center h-100 ourCarPadding">
                      <h4 className="fontWeight400 ourCarPaddingRow">Tesla Model Y</h4>
                      <p>
                        "The Tesla Model S has completely redefined my perception of electric vehicles. It's a luxury car with unbeatable performance and sustainability."
                      </p>
                      <p>
                        "The Tesla Model S has completely redefined my perception of electric vehicles. It's a luxury car with unbeatable performance and sustainability."
                      </p>
                      <h6 className="mt-3">Itachi Uchiha</h6>
                      <p className="">CEO & Founder of Akatsuki</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Second Testimonial */}
            <div className="carousel-item">
              <div className="d-flex justify-content-center">
                <div className="bg-dark text-white shadow-lg border-0 w-100 rounded" style={{ maxWidth: '900px' }}>
                  <div className="row g-0 custom-border paddingTop">
                    {/* Image Section */}
                    <div className="col-md-5 h-100">
                      <img
                        src={ourCar}
                        alt="our-car"
                        className="img-fluid rounded-start h-100"
                      />
                    </div>

                    {/* Content Section */}
                    <div className="col-md-7 d-flex flex-column justify-content-center h-100 ourCarPadding">
                      <h4 className="fontWeight400 ourCarPaddingRow">Tesla Model X</h4>
                      <p>
                        "Driving the Tesla Model X has been an amazing experience. The technology is futuristic, and the design is sleek. It's the perfect car for my lifestyle."
                      </p>
                      <p>
                        "The Tesla Model S has completely redefined my perception of electric vehicles. It's a luxury car with unbeatable performance and sustainability."
                      </p>
                      <h6 className="mt-3">Naruto Uzumaki</h6>
                      <p className="">Hokage, Hidden Leaf Village</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Third Testimonial */}
            <div className="carousel-item">
              <div className="d-flex justify-content-center">
                <div className="bg-dark text-white shadow-lg border-0 w-100 rounded" style={{ maxWidth: '900px' }}>
                  <div className="row g-0 custom-border paddingTop">
                    {/* Image Section */}
                    <div className="col-md-5 h-100">
                      <img
                        src={ourCar}
                        alt="our-car"
                        className="img-fluid rounded-start h-100"
                      />
                    </div>

                    {/* Content Section */}
                    <div className="col-md-7 d-flex flex-column justify-content-center h-100 ourCarPadding">
                      <h4 className="fontWeight400 ourCarPaddingRow">Tesla Model S</h4>
                      <p>
                        "The Tesla Model S has completely redefined my perception of electric vehicles. It's a luxury car with unbeatable performance and sustainability."
                      </p>
                      <p>
                        "The Tesla Model S has completely redefined my perception of electric vehicles. It's a luxury car with unbeatable performance and sustainability."
                      </p>
                      <h6 className="mt-3">Sasuke Uchiha</h6>
                      <p className="">Founder of the Uchiha Clan's Electric Fleet</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          {/* Carousel Controls */}
          <button className="carousel-control-prev" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>



    </div>
  );
};

export default Fleet;
