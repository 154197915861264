import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DropOffLocation from "./DropOffLocation";
import PickupLocation from "./PickupLocation";
import TimeSelector from "./TimeSelector";
import Search from "./Search";
import searchIcon from "../../assets/svg/searchIcon.svg";
import searchIconLight from "../../assets/svg/searchIconLight.svg";
import useMainSearch from "../../Hooks/useMainSearch";
import ErrorBoundary from "../ErrorBoundary";
import axios from "axios";
import { BASE_URL } from "../../api";
import { useFetchLocations } from "../../Hooks/useFetchLocations";
import { useNavigate } from "react-router-dom";
import joulezIcon from "../../assets/svg/joulezIcon.svg";
import joulezLightIcon from "../../assets/svg/JoulezLightIcon.svg";
import new_joulez_logo from "../../assets/svg/new_joulez_logo.svg";
import useScrollY from "../../Hooks/useScrollY";

const SearchContainer = ({ showSearch = true, JoulezIconRequired = false, siteMapLocationID="", locationSitMapName="" }) => {
  const scrollY = useScrollY()
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const {
    containerRef,
    showModal,
    handleToggle,
    handlePickupSelect,
    handleDropSelect,
  } = useMainSearch();

  const navigate = useNavigate();
  const { locationOptions, filterOptions, setFilterOptions, loading, error } =
    useFetchLocations(`${BASE_URL}location/open`);
  localStorage.setItem("allLocationsData", JSON.stringify(locationOptions));
  const [pickupGarageServiceCount, setPickupGarageServiceCount] = useState("");
  const [restrictDropOffLocations, setRestrictDropOffLocations] =
    useState(false);

  const handleGetServiceCount = (
    pickupGarageServiceCount,
    restrictDropValue
  ) => {
    setPickupGarageServiceCount(pickupGarageServiceCount);
    setRestrictDropOffLocations(restrictDropValue);
    localStorage.setItem("disableDropLocation", restrictDropValue);
    localStorage.setItem("serviceCount", pickupGarageServiceCount);
  };

  return (
    <div
      ref={containerRef}
      className={`${isDarkMode ? "bgf8f9fa searchBorder733" : "lightMode border234D"} ${
        showSearch ? "searchContainer" : `width90vw ${scrollY > 500 ? "mt-0" : "mt-3"}`
      }  flex-column mx-auto p-3 d-lg-flex flex-lg-row justify-content-between align-items-center`}
    >
      {!showSearch && (
        <div className="d-flex justify-content-center align-items-center">
          <img
          className="searchIcon"
          src={`${isDarkMode ? searchIcon : searchIconLight}`}
          alt="searchIcon"
        />
          </div>
      )}
      {JoulezIconRequired && (
        <div
          className="col-lg-2 px-2 cursorPointer"
          onClick={() => {
            navigate("/");
            //the entire page will reload
            window.location.reload();
            localStorage.removeItem("bookingInputs");
          }}
        >
          <img src={isDarkMode ? new_joulez_logo : joulezLightIcon} alt="icon" />
        </div>
      )}
      {JoulezIconRequired && <div className="text-white hrLineSearch d-none d-sm-none  d-md-none d-lg-block"></div>}
      <ErrorBoundary>
        <PickupLocation
          toggle={() => handleToggle("pickup")}
          show={showModal.pickup}
          onSelect={handlePickupSelect}
          dropToggle={() => handleToggle("drop")}
          dropShow={showModal.drop}
          dropOnSelect={handleDropSelect}
          locationOptions={locationOptions}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          loading={loading}
          error={error}
          handleGetServiceCount={handleGetServiceCount}
          siteMapLocationID={siteMapLocationID}
          locationSitMapName={locationSitMapName}
        />
      </ErrorBoundary>
      <div className="text-white hrLineSearch d-none d-sm-none  d-md-none d-lg-block"></div>

      <DropOffLocation
        toggle={() => handleToggle("drop")}
        show={showModal.drop}
        onSelect={handleDropSelect}
        showInputContainer={false}
        // activeInput="drop"//this is to focus the dropOffLocation input on desktop screen
        locationOptions={locationOptions}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        loading={loading}
        error={error}
        pickupGarageServiceCount={pickupGarageServiceCount}
        restrictDropOffLocations={restrictDropOffLocations}
        setRestrictDropOffLocations={setRestrictDropOffLocations}
        handleGetServiceCount={handleGetServiceCount}
      />
      <div className="text-white hrLineSearch d-none d-sm-none  d-md-none d-lg-block"></div>
      <TimeSelector
        toggle={() => handleToggle("calendarAndTime")}
        show={showModal.calendarAndTime}
        filterOptions={filterOptions}
        restrictDropOffLocations={restrictDropOffLocations}
      />
      {showSearch && (
        <div className="text-white hrLineSearch d-none d-sm-none  d-md-none d-lg-block"></div>
      )}
      {showSearch && <Search toggle={() => handleToggle("")} />}
    </div>
  );
};

export default SearchContainer;
