// import { createSlice } from "@reduxjs/toolkit";

// const getInitialMode = () => {
//   const savedMode = localStorage.getItem("darkMode");
//   if (savedMode !== null) {
//     //we are getting string value from localstorage
//     return savedMode === "true"; //it becomes string to boolean value true
//   }
//   return window.matchMedia("(prefers-color-scheme: dark)").matches;
// };

// const lightAndDarkModeSlice = createSlice({
//   name: "lightAndDarkMode",
//   initialState: {
//     darkMode: getInitialMode(),
//   },
//   reducers: {
//     toggleMode: (state) => {
//       state.darkMode = !state.darkMode;
//       localStorage.setItem("darkMode", state.darkMode);
//     },
//   },
// });

// export const { toggleMode } = lightAndDarkModeSlice.actions;

// export default lightAndDarkModeSlice.reducer;


import { createSlice } from "@reduxjs/toolkit";

const lightAndDarkModeSlice = createSlice({
  name: "lightAndDarkMode",
  initialState: {
    darkMode: true,
  },
  reducers: {
    toggleMode: (state) => {
      state.darkMode = true;
      localStorage.setItem("darkMode", true);
    },
  },
});

export const { toggleMode } = lightAndDarkModeSlice.actions;

export default lightAndDarkModeSlice.reducer;
