// import React, { useCallback, useState } from "react";
// import { useDropzone } from "react-dropzone";
// import deleteIcon from "../assets/svg/delete_red_icon.svg";
// import heic2any from "heic2any";

// const FileUploader = ({
//   label,
//   onFileChange = (files) => console.log(files), // default handler for file changes
//   fileTextMessage
// }) => {
//   const [file, setFile] = useState(null);
//   const [preview, setPreview] = useState(null);
//   const [error, setError] = useState(null);

//   const onDrop = useCallback(
//     async (acceptedFiles, rejectedFiles) => {
//       //it gives both accepted and rejected files from the useDropZone({onDrop})
//       const newFile = acceptedFiles[0];
//       if (newFile) {
//         let fileError = null;
//         if (newFile.type === "application/pdf" && newFile.size > 5 * 1024 * 1024) {
//           fileError = "PDF files must be smaller than 5 MB.";
//         } else if (newFile.type.startsWith("image/") && newFile.size > 15 * 1024 * 1024) {
//           fileError = "Image files must be smaller than 15 MB.";
//         }

//         if (fileError) {
//           setError(fileError);
//           setFile(null);
//           setPreview(null);
//         } else {
//           try {
//             let filePreview = null;
//             if (newFile.type === "image/heic") {
//               const convertedBlob = await heic2any({ blob: newFile, toType: "image/jpeg" });
//               filePreview = URL.createObjectURL(convertedBlob);
//             } else {
//               filePreview = URL.createObjectURL(newFile);
//             }
//             setPreview(filePreview);
//             setFile(newFile);
//             setError(null);
//             onFileChange(acceptedFiles);
//           } catch (conversionError) {
//             setError("Failed to convert HEIC file.");
//             setFile(null);
//             setPreview(null);
//           }
//         }
//       } else if (rejectedFiles.length > 0) {
//         setError("File type not supported or file is too large.");
//         setFile(null);
//         setPreview(null);
//       }
//     },
//     [onFileChange]
//   );

//   const removeFile = () => {
//     setFile(null);
//     setPreview(null);
//     setError(null);
//     if (preview) {
//       URL.revokeObjectURL(preview);
//     }
//   };

//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop,
//     accept: {
//       "application/pdf": [],
//       "image/png": [],
//       "image/jpeg": [],
//       "image/jpg": [],
//       "image/heic": []
//     }
//   });

//   return (
//     <div>
//       <div
//         className="d-flex justify-content-between align-items-center"
//         onClick={removeFile}
//       >
//         <h6>{label}</h6>
//         {file && <img src={deleteIcon} alt="deleteIcon" />}
//       </div>
//       <div className="position-relative fileUploadInputBox" {...getRootProps()}>
//         <input {...getInputProps()} />
//         {(!file && !error) && (
//           <div className="d-flex justify-content-center align-items-center h-100">
//             <div>{fileTextMessage}</div>
//           </div>
//         )}
//         {error && (
//           <div className="error-message" style={{ color: 'red', marginTop: '10px' }}>
//             {error}
//           </div>
//         )}
//         {preview && (
//           <img
//             src={preview}
//             alt="Preview"
//             style={{ height:"100px", width:"150px", objectFit:"cover" }}
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// export default FileUploader;

import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import deleteIcon from "../assets/svg/delete_red_icon.svg";
import heic2any from "heic2any";
import imageCompression from 'browser-image-compression';
import latestPdfIcon from "../assets/svg/latestPdfIcon.svg"

const FileUploader = ({
  label,
  onFileChange = (files) => {
    //console.log(files)
  }, // default handler for file changes
  fileTextMessage,
  removePreviewImage = false,
  handleDeleteFile = () => {}
}) => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(removePreviewImage){
      removeFile()
    }
  }, // eslint-disable-next-line
  [removePreviewImage])

  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      const newFile = acceptedFiles[0];
      if (newFile) {
        let fileError = null;
        if (newFile.type === "application/pdf" && newFile.size > 5 * 1024 * 1024) {
          fileError = "PDF files must be smaller than 5 MB.";
        } else if (newFile.type.startsWith("image/") && newFile.size > 5 * 1024 * 1024) {
          fileError = "Image files must be smaller than 5 MB.";
        }

        if (fileError) {
          setError(fileError);
          setFile(null);
          setPreview(null);
        } else {
          try {
            let compressedFile = newFile;
            // Compress if image size is greater than 5 MB
            if (newFile.size > 5 * 1024 * 1024) {
              const options = {
                maxSizeMB: 5,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              };
              compressedFile = await imageCompression(newFile, options);
            }

            let filePreview = null;
            if (compressedFile.type === "application/pdf") {
              filePreview = latestPdfIcon; // Set the PDF icon here
            }
            else if (compressedFile.type === "image/heic") {
              const convertedBlob = await heic2any({ blob: compressedFile, toType: "image/jpeg" });
              filePreview = URL.createObjectURL(convertedBlob);
            } else {
              filePreview = URL.createObjectURL(compressedFile);
            }

            setPreview(filePreview);
            setFile(compressedFile);
            setError(null);
            onFileChange(compressedFile); // Send the compressed file to parent component
          } catch (conversionError) {
            setError("Failed to convert HEIC file.");
            setFile(null);
            setPreview(null);
          }
        }
      } else if (rejectedFiles.length > 0) {
        setError("File type not supported or file is too large.");
        setFile(null);
        setPreview(null);
      }
    },
    [onFileChange]
  );

  const removeFile = () => {
    setFile(null);
    setPreview(null);
    setError(null);
    handleDeleteFile()
    if (preview) {
      URL.revokeObjectURL(preview);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [],
      "image/png": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/heic": []
    }
  });

  return (
    <div className="fileUploaderwidth220">
      <div
        className="d-flex justify-content-between align-items-center py-1"
        onClick={removeFile}
      >
        <div>{label}</div>
        {file && <img src={deleteIcon} alt="deleteIcon" />}
      </div>
      <div className="position-relative fileUploadInputBox" {...getRootProps()}>
        <input {...getInputProps()} />
        {(!file && !error) && (
          <div className="d-flex justify-content-center align-items-center h-100">
            <div>{fileTextMessage}</div>
          </div>
        )}
        {error && (
          <div className="error-message" style={{ color: 'red', marginTop: '10px' }}>
            {error}
          </div>
        )}
        {preview && (
          <img
            src={preview}
            alt="Preview"
            style={{ height: "80px",  objectFit: "cover" }}
          />
        )}
      </div>
    </div>
  );
};

export default FileUploader;
