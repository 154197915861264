import { useState, useEffect } from "react";
import axios from "axios";

export const useFetchLocations = (url) => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        const result = response.data.data.map((item) => ({
          id: item.id,
          displayAddress: item.displayAddress,
          latitude: item.lat,
          longitude: item.lng,
          garageServiceable: item?.garageServiceable,
          timeSlots: item?.timeSlots,
          locationName:item?.locationName,
          city:item?.city
        }));
        setLocationOptions(result);
        setFilterOptions(result);
        setLoading(false);
        localStorage.setItem("allLocationsTimeZones", JSON.stringify(response?.data?.data[0]))
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { filterOptions, setFilterOptions, locationOptions, loading, error };
};


// import { useState, useEffect } from "react";
// import axios from "axios";

// export const useFetchLocations = (baseUrl, latitude = null, longitude = null) => {
//   const [locationOptions, setLocationOptions] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [filterOptions, setFilterOptions] = useState([]);
//   const [isLatLongUsed, setIsLatLongUsed] = useState(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         // Construct URL based on latitude and longitude
//         let url = baseUrl;
//         console.log(latitude, longitude);
        
//         if (latitude && longitude) {
//           url += `?latitude=${latitude}&longitude=${longitude}`;
//           setIsLatLongUsed(true)
//         } else {
//           setIsLatLongUsed(false)
//         }

//         const response = await axios.get(url);

//         const result = response.data.data.map((item) => ({
//           id: item.id,
//           displayAddress: item.displayAddress,
//           latitude: item.latitude,
//           longitude: item.longitude,
//         }));
//         setLocationOptions(result);
//         setFilterOptions(result);
//       } catch (error) {
//         setError(error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [baseUrl, latitude, longitude]);

//   return { filterOptions, setFilterOptions, locationOptions, loading, error, isLatLongUsed };
// };
