import { useSelector } from "react-redux";
import images from "../assets/Images";
import { content4 } from "../data/content";

const FounderContainer = () => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const { title, quote, founderName, founderTitle } = content4;
  return (
    <div className={`${isDarkMode ? "darkMode" : "lightMode"} p-5`}>
      <div className="pt-5">
        <h2 className="text-center founderContainerTitle fontAntarctica">{title}</h2>
      </div>
      <div className="p-3 d-flex  justify-content-center align-items-center ">
        <div className=" d-flex flex-column flex-lg-row  justify-content-center align-items-center  mt-3 ">
          <div className="m-2 p-2">
            <img
              className="founderImg object-fit-contain rounded"
              src={images.founderImage}
              alt="founderImage"
            />
          </div>
          <div className="m-2 p-2 col-12 col-lg-6">
            <p className="fontAntarctica 4">"{quote}"</p>
            <h3 className="fontAtacama">{founderName}</h3>
            <h6 className="fontAntarctica">{founderTitle}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FounderContainer;
