import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DuplicateImage from "../assets/Images/Duplicate.png";


const Card = ({
  detailsOfTheCar,
  membersIcon,
  milesIcon,
  locationIcon,
  wishlistIcon,
  baseRateWithNoDays
}) => {
  const {
    carImage,
    vehicleType,
    vehicleModel,
    name,
    seats,
    maxMiles,
    locationName,
    baseRatePerDay,
    noOfDays,
  } = detailsOfTheCar;

  const {value} = baseRateWithNoDays || {};
  // console.log(value);
  

  const currentButtonStatus = useSelector((store) => store?.currentButton);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/booking");
  };
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  return (
    <div
      onClick={() => {
        localStorage.setItem("selectedCar", JSON.stringify(detailsOfTheCar));
        handleNavigate();
      }}
      className={`${currentButtonStatus?.disableSearchButton ? "disabled-class" : ""} card col-3 col-sm-12 col-md-3 col-lg-3 p-3 fontAntarctica fontSize13 cursorPointer ${
        isDarkMode ? "bg3B3936 text-white" : "lightMode"
      }`}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p className="fontSize14 mb-1 text-truncate carModelWidth">
            {name} {vehicleModel}
          </p>
          <p className="opacity70">{vehicleType}</p>
        </div>
        <div className="mb-2">
          <p className="mb-1 text-end fontSize13">${baseRatePerDay}*/day</p>
          <p className="text-end tripFontSize">${value}*/trip</p>
        </div>
      </div>
      {/* <img src={wishlistIcon} alt="wishListIcon" className="wishList" /> */}
      {/* {carImage && (
        <img
          className="card-img-top object-fit-cover mb-2  w-auto h-auto"
          src={ DuplicateImage }
          alt={vehicleModel}
        />
      )} */}
      {carImage ? (
        <img
          className="card-img-top object-fit-cover mb-2"
          src={carImage}
          alt={`${vehicleModel}`}
        />
      ) : (
        <img
          className="card-img-top object-fit-cover mb-2 w-auto h-auto"
          src={DuplicateImage}
          alt={`${vehicleModel}`}
        />
      )}
      <div className="card- fontSize10 p-0">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex ">
            <p className="p-1 mb-0">
              <img className="mx-1" src={membersIcon} alt="membersIcon" />
              {seats}
            </p>
            <p className="p-1 mb-0">
              <img className="mx-1" src={milesIcon} alt="milesIcon" />
              {maxMiles} mi
            </p>
          </div>
          <div>
            <p className="mb-0">
              {/* {`${city} , ${state}`} */}
              {locationName}
              <img className="px-1" src={locationIcon} alt="locationIcon" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
