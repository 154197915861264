import { useState } from "react";

const useKeyBoradNavigation = (items, onSelect) => {
  const [highlightedIndex, setHighlightedIndex] = useState(null);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) => {
        // If prevIndex is null, start from 0; otherwise, move down
        const newIndex =
          prevIndex === null
            ? 0
            : prevIndex === items.length - 1
            ? 0
            : prevIndex + 1;
        return newIndex;
      });
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) => {
        // If prevIndex is null, start from the last index; otherwise, move up
        const newIndex =
          prevIndex === null
            ? items.length - 1
            : prevIndex === 0
            ? items.length - 1
            : prevIndex - 1;
        return newIndex;
      });
    } else if (e.key === "Enter" && highlightedIndex !== null) {
      onSelect(
        items[highlightedIndex]?.displayAddress,
        items[highlightedIndex].id
      );
      setHighlightedIndex(null);
    }
  };

  return { handleKeyDown, highlightedIndex };
};

export default useKeyBoradNavigation;
