import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import images from "../assets/Images";
import { useSelector } from "react-redux";
import {BASE_URL} from "../api/index"
import { useNavigate } from "react-router-dom";

const CarouselContainer = () => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const [carouselData, setCarouselData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState("Hyundai");
  const [brandLoading, setBrandLoading] = useState(false);
  const [brandNameBackground, setBrandNameBackground] = useState("Hyundai");
  const  navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchCarouselData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}fleet/open`
        );
        setCarouselData(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchCarouselData();
  }, []);

  if (loading)
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  if (error) return <p className="text-dark text-center bg-gradient ">Error while Loading!</p>;
  if (!carouselData) return null;

  const brandGroups = _.groupBy(carouselData, "brand");

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: window?.innerWidth > 992 ? true : false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200, // Extra large devices (large desktops, 1200px and up)
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // Large devices (desktops, 992px and up)
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Medium devices (tablets, 768px and up)
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576, // Small devices (landscape phones, 576px and up)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 376, // Small devices (landscape phones, 576px and up)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleGetBrandName = (brand) => {
    setBrandNameBackground(brand);
    setBrandLoading(true); // Start loading state for brand-specific content
    setTimeout(() => {
      setBrandName(brand);
      setBrandLoading(false); // End loading state
    }, 300); // Delay to simulate loading time
  };

  return (
    <div className={`${isDarkMode ? "darkMode" : "lightMode"}`}>
      <div>
        <div className="pt-5 d-flex justify-content-center ">
          <div className="mainSlideContainer">
            <Slider {...settings}>
              {Object.keys(brandGroups).map((brand) => (
                <div
                  key={brand}
                  className={`${
                    brandNameBackground === brand ? "bgDivColor" : ""
                  } imageContainer d-flex justify-content-center align-items-center flex-nowrap `}
                  onClick={() => handleGetBrandName(brand)}
                >
                  {images[brand] ? <img src={images[brand]} className="brandImage" alt={brand} /> : <div className={`${isDarkMode ? "text-white" : "text-dark"}mb-0 fontSize14 fontAntarctica`}>{brand}</div>}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="pt-4 loadingContainer">
        <div
          id="carouselExampleAutoplayingBrand"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            {brandLoading
              ? loading
              : brandGroups[brandName]?.map((_, i) => {
                  return (
                    <button
                      key={i}
                      type="button"
                      data-bs-target="#carouselExampleAutoplayingBrand"
                      data-bs-slide-to={i}
                      className={`${i === 0 ? "active" : ""}`}
                      aria-current="true"
                      aria-label={`Slide ${i}`}
                    ></button>
                  );
                })}
          </div>
          <div className="carousel-inner">
            {brandLoading ? (
              <div className="carousel-item active">
                <div className="d-flex justify-content-center align-items-center loadingContainer">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              brandGroups[brandName]?.map((details, index) => {
                return (
                  <div
                    key={details?.id}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <div onClick={()=> navigate("/cars-list")}>
                      {details?.img ? (
                        <div className="d-flex flex-column justify-content-center align-items-center ">
                          <img
                            className="p-3 carouselContainerImage cursor"
                            src={details?.img}
                            alt="BrandCarImage"
                          />
                          <div className="text-center mt-3 col-10 col-sm-8 col-md-4 col-lg-4">
                            <h5 className="fontAntarctica">
                              {details?.brand} {details?.model}
                            </h5>
                            <div
                              className={`d-flex ${
                                details?.batteryRange
                                  ? "justify-content-between "
                                  : "justify-content-center "
                              }`}
                            >
                              {details.batteryRange ? (
                                <div className="fontAntarctica">
                                  <h6 className="fontSize13 fontAntarctica">Range</h6>
                                  <h4>{details?.batteryRange} mi</h4>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="fontAntarctica">
                                <h6 className="fontSize13 fontAntarctica ">Starting rate</h6>
                                <h4>
                                  ${Math.floor(details?.pricePerDay)} / Day
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <img
                            src={images?.dummyImage}
                            alt="dummyImage"
                            className="w-25"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            )}
          </div>
          {!brandLoading && (
            <button
              className="carouselBrand-control-prev"
              type="button"
              data-bs-target="#carouselExampleAutoplayingBrand"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
          )}
          {!brandLoading && (
            <button
              className="carouselBrand-control-next"
              type="button"
              data-bs-target="#carouselExampleAutoplayingBrand"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarouselContainer;
