import { useState, useRef, useEffect } from "react";

const useMainSearch = () => {
  const [showModal, setShowModal] = useState({
    pickup: false,
    drop: false,
    calendarAndTime: false,
  });

  const containerRef = useRef(null);

  const handleToggle = (modal) => {
    setShowModal((currentModal) => ({
      ...{
        pickup: false,
        drop: false,
        calendarAndTime: false,
      },
      [modal]: !currentModal[modal],
    }));
  };

  const handlePickupSelect = () => {
    // handleToggle("drop");
    handleToggle("calendarAndTime");//it will directly open the calendarAndTime when u click pickup option value
  };

  const handleDropSelect = () => {
    handleToggle("calendarAndTime");
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowModal({
        pickup: false,
        drop: false,
        calendarAndTime: false,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => window.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return {
    containerRef,
    showModal,
    handleToggle,
    handlePickupSelect,
    handleDropSelect,
  };
};

export default useMainSearch;
