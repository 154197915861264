import React, { useEffect, useRef } from "react";
// import closeIcon from "../assets/svg/closeIcon.svg";
import redCloseIcon from "../assets/svg/redCloseIcon.svg";
import { useDispatch } from "react-redux";

const DesktopCustomModal = ({
  children,
  modalTitle,
  modalButton,
  modalId,
  closeOnOutsideClick = false,
  modalContent,
  closeModal,
  isModalOpen,
  isCloseIconRequired = false,
  isHRLineRequired = false,
  modalCenter = false,
  modalBackgroudColor = "bg23",
  modalClassHeight = "",
  modalClassWidth = "",
}) => {
  const modalRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isModalOpen) {
      // Set body overflow to hidden when the modal opens
      document.body.style.overflow = "hidden";
      // document.body.style.position = "fixed";
      // document.body.style.width = "100%"; // Ensures width doesn't collapse on mobile

      // Function to handle clicks outside of the modal
      const handleOutsideClick = (event) => {
        if (
          closeOnOutsideClick &&
          modalRef.current &&
          !modalRef.current.contains(event.target)
        ) {
          closeModal();
        }
      };

      // Add event listener if closeOnOutsideClick is true
      if (closeOnOutsideClick) {
        document.addEventListener("mousedown", handleOutsideClick);
      }

      // Cleanup function to remove event listener and reset body style
      return () => {
        if (closeOnOutsideClick) {
          document.removeEventListener("mousedown", handleOutsideClick);
        }
        document.body.style.overflow = "visible";
      };
    }
  }, [isModalOpen, closeModal, closeOnOutsideClick, dispatch]);

  return (
    <div>
      <div className="cursorPointer">{modalButton}</div>

      {isModalOpen && (
        <div
          className="modal d-block bgRgb5 overflow-hidden"
          id={modalId}
          aria-labelledby={`${modalId}Label`}
          aria-hidden="true"
        >
          <div
            className={`modal-dialog  ${
              modalCenter ? "" : "modal-dialog-margin-right"
            } d-flex justify-content-center align-items-center h-100`}
          >
            <div
            style={{zIndex:"9999"}}
              className={`modal-content  p-3  ${modalClassHeight} ${modalClassWidth} ${modalBackgroudColor}`}
              ref={modalRef}
            >
              <div
                className={`modalHeader ${
                  isCloseIconRequired
                    ? "d-flex justify-content-between align-items-center"
                    : ""
                }`}
              >
                <div
                  className="modal-title fontSize17 fontAntarctica"
                  id={`${modalId}Label`}
                >
                  {modalTitle}
                </div>

                {isCloseIconRequired ? (
                  <div className="px-3 py-2">
                    <img
                      className="btn-close cursorPointer"
                      onClick={closeModal}
                      aria-label="Close"
                      src={redCloseIcon}
                      alt="customModalCloseIcon"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {isHRLineRequired && <hr className="m-0" />}
              <div className="modal-body customizedScrollBar">{children}</div>
              {modalContent && <div className="modal-footer">{modalContent}</div>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DesktopCustomModal;
