import { useSelector } from "react-redux";
import { content3 } from "../data/content";
import { Fragment } from "react";

const JoulezWorkContainer = () => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const { title, steps } = content3;
  return (
    <div
      className={`${
        isDarkMode ? "darkGreyMode" : "lightGreyMode"
      } col-12 p-5  d-flex justify-content-sm-start  justify-content-md-center  justify-content-lg-center align-items-center`}
    >
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-3 text-center text-lg-center ">
        <h1 className="fontAntarctica joulezWorkContainerTitle">{title}</h1>
        <div>
          {steps.map((step, index) => (
            <Fragment key={index}>
              <div className="fontAntarctica joulezWorkContainerFontSize">{step}</div>
              {index < steps.length - 1 && <hr className="horizontalLine" />}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JoulezWorkContainer;
