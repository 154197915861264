import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
  name: "dateTimeLocation",
  initialState: {
    // pickup: "Bronx - 610 Exterior Street, Bronx, NY",
    // drop: "Bronx - 610 Exterior Street, Bronx, NY",
    pickup: "",
    drop: "",
    pickupIdAndDropId: {
      pickupId: "2",
      pickupLatitude: "",
      pickupLongitude: "",
      dropId: "2",
      dropLatitude: "",
      dropLongitude: "",
    },
  },
  reducers: {
    addPickupLocation: (state, action) => {
      state.pickup = action.payload;
    },
    addDropLocation: (state, action) => {
      state.drop = action.payload;
    },
    addPickupAndDropId: (state, action) => {
      const {
        pickupId,
        dropId,
        currentLatitude,
        currentLongitude,
        currentDropoffLatitude,
        currentDropoffLongitude,
      } = action.payload;
      if (pickupId !== undefined) {
        state.pickupIdAndDropId.pickupId = pickupId;
      }
      if (dropId !== undefined) {
        state.pickupIdAndDropId.dropId = dropId;
      }
      if (currentLatitude !== undefined) {
        state.pickupIdAndDropId.pickupLatitude = currentLatitude;
      }
      if (currentLatitude !== undefined) {
        state.pickupIdAndDropId.pickupLongitude = currentLongitude;
      }
      if (currentDropoffLatitude !== undefined) {
        state.pickupIdAndDropId.dropLatitude = currentDropoffLatitude;
      }
      if (currentDropoffLongitude !== undefined) {
        state.pickupIdAndDropId.dropLongitude = currentDropoffLongitude;
      }
    },
  },
});

export const { addPickupLocation, addDropLocation, addPickupAndDropId } =
  locationSlice.actions;

export default locationSlice.reducer;
