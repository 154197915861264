// import React, { useState, useEffect, useRef, useCallback } from "react";
// import { Autocomplete } from "@react-google-maps/api";
// import locationIcon from "../assets/svg/colorLocateIcon.svg";
// import axios from "axios";
// import { BASE_URL } from "../api";

// const AddressAutocomplete = ({ addressDetails, setAddressDetails }) => {
//   const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);
//   const [inputValue, setInputValue] = useState(""); // State to store the input value
//   const [stateTeritory, setStateTeritory] = useState("");
//   const autocompleteRef = useRef(null); // Ref for Autocomplete component

//   const [stateListAll, setStateListAll] = useState("");
//   const [stateListAllError, setStateListAllError] = useState("");

//   useEffect(() => {
//     if (inputValue === "") {
//       setAutocompleteSuggestions([]);
//     }
//   }, [inputValue]);

//   const getStateListAll = async () => {
//     try {
//       const userCreditResponse = await axios.get(
//         `${BASE_URL}location/getStateListAll/open`
//       );
//       setStateListAll(userCreditResponse?.data?.data?.countries);
//     } catch (error) {
//       setStateListAllError("Error in getting state list");
//     }
//   };

//   useEffect(() => {
//     getStateListAll();
//   }, []);


//   // Fetch predictions from Google Places API based on input value
//   const fetchPredictions = useCallback((input = "") => {
//     if (!window.google || !window.google.maps) return;

//     const autocompleteService =
//       new window.google.maps.places.AutocompleteService();
//     const placesService = new window.google.maps.places.PlacesService(
//       document.createElement("div")
//     );

//     autocompleteService.getPlacePredictions(
//       { input, componentRestrictions: { country: "us" } },
//       (predictions, status) => {
//         if (
//           status === window.google.maps.places.PlacesServiceStatus.OK &&
//           predictions
//         ) {
//           const results = [];

//           const processPrediction = (prediction, index) => {
//             placesService.getDetails(
//               { placeId: prediction.place_id },
//               (place, status) => {
//                 if (
//                   status === window.google.maps.places.PlacesServiceStatus.OK
//                 ) {
//                   results[index] = {
//                     description: prediction.description,
//                     placeId: place.place_id,
//                     address: place.formatted_address,
//                     city: "",
//                     state: "",
//                     zipCode: "",
//                     country: "",
//                     countryCode: "",
//                   };

//                   // Extract city, state, zip code, and country from the place details
//                   place.address_components.forEach((component) => {
//                     const types = component.types;
//                     if (types.includes("locality")) {
//                       results[index].city = component.long_name;
//                     }
//                     if (types.includes("administrative_area_level_1")) {
//                       results[index].state = component.short_name;
//                     }
//                     if (types.includes("postal_code")) {
//                       results[index].zipCode = component.long_name;
//                     }
//                     if (types.includes("country")) {
//                       results[index].country = component.long_name;
//                       results[index].countryCode = component.short_name;
//                     }
//                   });

//                   if (results.length === predictions.length) {
//                     setAutocompleteSuggestions(results);
//                   }
//                 }
//               }
//             );
//           };

//           predictions.forEach((prediction, index) => {
//             processPrediction(prediction, index);
//           });
//         } else {
//           setAutocompleteSuggestions([]);
//         }
//       }
//     );
//   }, []);

//   const handleCountry = (e) => {
//     const country = e.target.value;

//     // Find the selected country data from stateListAll
//     const selectedCountryData = stateListAll?.find(
//       (ele) => ele?.name === country
//     );

//     // If country is found, set the state's territories (assuming each country has a `states` field)
//     if (selectedCountryData) {
//       setStateTeritory(selectedCountryData.states || []); // Assuming 'states' is an array in the country data
//     } else {
//       setStateTeritory([]); // Reset states if no country is selected or found
//     }
//   };

//   // Update the predictions when the input value changes
//   const handleInputChange = (event) => {
//     const value = event.target.value;
//     setInputValue(value); // Update the input value state
//     fetchPredictions(value); // Fetch predictions based on the input value
//   };

//   useEffect(() => {
//     fetchPredictions(inputValue); // Fetch predictions when component mounts or input value changes
//     // eslint-disable-next-line
//   }, [fetchPredictions]);

//   return (
//     <div>
//       <Autocomplete
//         // onPlaceChanged={handlePlaceChanged}
//         onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)} // Assign autocomplete instance to ref
//       >
//         <div className="mb-3 position-relative">
//           <input
//             id="AddressLine1"
//             value={inputValue} // Bind the input value to the state
//             onChange={handleInputChange} // Update input value on change
//             placeholder="Enter Address Line 1"
//             type="text"
//             className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
//           />
//         </div>
//       </Autocomplete>

//       {/* Display the address suggestions */}
//       {autocompleteSuggestions?.length > 0 && (
//         <ul className="unorderList bgF08 borderRadius8 p-2">
//           {autocompleteSuggestions.map((suggestion, index) => (
//             <li
//               key={index}
//               onClick={() => {
//                 setInputValue(suggestion.description); // Set the input field value to the clicked suggestion
//                 setAddressDetails({
//                   address: suggestion.address,
//                   city: suggestion.city,
//                   state: suggestion.state,
//                   zipCode: suggestion.zipCode,
//                   country: suggestion.country,
//                   countryCode: suggestion.countryCode,
//                   secondAddressline: "",
//                 });
//                 const result = stateListAll?.filter(
//                   (ele) => ele?.countryCode === suggestion?.countryCode
//                 );
//                 setStateTeritory(result[0]?.states);
//                 setAutocompleteSuggestions([]); // Clear suggestions after selection
//               }}
//               className="cursorPointer d-flex align-items-center"
//             >
//               <div className="p-2">
//                 <img src={locationIcon} alt="locationIcon" />
//               </div>
//               <div>
//                 <p className="mb-0 fontSize13">
//                   {suggestion.description} - {suggestion.city},{" "}
//                   {suggestion.state} {suggestion.zipCode}, {suggestion.country}
//                 </p>
//               </div>
//             </li>
//           ))}
//         </ul>
//       )}

//       {/* Form Inputs for City, State, Zip Code, Address, and Country */}
//       <div className="mb-3 position-relative">
//         <input
//           id="AddressLine2"
//           placeholder="Enter Address Line 2"
//           type="text"
//           className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
//           value={addressDetails?.secondAddressline}
//           onChange={(e) => {
//             setAddressDetails({
//               ...addressDetails,
//               secondAddressline: e.target.value,
//             });
//           }}
//         />
//       </div>

//       <div className="mb-3">
//         <select
//           className="w-100 p-2 rounded rounded-3 form-control border3B3936 supportFormFocus bg-transparent outlineNone text-white"
//           name="country"
//           id="country"
//           value={addressDetails.country}
//           onChange={(e) => {
//             setAddressDetails({ ...addressDetails, country: e.target.value });
//             handleCountry(e); // Handle country change to fetch state options
//           }}
//         >
//           <option value="">Select Country</option>{" "}
//           {stateListAll?.length > 0 &&
//             stateListAll?.map((ele, index) => (
//               <option key={index} value={ele?.name}>
//                 {ele?.name}
//               </option>
//             ))}
//         </select>
//         <div>{stateListAllError && stateListAllError}</div>
//       </div>

//       <div className="mb-3">
//         <select
//           className="w-100 p-2 rounded rounded-3 form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
//           name="state"
//           id="state"
//           onChange={(e) => {
//             setAddressDetails({ ...addressDetails, state: e.target.value });
//           }}
//           value={addressDetails?.state}
//         >
//           <option>Select State Teriitory</option>
//           {stateTeritory?.length > 0 &&
//             stateTeritory?.map((ele, index) => (
//               <option key={index} value={ele?.stateCode}>
//                 {ele?.name}
//               </option>
//             ))}
//         </select>
//       </div>

//       <div className="mb-3 position-relative">
//         <input
//           id="city"
//           placeholder="Enter City Name"
//           type="text"
//           className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
//           value={addressDetails.city}
//           onChange={(e) =>
//             setAddressDetails({ ...addressDetails, city: e.target.value })
//           }
//         />
//       </div>

//       <div className="mb-3 position-relative">
//         <input
//           id="zipCode"
//           placeholder="Zip code"
//           type="text"
//           className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
//           value={addressDetails.zipCode}
//           onChange={(e) =>
//             setAddressDetails({ ...addressDetails, zipCode: e.target.value })
//           }
//         />
//       </div>
//     </div>
//   );
// };

// export default AddressAutocomplete;

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Autocomplete } from "@react-google-maps/api";
import locationIcon from "../assets/svg/colorLocateIcon.svg";
import axios from "axios";
import { BASE_URL } from "../api";

const AddressAutocomplete = ({ addressDetails, setAddressDetails, errors = "", setErrors = "" }) => {
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState(""); // State to store the input value
  const [stateTeritory, setStateTeritory] = useState("");
  const autocompleteRef = useRef(null); // Ref for Autocomplete component
  const [stateListAll, setStateListAll] = useState([]);
  const [stateListAllError, setStateListAllError] = useState("");

  //  const [errors, setErrors] = useState({
  //   country: "",
  //   state: "",
  //   city: "",
  //   zipCode: "",
  //   address: "",
  // });

  // Fetch state list data from API
  useEffect(() => {
    const getStateListAll = async () => {
      try {
        const userCreditResponse = await axios.get(
          `${BASE_URL}location/getStateListAll/open`
        );
        setStateListAll(userCreditResponse?.data?.data?.countries || []);
      } catch (error) {
        setStateListAllError("Error in getting state list");
      }
    };

    getStateListAll();
  }, []);

  // Clear suggestions when input is empty
  useEffect(() => {
    if (inputValue === "") {
      setAutocompleteSuggestions([]);
    }
  }, [inputValue]);

  // Fetch predictions from Google Places API based on input value
  const fetchPredictions = useCallback((input = "") => {
    if (!window.google || !window.google.maps) return;

    const autocompleteService = new window.google.maps.places.AutocompleteService();

    autocompleteService.getPlacePredictions(
      { input, componentRestrictions: { country: "us" } },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          const results = predictions.map((prediction) => ({
            description: prediction.description,
            placeId: prediction.place_id,
          }));
          setAutocompleteSuggestions(results);
        } else {
          setAutocompleteSuggestions([]);
        }
      }
    );
  }, []);

  // Handle country change
  const handleCountry = (e) => {
    const country = e.target.value;

    // Find the selected country data from stateListAll
    const selectedCountryData = stateListAll?.find(
      (ele) => ele?.name === country
    );

    // If country is found, set the state's territories (assuming each country has a `states` field)
    if (selectedCountryData) {
      setStateTeritory(selectedCountryData.states || []); // Assuming 'states' is an array in the country data
    } else {
      setStateTeritory([]); // Reset states if no country is selected or found
    }
  };

  // Update the predictions when the input value changes
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value); // Update the input value state
    fetchPredictions(value); // Fetch predictions based on the input value
  };

  // Handle suggestion selection (fetch details for the selected place)
  const handleSuggestionSelect = (suggestion) => {
    // Fetch detailed address information only when a suggestion is selected
    fetchPlaceDetails(suggestion.placeId, (details) => {
      setAddressDetails({
        address: details.address,
        city: details.city,
        state: details.state,
        zipCode: details.zipCode,
        country: details.country,
        countryCode: details.countryCode,
        secondAddressline: "",
      });

      // Update state list based on the selected country code
      const result = stateListAll?.find((ele) => ele?.countryCode === details.countryCode);
      setStateTeritory(result?.states || []);
    });
    //setInputValue(suggestion.description); // Set the input field to the selected description
    setAutocompleteSuggestions([]); // Clear suggestions after selection
  };

  // Fetch place details using the Google Places API
  const fetchPlaceDetails = (placeId, callback) => {
    if (!window.google || !window.google.maps) return;

    const placesService = new window.google.maps.places.PlacesService(document.createElement("div"));

    placesService.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
        const addressDetails = {
          address: "",
          city: "",
          state: "",
          zipCode: "",
          country: "",
          countryCode: "",
        };

        place.address_components.forEach((component) => {
          const types = component.types;
          if (types.includes("route"))   addressDetails.address = place.name;
          if (types.includes("locality")) addressDetails.city = component.long_name;
          if (types.includes("administrative_area_level_1")) addressDetails.state = component.short_name;
          if (types.includes("postal_code")) addressDetails.zipCode = component.long_name;
          if (types.includes("country")) {
            addressDetails.country = component.long_name;
            addressDetails.countryCode = component.short_name;
          }
        });

        callback(addressDetails);
      } else {
        callback(null); // In case of an error, you can handle it accordingly
      }
    });
  };

  // Fetch predictions when component mounts or input value changes
  useEffect(() => {
    fetchPredictions(inputValue);
    // eslint-disable-next-line
  }, [inputValue]);

  // Validation onBlur for Address fields
  // const handleBlur = (field) => {
  //   switch (field) {
  //     case "address":
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         address: !addressDetails.address ? "Please enter address" : "",
  //       }));
  //       break;
  //     case "city":
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         city: addressDetails.city?.length <= 1 || !addressDetails.city ? "City must be more than 1 character" : "",
  //       }));
  //       break;
  //     case "state":
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         state: !addressDetails.state ? "Please select state" : "",
  //       }));
  //       break;
  //     case "country":
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         country: !addressDetails.country ? "Please select country" : "",
  //       }));
  //       break;
  //     case "zipCode":
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         zipCode: !addressDetails.zipCode || !/^\d{5}$/.test(addressDetails.zipCode) ? "Zip code must be 5 digits" : "",
  //       }));
  //       break;
  //     default:
  //       break;
  //   }
  // };


  // Function to validate fields dynamically
  const validateField = (field, value) => {
    switch (field) {
      case "address":
        return !value ? "Please enter address" : "";
      case "city":
        return value?.length <= 1 || !value ? "City must be more than 1 character" : "";
      case "state":
        return !value ? "Please select state" : "";
      case "country":
        return !value ? "Please select country" : "";
      case "zipCode":
        const zipCodeRegex = /^\d{5}$/;
        return !value || !zipCodeRegex.test(value) ? "Zip code must be 5 digits" : "";
      default:
        return "";
    }
  };

  // Handle onChange for all fields with dynamic validation
  const handleFieldChange = (e) => {
    const { id, value } = e.target;
    setAddressDetails((prev) => ({ ...prev, [id]: value }));

    // Validate the field dynamically
    const error = validateField(id, value);
    setErrors((prevErrors) => ({ ...prevErrors, [id]: error }));
  };

  // Validation onBlur for Address fields (optional fallback)
  const handleBlur = (field) => {
    const error = validateField(field, addressDetails[field]);
    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
  };


  return (
    <div>
      <Autocomplete onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}>
        <div className="mb-3 position-relative">
          <input
            id="address"
            value={addressDetails.address}
            // onChange={(e) => {
            //    setAddressDetails({ ...addressDetails, address: e.target.value })
            //    handleInputChange(e)
            // }}
            onChange={(e) => {
              handleFieldChange(e)
              handleInputChange(e)
            }
            }
            onBlur={() => handleBlur("address")}
            placeholder="Enter Address Line 1"
            type="text"
            className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
          />
            {errors.address && <div className="text-danger">{errors.address}</div>}
        </div>
      </Autocomplete>

      {/* Display the address suggestions */}
      {autocompleteSuggestions.length > 0 && (
        <ul className="unorderList bgF08 borderRadius8 p-2 text-white">
          {autocompleteSuggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionSelect(suggestion)}
              className="cursorPointer d-flex align-items-center"
            >
              <div className="p-2">
                <img src={locationIcon} alt="locationIcon" />
              </div>
              <div>
                <p className="mb-0 fontSize13">
                  {suggestion.description}
                </p>
              </div>
            </li>
          ))}
        </ul>
      )}

      {/* Form Inputs for City, State, Zip Code, Address, and Country */}
      <div className="mb-3 position-relative">
        <input
          id="secondAddressline"
          placeholder="Enter Address Line 2"
          type="text"
          className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
          value={addressDetails.secondAddressline}
          // onChange={(e) =>
          //   setAddressDetails({ ...addressDetails, secondAddressline: e.target.value })
          // }
          onChange={handleFieldChange}
        />
      </div>

      <div className="mb-3">
        <select
          className="w-100 p-2 rounded rounded-3 form-control border3B3936 supportFormFocus bg-transparent outlineNone text-white"
          name="country"
          id="country"
          value={addressDetails.country}
          // onChange={(e) => {
          //   setAddressDetails({ ...addressDetails, country: e.target.value });
          //   handleCountry(e); // Handle country change to fetch state options
          // }}
          onChange={(e) => {
            handleFieldChange(e)
            handleCountry(e);
          }
          }
          onBlur={() => handleBlur("country")}
        >
          <option value="">Select Country</option>
          {stateListAll.length > 0 &&
            stateListAll.map((ele, index) => (
              <option key={index} value={ele?.name}>
                {ele?.name}
              </option>
            ))}
        </select>
        {errors.country && <div className="text-danger">{errors.country}</div>}
        <div>{stateListAllError && stateListAllError}</div>
      </div>

      <div className="mb-3">
        <select
          className="w-100 p-2 rounded rounded-3 form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
          name="state"
          id="state"
          // onChange={(e) => {
          //   setAddressDetails({ ...addressDetails, state: e.target.value });
          // }}
          onChange={handleFieldChange}
          onBlur={() => handleBlur("state")}
          value={addressDetails?.state}
        >
          <option>Select State Territory</option>
          {stateTeritory?.length > 0 &&
            stateTeritory.map((ele, index) => (
              <option key={index} value={ele?.stateCode}>
                {ele?.name}
              </option>
            ))}
        </select>
        {errors.state && <div className="text-danger">{errors.state}</div>}
      </div>

      <div className="mb-3 position-relative">
        <input
          id="city"
          placeholder="Enter City Name"
          type="text"
          className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
          value={addressDetails.city}
          // onChange={(e) =>
          //   setAddressDetails({ ...addressDetails, city: e.target.value })
          // }
          onChange={handleFieldChange}
          onBlur={() => handleBlur("city")}
        />
        {errors.city && <div className="text-danger">{errors.city}</div>}
      </div>

      <div className="mb-3 position-relative">
        <input
          id="zipCode"
          placeholder="Zip code"
          type="text"
          className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
          value={addressDetails.zipCode}
          // onChange={(e) =>
          //   setAddressDetails({ ...addressDetails, zipCode: e.target.value })
          // }
          onChange={handleFieldChange}
          onBlur={() => handleBlur("zipCode")}
        />
        {errors.zipCode && <div className="text-danger">{errors.zipCode}</div>}
      </div>
    </div>
  );
};

export default AddressAutocomplete;
