// import homepageIcon from "./homepage.jpg";
//import homePage from "./banner_1.webp"
//  import homePage from "./banner_1.webp"
//  import newCarBgImageWebp from "./newCarBgImageWebp.webp"
//  import trialCarImage1 from "./TrialCarImage1.png"
//  import trialCarImage2 from "./TrialCarImage2.png"
//  import trialCarImage3 from "./TrilaCarImage3.jpg"
//  import trialCarImage4 from "./TrialCarImage4.jpg"
//  import newCarImageWeb1 from "./newCarImageWeb1.webp"
//  import croppedNewImage from "./bgCropedImage1.png"
//  import newCarCroppedIMage2 from "./newCarCroppedIMage2.png"
//  import main_car_1 from "./main_car_1.png"
 import car_image_1 from "./car_image_1.webp"
//  import car_image_2 from "./car_image_2.png"
// import homePage from "./banner_3.webp"
import BMW from "./BMW.png"
import Hyundai from "./Hyundai.png"
import Kia from "./Kia.png"
import LucidMotors from "./Lucid.png"
import Mercedes from "./Mercedes.png"
import Rivian from "./Rivian.png"
import Tesla from "./Tesla.png"
import Volkswagen from "./Volkswagen.png"
import dummyImage from "./dummyImage.png"
import founderImage from "./founderImage.jpeg"
import SiriusImage from "./SiriusXM-new.svg"
import SiriusLightImage from "./SiriusLightIcon.png"
import homeLightMode from "./homeLightMode.jpeg"
import AboutUsBgImage from "./AboutUsBGImage.jpg"
import AboutUsImage from "./AboutUsImage.jpg"
// import micahBergdaleImage from "./micahBergdaleImage.jpg"
// import robinsonMorriImage from "./robinsonMorriImage.jpg"
// import tanielArturImage from "./tanielArturImage.jpg"

import micahBergdaleImage from "./micahImage.png"
import robinsonMorriImage from "./robinsonImage.png"
import tanielArturImage from "./tanielImage.png"
import mastercard from "./mastercard.png"
import visa from "./visa.png"
import defaultCard from "./defaultCard.png"
import diners from "./diners.png"
import discover from "./discover.png"
import amex from "./amex.png"
const images = {
  // homepage: homepageIcon,
  homepage: car_image_1,
  homeLightModePage: homeLightMode,
  BMW:BMW,
  Hyundai:Hyundai,
  Kia:Kia,
  "Lucid Motors": LucidMotors,
  Mercedes: Mercedes,
  Rivian: Rivian,
  Tesla: Tesla,
  Volkswagen: Volkswagen,
  dummyImage: dummyImage,
  founderImage: founderImage,
  SiriusImage:SiriusImage,
  SiriusLightImage: SiriusLightImage,
  AboutUsBgImage: AboutUsBgImage,
  AboutUsImage: AboutUsImage,
  micahBergdaleImage: micahBergdaleImage,
  robinsonMorriImage: robinsonMorriImage,
  tanielArturImage: tanielArturImage,
  mastercard:mastercard,
  visa:visa,
  defaultCard:defaultCard,
  diners:diners,
  discover:discover,
  amex:amex
};
export default images;
