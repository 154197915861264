// import React, { useState } from "react";
// import ReactSlider from "react-slider";

// const RangeSlider = ({
//   data,
//   minPrice,
//   maxPrice,
//   filterData,
//   setFilterData,
// }) => {
//   const [values, setValues] = useState([minPrice, maxPrice]); // Initial values for the thumbs

//   const min = minPrice;
//   const max = maxPrice;
//   const step = 50;

//   return (
//     <div>
//       <div className="d-flex justify-content-between">
//         <p className="fontSize14 px-2 mb-1">Price</p>
//         <div>
//           {" "}
//           {values[0]}$/ Day - {values[1]}$/ Day
//         </div>
//       </div>
//       <ReactSlider
//         className="horizontal-slider"
//         thumbClassName="example-thumb"
//         trackClassName="example-track"
//         defaultValue={values}
//         ariaLabel={["Lower thumb", "Upper thumb"]}
//         ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
//         // renderThumb={(props, state) => <div {...props}>{state.valueNow}$</div>}
//         // pearling
//         minDistance={step}
//         min={min}
//         max={max}
//         step={step}
//         onChange={(newValues) => {
//           setValues(newValues);
//           setFilterData((prev) => ({
//             ...prev,
//             minPrice: values[0],
//             maxPrice: values[1],
//           }));
//         }}
//       />
//     </div>
//   );
// };

// export default RangeSlider;


import React, { useState, useEffect } from "react";
import ReactSlider from "react-slider";

const RangeSlider = ({ data, minPrice, maxPrice, filterData, setFilterData }) => {
  // Initially, set values to the selected range in filterData if available, else use minPrice and maxPrice
  const [values, setValues] = useState([
    filterData?.minPrice || minPrice,
    filterData?.maxPrice || maxPrice,
  ]);

  const step = 1;

  // Use effect to adjust the selected values when minPrice or maxPrice changes
  useEffect(() => {
    // Don't reset values if the user has already selected a range
    if (filterData?.minPrice && filterData?.maxPrice) {
      // Keep the current selected range relative to the new minPrice and maxPrice
      const newMinPrice = Math.max(minPrice, filterData.minPrice);
      const newMaxPrice = Math.min(maxPrice, filterData.maxPrice);
      setValues([newMinPrice, newMaxPrice]);
    } else {
      // Reset the values if no range was previously selected
      setValues([minPrice, maxPrice]);
    }
  }, [minPrice, maxPrice, filterData]);

  const handleSliderChange = (newValues) => {
    setValues(newValues);
    setFilterData((prev) => ({
      ...prev,
      minPrice: newValues[0],
      maxPrice: newValues[1],
    }));
  };

  return (
    <div>
      <div className="d-flex justify-content-between fontSize14">
        <p className="px-2 mb-1">Price</p>
        <div>
          $ {values[0]}/ Day - $ {values[1]}/ Day
        </div>
      </div>
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        value={values} // Use `value` to bind slider to state
        ariaLabel={["Lower thumb", "Upper thumb"]}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        minDistance={step}
        min={minPrice}
        max={maxPrice}
        step={step}
        onChange={handleSliderChange}
      />
    </div>
  );
};

export default RangeSlider;
