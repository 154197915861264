// import React, { useState, useEffect } from "react";
// import {
//   useStripe,
//   useElements,
//   CardElement,
//   AddressElement,
// } from "@stripe/react-stripe-js";
// import axios from "axios";
// import { BASE_URL } from "../api";

// const Checkout = ({
//   closeModal,
//   isCardElement = true,
//   editCardDetailsForStripe,
// }) => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [isProcessing, setIsProcessing] = useState(false);
//   const [clientSecret, setClientSecret] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");

//   const token = localStorage.getItem("AccessToken");

//   useEffect(() => {
//     const fetchIntentResponse = async () => {
//       try {
//         const response = await axios.get(`${BASE_URL}savedcard/intent`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         setClientSecret(JSON.parse(response.data?.data)?.client_secret);
//       } catch (error) {
//         console.error("Error fetching intent response:", error);
//       }
//     };

//     fetchIntentResponse();
//   }, [token]);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setIsProcessing(true);
//     setErrorMessage("");

//     if (!stripe || !elements || !clientSecret) {
//       setErrorMessage("Stripe has not loaded properly.");
//       setIsProcessing(false);
//       return;
//     }

//     const cardElement = elements.getElement(CardElement);
//     const addressElement = elements.getElement(AddressElement);

//     if (!cardElement || !addressElement) {
//       setErrorMessage("Stripe elements are not properly mounted.");
//       setIsProcessing(false);
//       return;
//     }

//     const { complete, value } = await addressElement.getValue();

//     if (complete) {
//     }

//     const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
//       payment_method: {
//         card: cardElement,
//         billing_details: {
//           address: {
//             ...value?.address,
//           },
//           email: "mrompalli@gmail.com", // Replace with actual email
//           name: value?.name, // Replace with actual name
//         },
//       },
//     });

//     setIsProcessing(false);

//     if (error) {
//       setErrorMessage(error?.message);
//       console.error("Error confirming setup intent:", error);
//     } else {
//       addressElement.clear();
//       cardElement.clear();
//       setTimeout(() => {
//         closeModal();
//       }, 500);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       {isCardElement ? (
//         <CardElement />
//       ) : (
//         <div>
//           <div
//             key={editCardDetailsForStripe?.id}
//             className="col-12 mb-1 text-dark px-3 borderE1 borderRadius12"
//           >
//             <div className="p-4 mb-1 m-1">
//               <div>
//                 <div className="mb-2">
//                   xxxx xxxx xxxx {editCardDetailsForStripe?.cardNumber}
//                 </div>
//                 <div className="mb-2">{editCardDetailsForStripe?.name}</div>
//                 <div className="d-flex justify-content-between align-items-center">
//                   <div className="mb-2">
//                     {editCardDetailsForStripe?.expiryDate}
//                   </div>
//                   <div className="mx-3">
//                     {editCardDetailsForStripe?.cardIssuedBy}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//       <AddressElement
//         className="my-4 sr-addressElement"
//         options={{ mode: "billing" }}
//       />
//       {errorMessage && <div className="error">{errorMessage}</div>}
//       <button
//         className="mt-2 p-2 outlineNone border-0 borderRadius8 w-100 bgCE1"
//         type="submit"
//         disabled={!stripe || isProcessing}
//       >
//         {isProcessing ? "Processing..." : "Save Card"}
//       </button>
//     </form>
//   );
// };

// export default Checkout;

import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { BASE_URL } from "../api";
import MetaTags from "../Components/MetaTag";
const Checkout = ({
  closeModal,
  isCardElement = true,
  editCardDetailsForStripe,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [billingAddress, setBillingAddress] = useState(null);

  const [editCardError, setEditCardError] = useState("")

  const token = localStorage.getItem("AccessToken");

  const cardElement = elements.getElement(CardElement);
  const addressElement = elements.getElement(AddressElement);

  useEffect(() => {
    // Fetch setup intent from the server (similar to your Angular method)
    const fetchIntentResponse = async () => {
      try {
        const response = await axios.get(`${BASE_URL}savedcard/intent`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClientSecret(JSON.parse(response.data?.data)?.client_secret);
      } catch (error) {
        console.error("Error fetching intent response:", error);
      }
    };

    fetchIntentResponse();
  }, [token]);

  useEffect(() => {
    // Set default billing address if editing card details
    if (!isCardElement && editCardDetailsForStripe) {
      const { address, name } = editCardDetailsForStripe;
      setBillingAddress({
        name,
        address: {
          line1: address?.line1,
          line2: address?.line2,
          city: address?.city,
          state: address?.state,
          postal_code: address?.postalCode,
          country: address?.country,
        },
      });
    }
  }, [isCardElement, editCardDetailsForStripe]);

  useEffect(() => {
    if (isCardElement) {
      addressElement?.clear();
      cardElement?.clear();
    }
  }, [addressElement, cardElement, isCardElement]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
    setErrorMessage("");

    if (!stripe || !elements || !clientSecret) {
      setErrorMessage("Stripe has not loaded properly.");
      setIsProcessing(false);
      return;
    }

    // const cardElement = elements.getElement(CardElement);
    // const addressElement = elements.getElement(AddressElement);

    if (!cardElement || !addressElement) {
      setErrorMessage("Stripe elements are not properly mounted.");
      setIsProcessing(false);
      return;
    }

    const { complete, value } = await addressElement.getValue();

    if (!complete) {
      setErrorMessage("Address fields are incomplete.");
      setIsProcessing(false);
      return;
    }

    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          address: {
            ...value?.address,
          },
          // email: "mrompalli@gmail.com", // Replace with actual email if needed
          name: value?.name, // Replace with actual name if needed
        },
      },
    });

    setIsProcessing(false);

    if (error) {
      setErrorMessage(error?.message);
      console.error("Error confirming setup intent:", error);
    } else {
      addressElement.clear();
      cardElement.clear();
      setTimeout(() => {
        closeModal();
      }, 500);
    }
  };

  const EditCardDetails = async () => {
    const { complete, value } = await addressElement.getValue();
    if(complete){
    let addressInput = {
      name: value.name,
      city: value.address.city,
      country: value.address.country,
      line1: value.address.line1,
      line2: value.address.line2,
      postalCode: value.address.postal_code,
      state: value.address.state,
    };
    
    try {
      const response = await axios.post(
        `${BASE_URL}savedcard/updateAddress/${editCardDetailsForStripe?.id}`, // URL to the API endpoint
        addressInput,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', 
          }
        }
      );
  
      // Handle the response
      // console.log('Response:', response.data);
      closeModal()
    } catch (error) {
      // Handle any errors
      setEditCardError('Error:', error.response || error.message);
    }
  }
  };

  return (
    <>
    <MetaTags
        title="Joulez - Electric Vehicle Car Rental Company Based in New York City and Los Angeles."
        description="Joulez is an Electric Vehicle company based out of New York City and Los Angeles. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen and Hyundai cars."
      />

<div>
      {isCardElement ? (
        <>
        <div className="sr-input">
        <CardElement />
        </div>
          <AddressElement
            className="my-4 sr-addressElement"
            options={{
              mode: "billing",
            }}
          />
        </>
      ) : (
        <div>
          <div
            key={editCardDetailsForStripe?.id}
            className="col-12 mb-1 text-dark px-3 borderE1 borderRadius12"
          >
            <div className="p-4 mb-1 m-1">
              <div>
                <div className="mb-2">
                  XXXX XXXX XXXX {editCardDetailsForStripe?.cardNumber}
                </div>
                <div className="mb-2">{editCardDetailsForStripe?.name}</div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="mb-2">
                    {editCardDetailsForStripe?.expiryDate}
                  </div>
                  <div className="mx-3">
                    {editCardDetailsForStripe?.cardIssuedBy}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Mount the AddressElement */}
      {billingAddress && (
        <AddressElement
          className="my-4 sr-addressElement"
          options={{
            mode: "billing",
            defaultValues: billingAddress,
          }}
        />
      )}

      {errorMessage && <div className="fontColor4031">{errorMessage}</div>}
      <button
        className="mt-2 p-2 outlineNone border-0 borderRadius8 w-100 bgCE1 text-white"
        type="submit"
        disabled={!stripe || isProcessing}
      >
        {isProcessing ? "Processing..." : isCardElement ? <div onClick={handleSubmit}>Save Card</div> :<div onClick={EditCardDetails}>Update Card</div>}
      </button>
    </div>
    </>
   
  );
};

export default Checkout;
