// import React from 'react'

// const Sitemap = () => {
//   return (
//     <div>
//       <h1>
//         Site Map
//       </h1>
//     </div>
//   )
// }

// export default Sitemap

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from "../Store/pageSlice";

const sitemapData = {
  url: "https://drivejoulez.com/",
  make: {
    name: "Browse by Make",
    list: [
      {
        name: "Kia",
        loc: "cars-list/make/Kia",
        meta: {
          title:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          description: "car kia",
          robots: "index,follow",
          ogTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          ogDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          ogImage: "https://drivejoulez.com/assets/images/joulez.png",
          twitterTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          twitterDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          twitterUrl: "https://drivejoulez.com",
        },
      },
      {
        name: "Mercedes",
        loc: "cars-list/make/Mercedes",
        meta: {
          title:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          description: "car mercedes",
          robots: "index,follow",
          ogTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          ogDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          ogImage: "https://drivejoulez.com/assets/images/joulez.png",
          twitterTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          twitterDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          twitterUrl: "https://drivejoulez.com",
        },
      },
      {
        name: "Tesla",
        loc: "cars-list/make/Tesla",
        meta: {
          title:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          description: "car tesla",
          robots: "index,follow",
          ogTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          ogDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          ogImage: "https://drivejoulez.com/assets/images/joulez.png",
          twitterTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          twitterDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          twitterUrl: "https://drivejoulez.com",
        },
      },
      {
        name: "BMW",
        loc: "cars-list/make/BMW",
        meta: {
          title:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          description: "car bmw",
          robots: "index,follow",
          ogTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          ogDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          ogImage: "https://drivejoulez.com/assets/images/joulez.png",
          twitterTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          twitterDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          twitterUrl: "https://drivejoulez.com",
        },
      },
      {
        name: "Hyundai",
        loc: "cars-list/make/Hyundai",
        meta: {
          title:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          description: "car hyundai",
          robots: "index,follow",
          ogTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          ogDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          ogImage: "https://drivejoulez.com/assets/images/joulez.png",
          twitterTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          twitterDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          twitterUrl: "https://drivejoulez.com",
        },
      },
      {
        name: "Lucid Motors",
        loc: "cars-list/make/Lucid Motors",
        meta: {
          title:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          description: "car Lucid Motors",
          robots: "index,follow",
          ogTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          ogDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          ogImage: "https://drivejoulez.com/assets/images/joulez.png",
          twitterTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          twitterDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          twitterUrl: "https://drivejoulez.com",
        },
      },
      {
        name: "Polestar",
        loc: "cars-list/make/Polestar",
        meta: {
          title:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          description: "car polestar",
          robots: "index,follow",
          ogTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          ogDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          ogImage: "https://drivejoulez.com/assets/images/joulez.png",
          twitterTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          twitterDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          twitterUrl: "https://drivejoulez.com",
        },
      },
      {
        name: "Volkswagen",
        loc: "cars-list/make/Volkswagen",
        meta: {
          title:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          description: "car volkswagen",
          robots: "index,follow",
          ogTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          ogDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          ogImage: "https://drivejoulez.com/assets/images/joulez.png",
          twitterTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          twitterDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          twitterUrl: "https://drivejoulez.com",
        },
      },
      {
        name: "Rivian",
        loc: "cars-list/make/Rivian",
        meta: {
          title:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          description: "car Rivian",
          robots: "index,follow",
          ogTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          ogDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          ogImage: "https://drivejoulez.com/assets/images/joulez.png",
          twitterTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          twitterDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          twitterUrl: "https://drivejoulez.com",
        },
      },
      {
        name: "Ford",
        loc: "cars-list/make/Ford",
        meta: {
          title:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          description: "car ford",
          robots: "index,follow",
          ogTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          ogDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian, Ford, Fisker and Hyundai cars.",
          ogImage: "https://drivejoulez.com/assets/images/joulez.png",
          twitterTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          twitterDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian, Ford, Fisker and Hyundai cars.",
          twitterUrl: "https://drivejoulez.com",
        },
      },
      {
        name: "Fisker",
        loc: "cars-list/make/Fisker",
        meta: {
          title:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          description: "car fisker",
          robots: "index,follow",
          ogTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          ogDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian, Ford, Fisker and Hyundai cars.",
          ogImage: "https://drivejoulez.com/assets/images/joulez.png",
          twitterTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          twitterDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian, Ford, Fisker and Hyundai cars.",
          twitterUrl: "https://drivejoulez.com",
        },
      },
    ],
  },
  country: {
    name: "Browse by Location",
    list: [
      {
        name: "United States",
        states: [
          {
            id: 2,
            name: "Bronx",
            loc: "cars-list/country/united-states/bronx",
            meta: {
              title:
                "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
              description: "car description",
              robots: "index,follow",
              ogTitle:
                "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
              ogDescription:
                "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
              ogImage: "https://drivejoulez.com/assets/images/joulez.png",
              twitterTitle:
                "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
              twitterDescription:
                "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
              twitterUrl: "https://drivejoulez.com",
            },
          },
          {
            id: 4,
            name: "Los Angeles",
            loc: "cars-list/country/united-states/los angeles",
            meta: {
              title:
                "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
              description: "car description",
              robots: "index,follow",
              ogTitle:
                "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
              ogDescription:
                "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
              ogImage: "https://drivejoulez.com/assets/images/joulez.png",
              twitterTitle:
                "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
              twitterDescription:
                "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
              twitterUrl: "https://drivejoulez.com",
            },
          },
          {
            id: 4,
            name: "Columbus Circle",
            loc: "cars-list/country/united-states/columbus circle",
            meta: {
              title:
                "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
              description: "car description",
              robots: "index,follow",
              ogTitle:
                "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
              ogDescription:
                "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
              ogImage: "https://drivejoulez.com/assets/images/joulez.png",
              twitterTitle:
                "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
              twitterDescription:
                "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
              twitterUrl: "https://drivejoulez.com",
            },
          },
          {
            id: 5,
            name: "Brooklyn",
            loc: "cars-list/country/united-states/brooklyn",
            meta: {
              title:
                "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
              description: "car description",
              robots: "index,follow",
              ogTitle:
                "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
              ogDescription:
                "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
              ogImage: "https://drivejoulez.com/assets/images/joulez.png",
              twitterTitle:
                "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
              twitterDescription:
                "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
              twitterUrl: "https://drivejoulez.com",
            },
          },
          {
            id: 6,
            name: "Elmhurst",
            loc: "cars-list/country/united-states/Elmhurst",
            meta: {
              title:
                "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
              description: "car description",
              robots: "index,follow",
              ogTitle:
                "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
              ogDescription:
                "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
              ogImage: "https://drivejoulez.com/assets/images/joulez.png",
              twitterTitle:
                "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
              twitterDescription:
                "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
              twitterUrl: "https://drivejoulez.com",
            },
          },
        ],
      },
    ],
  },
  pages: {
    name: "Browse by Page",
    list: [
      {
        name: "Book a Car",
        loc: "cars-list",
        meta: {
          title:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          description: "Book car",
          robots: "index,follow",
          ogTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          ogDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          ogImage: "https://drivejoulez.com/assets/images/joulez.png",
          twitterTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          twitterDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          twitterUrl: "https://drivejoulez.com",
        },
      },
      {
        name: "Our Cars",
        loc: "our-fleet",
        meta: {
          title:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          description: "Book car",
          robots: "index,follow",
          ogTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          ogDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          ogImage: "https://drivejoulez.com/assets/images/joulez.png",
          twitterTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          twitterDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          twitterUrl: "https://drivejoulez.com",
        },
      },
      {
        name: "Contact Us",
        loc: "support",
        meta: {
          title:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          description: "Contact joulez",
          robots: "index,follow",
          ogTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          ogDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          ogImage: "https://drivejoulez.com/assets/images/joulez.png",
          twitterTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          twitterDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          twitterUrl: "https://drivejoulez.com",
        },
      },
      // {
      //   name: "Lightning OS",
      //   loc: "lightningOS",
      //   meta: {
      //     title:
      //       "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
      //     description: "lightning OS",
      //     robots: "index,follow",
      //     ogTitle:
      //       "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
      //     ogDescription:
      //       "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
      //     ogImage: "https://drivejoulez.com/assets/images/joulez.png",
      //     twitterTitle:
      //       "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
      //     twitterDescription:
      //       "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
      //     twitterUrl: "https://drivejoulez.com",
      //   },
      // },
      {
        name: "Corporate Rentals",
        loc: "corporateRentals",
        meta: {
          title:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          description: "Corporate Rentals",
          robots: "index,follow",
          ogTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          ogDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          ogImage: "https://drivejoulez.com/assets/images/joulez.png",
          twitterTitle:
            "Joulez - Electric Vehicle Car Rental Company Based In New York City.",
          twitterDescription:
            "Joulez is an Electric Vehicle company based out of New York City. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen, Rivian and Hyundai cars.",
          twitterUrl: "https://drivejoulez.com",
        },
      },
    ],
  },
};

const Sitemap = () => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage("sitemap"));
  }, [dispatch]);

  return (
    <div className= " bodyBlack fontAntarctica d-flex flex-column min-vh-100">
      <div className="container">
      <div className="container-fluid d-flex flex-column px-0">
  
  {/* Browse by Make */}
  <section>
    <h2 className="siteMapTopMargin P33 fontWeight400">{sitemapData.make.name}</h2>
    <ul className="horizontal-list P33 fontWeight400">
      {sitemapData.make.list.map((item) => (
        <li key={item.name}>
          <Link to={`/${item.loc}`} className="text-decoration-none click fontWeight400">
            {item.name}
          </Link>
        </li>
      ))}
    </ul>
  </section>
  <hr></hr>
  {/* Browse by Location */}
  <section>
    <h2 className="P33 fontWeight400">{sitemapData.country.name}</h2>
    <ul className="location-list fontWeight400">
      {sitemapData.country.list.map((country) => (
        <li key={country.name}>
          <ul>
            {country.states.map((state) => (
              <li key={state.id}>
                <Link to={`/${state.loc}`} className="text-decoration-none click fontWeight400">
                  {state.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  </section>
  <hr></hr>

  {/* Browse by Page */}
  <section className="mb-5 ">
    <h2 className="P33 fontWeight400">{sitemapData.pages.name}</h2>
    <ul className="horizontal-list P33 fontWeight400">
      {sitemapData.pages.list.map((page) => (
        <li key={page.name}>
          <Link to={`/${page.loc}`} className="text-decoration-none click fontWeight400">
            {page.name}
          </Link>
        </li>
      ))}
    </ul>
  </section>
</div>

      </div>
   
    </div>
  );
  
};

export default Sitemap;
