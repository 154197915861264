import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lightningOS from "../assets/Images/lightningOS.webp";
import tracking from "../assets/Images/tracking.png";
import secure from "../assets/Images/secure.png";
import maintenance from "../assets/Images/maintenance.png";
import batteryPower from "../assets/Images/battery-power.png";
import lightningOS2 from "../assets/Images/lightningOS2.webp";
import lightningOS3 from "../assets/Images/lightningOS3.webp";
import lightningOS4 from "../assets/Images/lightningOS4.webp";
import { setCurrentPage } from '../Store/pageSlice';

const Lighting = () => {
    const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
    const dispatch = useDispatch()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        dispatch(setCurrentPage("lightningOS"));
      }, [dispatch]);
    

    return (
        <div className={`${isDarkMode ? 'bg-dark text-white' : 'bg-light text-dark'} d-flex flex-column  object-fit-cover`}>
            <div className=''>
                <div className="d-flex flex-column align-items-center position-relative">
                    {/* Image with responsive height for mobile */}
                    <img
                        className="object-fit-cover w-100 d-none d-md-block vh-100"
                        src={lightningOS}
                        alt="Corporate Rentals"
                    />
                    {/* For mobile screen, the image height is reduced */}
                    <img
                        className="object-fit-cover w-100 d-md-none"
                        src={lightningOS}
                        style={{ height: "50vh" }} // Adjust this to 50-60% as needed
                        alt="Corporate Rentals"
                    />
                    {/* <img
                    className="object-fit-cover w-100 d-none d-md-block vh-100"
                    src={lightningOS}
                    alt="joulez-lightningOS"
                /> */}
                    <div className="position-absolute fontAtacama top-50 start-50 translate-middle text-center text-white">
                        <h1>Lightning OS</h1>
                        <p className="lead">A place where we manage our fleet, vehicles, and guests</p>
                    </div>

                </div>
                <div className="container fontWeight400 ">
                    <div className="row text-center fontMontserrat  mt-5">
                        <div className="col-6 col-md-3 d-flex flex-column align-items-center mb-4">
                            <img src={tracking} alt="Tracking" className="img-fluid mb-2" />
                            <p className="text-center fontMontserrat">Access to every vehicle in your fleet with live tracking</p>
                        </div>
                        <div className="col-6 col-md-3 d-flex flex-column align-items-center mb-4">
                            <img src={secure} alt="Secure" className="img-fluid mb-2" />
                            <p className="text-center fontMontserrat">Safe and Secure Vehicle Controls</p>
                        </div>
                        <div className="col-6 col-md-3 d-flex flex-column align-items-center mb-4">
                            <img src={maintenance} alt="Maintenance" className="img-fluid mb-2" />
                            <p className="text-center fontMontserrat">Manage your Maintenance requests here</p>
                        </div>
                        <div className="col-6 col-md-3 d-flex flex-column align-items-center mb-4">
                            <img src={batteryPower} alt="Battery" className="img-fluid mb-2" />
                            <p className="text-center fontMontserrat">Keep track of your EV's Battery Levels</p>
                        </div>
                    </div>


                    <div className="row align-items-center"> {/* Added margin bottom for spacing */}
                        <div className="col-lg-6 order-lg-2 d-flex justify-content-center">
                            <img
                                src={lightningOS2}
                                alt="Fleet management"
                                className="img-fluid rounded-5 p-3 os-height"
                                style={{ height: '75vh', objectFit: 'cover' }} // Keep the height consistent
                            />
                        </div>
                        <div className="col-lg-6 text-lg-start text-center d-flex flex-column justify-content-center" style={{ minHeight: '30vh' }}>
                            <h2 className='fontAtacama'>Keeping Your Fleet in Top Gear</h2>
                            <p className='fontAntarctica'>
                                Experience unparalleled control over your electric vehicle (EV) car rental fleet with our advanced
                                software solution. From overseeing the performance of individual vehicles to optimizing maintenance
                                schedules, our platform empowers administrators to make informed decisions for a seamlessly operating
                                fleet.
                            </p>
                        </div>
                    </div>
                    <div className='container'>
                        <div className="row align-items-center"> {/* Added margin bottom for spacing */}
                            <div className="col-lg-6 d-flex justify-content-center">
                                <img
                                    src={lightningOS3}
                                    alt="EV management"
                                    className="img-fluid rounded-5 p-3 os-height"
                                    style={{ height: '75vh', objectFit: 'cover' }} // Keep the height consistent
                                />
                            </div>
                            <div className="col-lg-6 text-lg-start text-center d-flex flex-column justify-content-center" style={{ minHeight: '30vh' }}>
                                <h2 className='fontAtacama'>Looking After Your EVs</h2>
                                <p className='fontAntarctica'>
                                    Take charge of each EV in your rental fleet with precision and ease using our dedicated software.
                                    From tracking the location of vehicles in real-time to streamlining maintenance processes, our solution
                                    ensures that each vehicle operates at its optimal performance level.
                                </p>
                            </div>
                        </div>


                    </div>

                    <div className="row align-items-center"> {/* Added margin bottom for spacing */}
                        <div className="col-lg-6 order-lg-2 d-flex justify-content-center">
                            <img
                                src={lightningOS4}
                                alt="Driver management"
                                className="img-fluid rounded-5 p-3 os-height"
                                style={{ height: '75vh', objectFit: 'cover' }} // Keep the height consistent
                            />
                        </div>
                        <div className="col-lg-6 text-lg-start text-center d-flex flex-column justify-content-center" style={{ minHeight: '30vh' }}>
                            <h2 className='fontAtacama'>Taking Care of Your Drivers</h2>
                            <p className='fontAntarctica'>
                                Effortlessly handle the logistics of drivers within your EV car rental ecosystem through our intuitive
                                software. From user registrations and profile management to monitoring driver activities, our platform
                                ensures a seamless experience for both administrators and drivers.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    );
}

export default Lighting;
