import searchIcon from "../../assets/svg/searchIcon.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Search = ({ toggle }) => {
  const navigate = useNavigate();
  const currentButtonStatus = useSelector((store) => store?.currentButton);
  const newFilteredDropGoogleLocations = localStorage.getItem("newFilteredDropGoogleLocations")
  const newFilteredPickupGoogleLocations = JSON.parse(localStorage.getItem("newFilteredPickupGoogleLocations"))

  const handleClickSearch = () => {
    toggle();
    if (!currentButtonStatus?.disableSearchButton) {
      navigate("/cars-list");
    }
    if(!newFilteredDropGoogleLocations){
      localStorage.setItem("newFilteredDropGoogleLocations", JSON.stringify(newFilteredPickupGoogleLocations))
    }
  };

  return (
    <div>
      <div
        className={`col-lg-2 searchIconContainer ${
          currentButtonStatus?.disableSearchButton ? "bgBA3" : "bgCE1"
        } d-none d-sm-none d-md-none d-lg-flex  justify-content-center  align-items-center`}
        onClick={handleClickSearch}
      >
        <img
          className="searchIcon"
          src={searchIcon}
          alt="searchIcon"
        />
      </div>
      {/* <hr className="my-2 bgD0C7 d-block d-lg-none"/> */}
      <div className="w-100 d-flex d-sm-flex d-md-flex d-lg-none  justify-content-center  align-items-center">
        <button
          className={`searchButton fontAntarctica fontSize13 bgCE1 text-white mt-3 mt-lg-0 ${currentButtonStatus?.disableSearchButton ? "bgBA3" : "bgCE1"}`}
          onClick={handleClickSearch}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default Search;
