import React, { useState } from "react";
 
const ShowPricingDetails = ({ estimatedPrice }) => {
  const [showDetails, setShowDetails] = useState(false);
 
  const {
    baseRate,
    baseRatePerDay,
    stateTax,
    stateTaxPercent,
    cityTax,
    cityTaxPercent,
    useTax,
    useTaxPercent,
    metropolitanTax,
    metropolitanTaxPercent,
    artMetroPierAndExpoAuth,
    artMetroPierAndExpoAuthTaxPercent,
    artTaxLocalPortion,
    artTaxLocalPortionTaxPercent,
    countyDistrictTaxSp,
    countyDistrictTaxSpPercent,
    countyLocalTaxSi,
    countyLocalTaxSiPercent,
    tourismTax,
    tourismTaxPercent,
    total,
    preTripCharges,
    rentalCoverageCharge,
    additionalCoverageCharge,
    eventExtrasCharges,
    discount,
    noOfdays,
    additionalCharges,
    pickupTransportationCharge,
    dropoffTransportationCharge,
    ageFee,
    taxTotal,
    ageFeePerDay
  } = estimatedPrice || {};
 
 
 
  return (
    <div>
      <div className="fontcolorFFF fontSize13 fontAntarctica bg3B3936 p-3 borderRadius12">
        <div>
          <div className="d-flex justify-content-between align-items-center  py-1">
            <div className="fontSize17">Pricing Details</div>
            <div
              onClick={() => {
                setShowDetails(!showDetails);
              }}
              className="fontColorCE1 cursorPointer"
            >
              {showDetails ? "View Less" : "View Detailed"}
            </div>
          </div>
          <hr className="my-1" />
          <div className="d-flex justify-content-between align-items-center  mt-1 py-1">
            <div>
              Base rate{" "}
              <span className="fontSize10">
              {`(${noOfdays} ${
                noOfdays && noOfdays > 1 ? "days" : "day"
              } @ $ ${baseRatePerDay?.toFixed(2)}*/day)`}
              </span>
            </div>
            <div>$ {baseRate?.toFixed(2)}</div>
          </div>
          <div>
          {ageFee !== 0 && (
            <div>
              <div className="d-flex justify-content-between align-items-center  py-1">
                <div>Age Fee
                {" "}
                <span className="fontSize10">
              {`(${noOfdays} ${
                noOfdays && noOfdays > 1 ? "days" : "day"
              } @ $ ${ageFeePerDay?.toFixed(2)}*/day)`}
                </span>
                   </div>
                <div>$ {ageFee?.toFixed(2)}</div>
              </div>
            </div>
          )}

          {taxTotal !==0 && (
            <div> 
              <div className="d-flex justify-content-between align-items-center  py-1">
                <div>Taxes</div>
                <div>$ {taxTotal?.toFixed(2)}</div>
              </div>
              {showDetails && (
              <div>
                {stateTax !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>State Tax {stateTaxPercent}%</div>
                    <div>$ {stateTax?.toFixed(2)}</div>
                  </div>
                )}
                {cityTax !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>City Tax {cityTaxPercent}%</div>
                    <div>$ {cityTax?.toFixed(2)}</div>
                  </div>
                )}
 
                {useTax !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>Use Tax {useTaxPercent}%</div>
                    <div>$ {useTax?.toFixed(2)}</div>
                  </div>
                )}
                {metropolitanTax !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>Metropolitan Tax {metropolitanTaxPercent}%</div>
                    <div>$ {metropolitanTax?.toFixed(2)}</div>
                  </div>
                )}
                {artMetroPierAndExpoAuth !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>
                      ART Metro Pier and Expo Auth Tax{" "}
                      {artMetroPierAndExpoAuthTaxPercent}%
                    </div>
                    <div>$ {artMetroPierAndExpoAuth?.toFixed(2)}</div>
                  </div>
                )}
                {artTaxLocalPortion !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>
                      ART Tax-Local Portion Tax {artTaxLocalPortionTaxPercent}%
                    </div>
                    <div>$ {artTaxLocalPortion?.toFixed(2)}</div>
                  </div>
                )}
                {countyDistrictTaxSp !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>
                      County District Tax SP {countyDistrictTaxSpPercent}%
                    </div>
                    <div>$ {countyDistrictTaxSp?.toFixed(2)}</div>
                  </div>
                )}
                {countyLocalTaxSi !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>County Local Tax SI {countyLocalTaxSiPercent}%</div>
                    <div>$ {countyLocalTaxSi?.toFixed(2)}</div>
                  </div>
                )}
                {tourismTax !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>Tourism Tax {tourismTaxPercent}%</div>
                    <div>$ {tourismTax?.toFixed(2)}</div>
                  </div>
                )}
              </div>
            )}
            </div>
          )}
            {/* <div className="d-flex align-self-start py-1">Taxes</div> 
            {showDetails && (
              <div>
                {stateTax !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>State Tax {stateTaxPercent}%</div>
                    <div>$ {stateTax?.toFixed(2)}</div>
                  </div>
                )}
                {cityTax !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>City Tax {cityTaxPercent}%</div>
                    <div>$ {cityTax?.toFixed(2)}</div>
                  </div>
                )}
 
                {useTax !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>Use Tax {useTaxPercent}%</div>
                    <div>$ {useTax?.toFixed(2)}</div>
                  </div>
                )}
                {metropolitanTax !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>Metropolitan Tax {metropolitanTaxPercent}%</div>
                    <div>$ {metropolitanTax?.toFixed(2)}</div>
                  </div>
                )}
                {artMetroPierAndExpoAuth !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>
                      ART Metro Pier and Expo Auth Tax{" "}
                      {artMetroPierAndExpoAuthTaxPercent}%
                    </div>
                    <div>$ {artMetroPierAndExpoAuth?.toFixed(2)}</div>
                  </div>
                )}
                {artTaxLocalPortion !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>
                      ART Tax-Local Portion Tax {artTaxLocalPortionTaxPercent}%
                    </div>
                    <div>$ {artTaxLocalPortion?.toFixed(2)}</div>
                  </div>
                )}
                {countyDistrictTaxSp !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>
                      County District Tax SP {countyDistrictTaxSpPercent}%
                    </div>
                    <div>$ {countyDistrictTaxSp?.toFixed(2)}</div>
                  </div>
                )}
                {countyLocalTaxSi !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>County Local Tax SI {countyLocalTaxSiPercent}%</div>
                    <div>$ {countyLocalTaxSi?.toFixed(2)}</div>
                  </div>
                )}
                {tourismTax !== 0 && (
                  <div className="d-flex justify-content-between align-items-center  py-1 opacity70">
                    <div>Tourism Tax {tourismTaxPercent}%</div>
                    <div>$ {tourismTax?.toFixed(2)}</div>
                  </div>
                )}
              </div>
            )} */}
          </div>
          {preTripCharges !== 0 && (
            <div>
              <div className="d-flex justify-content-between align-items-center  py-1">
                <div>Extras</div>
                <div>$ {preTripCharges?.toFixed(2)}</div>
              </div>
              {showDetails && (
                <div>
                  {eventExtrasCharges?.map((extras) => (
                    
                    <div 
                    key={extras?.extrasChart?.id}
                    className="d-flex justify-content-between align-items-center opacity70 my-1">
                      <div>{extras?.extrasChart?.name} <span className="fontSize9">  ($ {extras?.extrasChart?.price}/{extras?.extrasChart?.priceModel})
                        </span> </div>
                      <div>$ {extras?.extrasChart?.price}.00</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {rentalCoverageCharge !== 0 && (
            <div>
              <div className="d-flex justify-content-between align-items-center  py-1">
                <div>Rental Coverage</div>
                <div>$ {rentalCoverageCharge?.toFixed(2)}</div>
              </div>
            </div>
          )}
          {additionalCoverageCharge !== 0 && (
            <div className="d-flex justify-content-between align-items-center  py-1">
              <div>Additional Coverage</div>
              <div>$ {additionalCoverageCharge?.toFixed(2)}</div>
            </div>
          )}
          {discount !== 0 && (
            <div className="d-flex justify-content-between align-items-center">
              <div>Discount</div>
              <div>-$ {discount?.toFixed(2)}</div>
            </div>
          )}
          {additionalCharges !== 0 && (
            <div>
              {additionalCharges?.map((charges) => (
                <div key={charges?.extrasChart?.name} className="d-flex justify-content-between align-items-center lh-lg">
                  <div>
                    {`${charges?.extrasChart?.name}`}
                    
                    <span className="fontSize10">
                      {`(${noOfdays} ${
                      noOfdays && noOfdays > 1 ? "days" : "day"
                    } @ $ ${charges?.originalPrice?.toFixed(2)}*/day)`
                    
                    }
                    </span>
                  </div>
                  <div>$ {charges?.chargeableAmount?.toFixed(2)}</div>
                </div>
              ))}
            </div>
          )}
           {(pickupTransportationCharge !== 0 || dropoffTransportationCharge !==0) && (
            <div className="d-flex justify-content-between align-items-center  py-1">
              <div>Transportation Charges</div>
              <div>$ {(pickupTransportationCharge+dropoffTransportationCharge)?.toFixed(2)}</div>
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center  py-1">
            <div className="fontSize17">Total</div>
            <div className="fontSize17">$ {total?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
 
export default ShowPricingDetails;
 