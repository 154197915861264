export const NAVBAR_LIST = [
  { identifer: "Our Cars", path: "/our-fleet" },
  { identifer: "Locations", path: "/location" },
  { identifer: "Support", path: "/support" },
  { identifer: "Corporate Rentals", path: "/corporateRentals" },
  // { identifer: "My Account", path: "/user-profile" },
  // { identifer: "About Us", path: "/about-us" },
];

export const FOOTER_DETAILS = {
  // bookingInfo: ["Book a Car", "Our Cars", "Locations", "Lightning OS", "FAQs"],
   bookingInfo: [
    { name: "Book a Car", path: "/cars-list" },
    // { name: "Our Cars", path: "/our-fleet" },
    // { name: "Locations", path: "/location" },
    // { name: "Lightning OS", path: "/lightning-os" },
    // {name:"Lightning OS", path:"/lightningOS"},
    { name: "FAQs", path: "/support" },
    { name: "Sitemap", path: "/sitemap" },
  ],
  
  //   additionalResources: [
  //   // { name: "Contact Us", path: "/support" },
  //   { name: "Sitemap", path: "/sitemap" },
  //   // { name: "Corporate Rentals", path: "/corporateRentals" }
  // ],
  officeInfo: [
    "Office",
    "1225 Randall Ave. FL3, Bronx, NY 10474",
  ],
  termsAndConditions: [
    // "Terms & Conditions",
    // "Privacy Policy",
    // "Cookie Settings",
    // "Rental Agreement"
    { name: "Terms & Conditions", path: "/terms-and-conditions" },
    { name: "Privacy Policy", path: "/privacy-policy" },
    { name: "Cookie Settings", path: "/gdpr-privacy-policy" },
    { name: "Rental Agreement", path: "/rental-agreement" },
  ]
};

export const FILTER_OPTIONS_DATA = {
    carsColors:{
      "options": [
        {
          "name": "Pearl White",
          "colorClass": "white"
        },
        {
          "name": "Bright Red",
          "colorClass": "red"
        },
        {
          "name": "Stealth Grey",
          "colorClass": "grey"
        },
        {
          "name": "Solid Black",
          "colorClass": "black"
        },
        {
          "name": "Metalic Blue",
          "colorClass": "blue"
        }
      ]
    },
    milesRange:{
      "options": [
        "270 - 290 miles", "300 - 349 miles", "350 - 399 miles", "400+ miles"
      ]
    }
}
