import React, { useEffect } from "react";
import { setCurrentPage } from "../Store/pageSlice";
import { useDispatch } from "react-redux";

const EvangeList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPage("evangel-list"));
  }, [dispatch]);

  return (
    <div>
      <h2>EvangeList</h2>
    </div>
  );
};

export default EvangeList;
