import { FOOTER_DETAILS } from "../assets/Constants";
import images from "../assets/Images";
import joulezIcon from "../assets/svg/joulezIcon.svg";
import joulezLightIcon from "../assets/svg/JoulezLightIcon.svg";
import facebookIcon from "../assets/svg/facebookIcon.svg";
import facebookLightIcon from "../assets/svg/facebookLightIcon.svg";
import tiktokIcon from "../assets/svg/tiktokIcon.svg";
import tiktokLightIcon from "../assets/svg/tiktokLightIcon.svg";
import youtubeIcon from "../assets/svg/youtubeIcon.svg";
import youtubeLightIcon from "../assets/svg/youtubeLightIcon.svg";
import twitterIcon from "../assets/svg/TwitterNew.svg";
import linkedinIcon from "../assets/svg/linkedinIcon.svg"
import bskyIcon from "../assets/svg/bskyIcon.svg"
import twitterLightIcon from "../assets/svg/twitterLightIcon.svg";
import instaIcon from "../assets/svg/InstaIcon.svg";
import InstaLightIcon from "../assets/svg/InstaLightIcon.svg";
import phoneIcon from "../assets/svg/phoneIcon.svg";
import mailIcon from "../assets/svg/mailIcon.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  //this is to navigate to specific page and we are passing the state name of ([detail?.name])---> FAQs as true to switch faq tab.
  const handleNavigate = (detail) => {
    navigate(detail?.path, { state: { [detail?.name]: true } });
  };

  return (
    <div
      className={`${
        isDarkMode ? "darkGreyMode" : "lightGreyMode"
      } fontAntarctica p-5`}
    >
      <div className="container col-12 text-center text-md-start d-flex flex-md-row  flex-column  justify-content-md-between">
        <div className="pt-3 padding40">
          <img
            src={isDarkMode ? joulezIcon : joulezLightIcon}
            alt="joulezLogo"
          />
          <p className="pt-4 fontSize13">Our Partners</p>
          <img
            className="siriusImg"
            src={isDarkMode ? images?.SiriusImage : images?.SiriusLightImage}
            alt="siriusImage"
          />
        </div>
        <div className="d-flex justify-content-center col-lg-5 pt-3">
          <div className="px-4">
            {FOOTER_DETAILS?.bookingInfo?.map((detail, index) => (
              <p
                onClick={() => handleNavigate(detail)}
                key={index}
                className="mb-2 fontSize13 hoverUnderLine"
              >
                {detail?.name}
              </p>
            ))}
          </div>
          {/* <div className="px-4">
            {FOOTER_DETAILS?.additionalResources?.map((detail, index) => (
              <p
                onClick={() => navigate(`${detail?.path}`)}
                key={index}
                className="mb-2 fontSize13  hoverUnderLine"
              >
                {detail?.name}
              </p>
            ))}
          </div> */}
        </div>
        <div className="pt-3">
          {/* {FOOTER_DETAILS?.officeInfo?.map((detail, index) => (
            <p key={index} className="mb-2 fontSize13 opacity">
              {detail}
            </p>
          ))} */}
          {FOOTER_DETAILS?.officeInfo?.map((detail, index) => (
  <p key={index} className={`mb-2 fontSize13 ${index === 1 ? "opacity" : ""}`}>
    {detail}
  </p>
))}

          <div className="fontSize13">
            <img src={phoneIcon} alt="phoneIcon" className="px-1" />
            {/* +1<span className=" mx-1 ">(917) 831-4031</span> */}
            <a
              href="tel:+14452568539"
              className="text-white text-decoration-none"
            >
              {" "}
              {/* +1 (917) 831-4031 */}
              +1 (445) 2JOULEZ
            </a>
          </div>
          {/* <div>
            <img src={mailIcon} alt="mailIcon" className="px-1" />
            <span className="fontSize13">Info@drivejoulez.com</span>
          </div> */}
          <div>
            <a
              className="text-white text-decoration-none"
              href="mailto:info@drivejoulez.com"
            >
              <img src={mailIcon} alt="mailIcon" className="px-1" />
              <span className="fontSize13 ">info@drivejoulez.com</span>
            </a>
          </div>
        </div>
      </div>
      <div className="bg-gradient">
        <hr />
      </div>
      <div className="d-flex justify-content-between align-items-center ">
        <div className="d-flex flex-sm-row flex-column  justify-content-between w-100">
          <div className="d-flex justify-content-between align-items-center flex-wrap flex-sm-nowrap flex-md-nowrap flex-lg-nowrap cursorPointer">
            {FOOTER_DETAILS?.termsAndConditions?.map((detail, index) => (
              <p
                onClick={() => navigate(`${detail?.path}`)}
                key={index}
                className="px-2 fontSize13 fontColorCE1"
              >
                {detail?.name}
              </p>
            ))}
          </div>
          <div className="d-flex justify-content-between align-items-center cursorPointer">
            <a
              href="https://www.facebook.com/drivejoulez"
              target="_blank"
               rel="noopener noreferrer"
              className="social-link w-inline-block"
            >
              <img
                src={`${isDarkMode ? facebookIcon : facebookLightIcon}`}
                alt="facebookIcon"
                className="px-2 footerLogosHeight"
              />
            </a>
            <div>
              <a
                href="https://www.youtube.com/channel/UC3qvv3Og5Ca-YFg43wSxKAA"
                target="_blank"
                 rel="noopener noreferrer"
                className="social-link w-inline-block"
              >
                <img
                  src={`${isDarkMode ? youtubeIcon : youtubeLightIcon}`}
                  alt="youtubeIcon"
                  className="px-2 footerLogosHeight"
                />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/drivejoulez/"
                target="_blank"
                 rel="noopener noreferrer"
                className="social-link w-inline-block"
              >
                <img
                  src={`${isDarkMode ? instaIcon : InstaLightIcon}`}
                  alt="instaIcon"
                  className="px-2 footerLogosHeight"
                />
              </a>
            </div>
            <div>
              <a
                href="https://twitter.com/drivejoulez"
                target="_blank"
                 rel="noopener noreferrer"
                className="social-link w-inline-block"
              >
                <img
                  src={`${isDarkMode ? twitterIcon : twitterLightIcon}`}
                  alt="twitterIcon"
                  className="px-2 footerLogosHeight"
                />
              </a>
            </div>
            <div>
              <a
                href="https://tiktok.com/@drivejoulez"
                target="_blank"
                 rel="noopener noreferrer"
                className="social-link w-inline-block"
              >
                <img
                  src={`${isDarkMode ? tiktokIcon : tiktokLightIcon}`}
                  alt="tiktokIcon"
                  className="px-2 footerLogosHeight"
                />
              </a>
            </div>
            <div>
              <a
               href="https://www.linkedin.com/company/drivejoulez/"
                target="_blank"
                 rel="noopener noreferrer"
                className="social-link w-inline-block"
              >
                <img
                  src={`${linkedinIcon}`}
                  alt="linkedinIcon"
                  className="px-2 height25"
                />
              </a>
            </div>
            <div>
              <a
               href="https://bsky.app/profile/drivejoulez.bsky.social"
                target="_blank"
                 rel="noopener noreferrer"
                className="social-link w-inline-block"
              >
                <img
                  src={`${bskyIcon}`}
                  alt="tiktokIcon"
                  className="px-2 footerLogosHeight"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 mt-sm-0 mt-md-0 mt-lg-0 text-center text-sm-start text-md-start  text-lg-start ">
        <small className="px-1 fontAntarctica fontSize13">
          &#169; {currentYear} Joulez Inc., All Rights Reserved.
        </small>
      </div>
    </div>
  );
};

export default Footer;
