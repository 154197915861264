// import React, { useEffect } from "react";
// import Button from "./Button";
// import RangeSlider from "./RangeSlider";
// import { useLocation } from "react-router-dom";

// const FilterOptions = ({ btnColor, data, filterData, setFilterData ,handleOnSelect}) => {
// const { brands, types, models, colors, ranges, maxPrice, minPrice, autoPilotList, seats } = data?.data?.carFilters || {};
// const currentUserDetails = JSON.parse(localStorage.getItem("CurrentUserDetails"));
// const location = useLocation();
// const pathLength =  location?.pathname?.split('/')?.length-1;
// const pathLength1 =  location?.pathname?.split('/')?.length-2;
// const pathNameFromSiteMap = location.pathname.split('/')[pathLength]
// const pathNameFromSiteMap1 = location.pathname.split('/')[pathLength1]

// useEffect(() => {
//     if(pathNameFromSiteMap1 === 'make'){
//       //decodeURIComponent it is used to remove %20 from URl and add space
//        handleOnSelect(decodeURIComponent(pathNameFromSiteMap));
//     }
//     // eslint-disable-next-line
//     }, [pathNameFromSiteMap1])

// useEffect(() => {
//   if(filterData?.ageGroup){
//     setFilterData((prev) => ({
//       ...prev,
//       ageGroup: filterData?.ageGroup
//     }))
//   }
//   // eslint-disable-next-line
//   }, [filterData?.ageGroup])

// const handleFilterChange = (filterType, value) => {
//     setFilterData((prev) => {
//       // Ensure prev[filterType] is an array
//       const currentSelection = prev[filterType] || [];
//       const newSelection = currentSelection.includes(value)
//         ? currentSelection.filter((item) => item !== value)
//         : [...currentSelection, value];

//       return {
//         ...prev,
//         carFilters: data?.data?.carFilters,
//         [filterType]: newSelection,
//       };
//     });
//   };

//   return (
//     <div>
//       <div className="p-3 fontAntarctica width100">
//         <p className="fontSize14 mb-1 px-2">Vehicle Type</p>
//         {types?.map((type, index) =>
//           type?.available === false ? (
//             <Button
//               key={index}
//               btnClassName={`filterButtons disbaleFilterButton cursorDefault`}
//             >
//               {type?.name}
//             </Button>
//           ) : (
//             <Button
//               key={index}
//               btnClassName={`filterButtons text-white ${filterData?.vehicleType?.includes(type?.name) ? "bgCE1" : "bg3B3936"}`}
//               onClick={() => handleFilterChange("vehicleType", type?.name)}
//             >
//               {type?.name}
//             </Button>
//           )
//         )}
//         <hr className="bg3B3936" />
//         <p className="fontSize14 mb-1 px-2">Brand</p>
//         {brands?.map((brand, index) =>
//           brand?.available === false ? (
//             <Button
//               key={index}
//               btnClassName={`filterButtons disbaleFilterButton cursorDefault`}
//             >
//               {brand?.name}
//             </Button>
//           ) : (
//             <Button
//               key={index}
//               // btnClassName={`filterButtons ${btnColor} ${
//               //   filterData.brand.includes(brand?.name) ? "bgCE1" : ""
//               // }`}

//               btnClassName={`filterButtons text-white ${
//                 filterData?.brand?.includes(brand?.name) ? "bgCE1" : "bg3B3936"
//               }`}
//               onClick={() => handleFilterChange("brand", brand?.name)}
//             >
//               {brand?.name}
//             </Button>
//           )
//         )}
//         <hr className="bg3B3936" />
//         <p className="fontSize14 mb-1 px-2">Model</p>
//         {models?.map((model, index) =>
//           model?.available === false ? (
//             <Button
//               key={index}
//               btnClassName={`filterButtons text-white disbaleFilterButton cursorDefault`}
//             >
//               {model?.name}
//             </Button>
//           ) : (
//             <Button
//               key={index}
//               // btnClassName={`filterButtons ${btnColor} ${
//               //   filterData.vehicleModel.includes(model?.name) ? "bgCE1" : ""
//               // }`}
//               btnClassName={`filterButtons text-white ${
//                 filterData?.vehicleModel?.includes(model?.name)  ? "bgCE1" : "bg3B3936"
//               }`}
//               onClick={() => handleFilterChange("vehicleModel", model?.name)}
//             >
//               {model?.name}
//             </Button>
//           )
//         )}
//         {/* <hr className="bg3B3936" />
//         <p className="fontSize14 mb-1 px-2">Color</p>
//         <div>
//         {colors?.map((color, index) =>
//           color?.available === false ? (
//             <Button
//               key={index}
//               btnClassName={`filterButtons text-white disbaleFilterButton cursorDefault`}
//             >
//               {color?.color}
//             </Button>
//           ) : (
//             <Button
//               key={index}
//               btnClassName={`filterButtons text-white  ${
//                 filterData?.colors?.includes(color?.color)  ? "bgCE1" : "bg3B3936"
//               }`}
//               onClick={() => handleFilterChange("colors", color?.color)}
//             >
//               {color?.color}
//             </Button>
//           )
//         )}
//         </div> */}
//         <hr className="bg3B3936" />
//         <div>
//           <RangeSlider data={data} filterData={filterData} setFilterData={setFilterData} maxPrice={maxPrice} minPrice={minPrice} />
//         </div>
//         <hr className="bg3B3936" />
//         <p className="fontSize14 mb-1 px-2">Age Group</p>
//         <select
//         name="ageGroup"
//         value={filterData?.ageGroup}
//          onChange={(e) => {
//           setFilterData((prev) => ({
//             ...prev,
//             ageGroup: e.target.value
//           }))
//         }
//         } className="w-100 age-group-select  outlineNone border-0 p-2 fontSize13 cursorPointer borderRadius8 text-white bg3B3936">
//           <option  disabled value="Select Age">Select Age</option>
//           <option value="1">18-20</option>
//           <option disabled={currentUserDetails?.ageGroup === 1} value="2">21-24</option>
//           <option     disabled={currentUserDetails?.ageGroup === 1 || currentUserDetails?.ageGroup === 2}
//           value="3">25+</option>
//         </select>
//         <hr className="bg3B3936" />
//         <p className="fontSize14 mb-1 px-2">Range</p>
//         {ranges?.map((range, index) =>
//           range?.available === false ? (
//             <Button
//               key={index}
//               btnClassName={`filterButtons disbaleFilterButton cursorDefault`}
//             >
//               {range?.label}
//             </Button>
//           ) : (
//             <Button
//               key={index}
//               // btnClassName={`filterButtons ${btnColor} ${
//               //   filterData.vehicleType.includes(type?.selected) ? "bgCE1" : ""
//               // }`}
//               btnClassName={`filterButtons text-white ${filterData?.ranges?.includes(range?.id) ? "bgCE1" : "bg3B3936"}`}
//               onClick={() => handleFilterChange("ranges", range?.id)}
//             >
//               {range?.label}
//             </Button>
//           )
//         )}
//         <hr className="bg3B3936" />
//         <p className="fontSize14 filterButtons">Autopilot</p>
//         {autoPilotList?.map((list, index) =>
//           list?.available === false ? (
//             <Button
//               key={index}
//               btnClassName={`filterButtons disbaleFilterButton cursorDefault`}
//             >
//               {list?.type}
//             </Button>
//           ) : (
//             <Button
//               key={index}
//               btnClassName={`filterButtons text-white ${filterData?.autoPilotList?.includes(list?.id) ? "bgCE1" : "bg3B3936"}`}
//               onClick={() => handleFilterChange("autoPilotList", list?.id)}
//             >
//               {list?.type}
//             </Button>
//           )
//         )}
//          <hr className="bg3B3936" />
//          <div className="mobilePadding">
//          <p className="fontSize14 mb-1 px-2">Seats</p>
//         {seats?.map((seat, index) =>
//           seat?.available === false ? (
//             <Button
//               key={index}
//               btnClassName={`filterButtons disbaleFilterButton cursorDefault`}
//             >
//               {seat?.name}
//             </Button>
//           ) : (
//             <Button
//               key={index}
//               btnClassName={`filterButtons text-white ${filterData?.seats?.includes(seat?.name) ? "bgCE1" : "bg3B3936"}`}
//               onClick={() => handleFilterChange("seats", seat?.name)}
//             >
//               {seat?.name}
//             </Button>
//           )
//         )}

//          </div>

//       </div>
//     </div>
//   );
// };

// export default FilterOptions;

import React, { useEffect } from "react";
import Button from "./Button";
import RangeSlider from "./RangeSlider";
import { useLocation } from "react-router-dom";

const FilterOptions = ({
  btnColor,
  data,
  filterData,
  setFilterData,
  handleOnSelect,
}) => {
  const {
    brands,
    types,
    models,
    colors,
    ranges,
    maxPrice,
    minPrice,
    autoPilotList,
    seats,
  } = data?.data?.carFilters || {};
  const currentUserDetails = JSON.parse(
    localStorage.getItem("CurrentUserDetails")
  );
  const location = useLocation();
  const pathLength = location?.pathname?.split("/")?.length - 1;
  const pathLength1 = location?.pathname?.split("/")?.length - 2;
  const pathNameFromSiteMap = location.pathname.split("/")[pathLength];
  const pathNameFromSiteMap1 = location.pathname.split("/")[pathLength1];

  useEffect(() => {
    if (pathNameFromSiteMap1 === "make") {
      //decodeURIComponent it is used to remove %20 from URl and add space
      handleOnSelect(decodeURIComponent(pathNameFromSiteMap));
    }
    // eslint-disable-next-line
  }, [pathNameFromSiteMap1]);

  useEffect(() => {
    if (filterData?.ageGroup) {
      setFilterData((prev) => ({
        ...prev,
        ageGroup: filterData?.ageGroup,
      }));
    }
    // eslint-disable-next-line
  }, [filterData?.ageGroup]);

  const handleFilterChange = (filterType, value) => {
    setFilterData((prev) => {
      // Ensure prev[filterType] is an array
      const currentSelection = prev[filterType] || [];
      const newSelection = currentSelection.includes(value)
        ? currentSelection.filter((item) => item !== value)
        : [...currentSelection, value];

      return {
        ...prev,
        carFilters: data?.data?.carFilters,
        [filterType]: newSelection,
      };
    });
  };

  return (
    <div>
      <div className="p-3 fontAntarctica width100">
        <p className="fontSize14 mb-1 px-2">Vehicle Type</p>
        {types?.map((type, index) => (
          <Button
            key={index}
            btnClassName={`filterButtons text-white ${
              filterData?.vehicleType?.includes(type?.name)
                ? "bgCE1"
                : "bg3B3936"
            }`}
            onClick={() => handleFilterChange("vehicleType", type?.name)}
          >
            {type?.name}
          </Button>
        ))}
        <hr className="bg3B3936" />
        <p className="fontSize14 mb-1 px-2">Brand</p>
        {brands?.map((brand, index) => (
          <Button
            key={index}
            btnClassName={`filterButtons text-white ${
              filterData?.brand?.includes(brand?.name) ? "bgCE1" : "bg3B3936"
            }`}
            onClick={() => handleFilterChange("brand", brand?.name)}
          >
            {brand?.name}
          </Button>
        ))}
        <hr className="bg3B3936" />
        <p className="fontSize14 mb-1 px-2">Model</p>
        {models?.map((model, index) => (
          <Button
            key={index}
            btnClassName={`filterButtons text-white ${
              filterData?.vehicleModel?.includes(model?.name)
                ? "bgCE1"
                : "bg3B3936"
            }`}
            onClick={() => handleFilterChange("vehicleModel", model?.name)}
          >
            {model?.name}
          </Button>
        ))}
        {/* <hr className="bg3B3936" />
        <p className="fontSize14 mb-1 px-2">Color</p>
        <div>
        {colors?.map((color, index) =>
          color?.available === false ? (
            <Button
              key={index}
              btnClassName={`filterButtons text-white disbaleFilterButton cursorDefault`}
            >
              {color?.color}
            </Button>
          ) : (
            <Button
              key={index}
              btnClassName={`filterButtons text-white  ${
                filterData?.colors?.includes(color?.color)  ? "bgCE1" : "bg3B3936"
              }`}
              onClick={() => handleFilterChange("colors", color?.color)}
            >
              {color?.color}
            </Button>
          )
        )}
        </div> */}
        <hr className="bg3B3936" />
        <div>
          <RangeSlider
            data={data}
            filterData={filterData}
            setFilterData={setFilterData}
            maxPrice={maxPrice}
            minPrice={minPrice}
          />
        </div>
        <hr className="bg3B3936" />
        <p className="fontSize14 mb-1 px-2">Age Group</p>
        <select
          name="ageGroup"
          value={filterData?.ageGroup}
          onChange={(e) => {
            setFilterData((prev) => ({
              ...prev,
              ageGroup: e.target.value,
            }));
          }}
          className="w-100 age-group-select  outlineNone border-0 p-2 fontSize13 cursorPointer borderRadius8 text-white bg3B3936"
        >
          <option disabled value="Select Age">
            Select Age
          </option>
          <option value="1">18-20</option>
          <option disabled={currentUserDetails?.ageGroup === 1} value="2">
            21-24
          </option>
          <option
            disabled={
              currentUserDetails?.ageGroup === 1 ||
              currentUserDetails?.ageGroup === 2
            }
            value="3"
          >
            25+
          </option>
        </select>
        <hr className="bg3B3936" />
        <p className="fontSize14 mb-1 px-2">Range</p>
        {ranges?.map((range, index) => (
          <Button
            key={index}
            btnClassName={`filterButtons text-white ${
              filterData?.ranges?.includes(range?.id) ? "bgCE1" : "bg3B3936"
            }`}
            onClick={() => handleFilterChange("ranges", range?.id)}
          >
            {range?.label}
          </Button>
        ))}
        <hr className="bg3B3936" />
        <p className="fontSize14 filterButtons">Autopilot</p>
        {autoPilotList?.map((list, index) => (
          <Button
            key={index}
            btnClassName={`filterButtons text-white ${
              filterData?.autoPilotList?.includes(list?.id)
                ? "bgCE1"
                : "bg3B3936"
            }`}
            onClick={() => handleFilterChange("autoPilotList", list?.id)}
          >
            {list?.type}
          </Button>
        ))}
        <hr className="bg3B3936" />
        <div className="mobilePadding">
          <p className="fontSize14 mb-1 px-2">Seats</p>
          {seats?.map((seat, index) => (
            <Button
              key={index}
              btnClassName={`filterButtons text-white ${
                filterData?.seats?.includes(seat?.name) ? "bgCE1" : "bg3B3936"
              }`}
              onClick={() => handleFilterChange("seats", seat?.name)}
            >
              {seat?.name}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterOptions;
