import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../Store/pageSlice';

const RentalAgreement = () => {
    const dispatch = useDispatch()
    const currentYear = new Date().getFullYear();
    const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      useEffect(() => {
        dispatch(setCurrentPage("rental-agreement"));
      }, [dispatch]);

  return (
    <div className={`${
        isDarkMode ? "darkGreyMode" : "lightGreyMode"
      } pt-5`}>
        <div className="container footer_adjust mt-5 terms">
    <div className="row">
        <div className="col-md-12 mt-3 terms-section">
            <h2 className="text-center mb-3 title">JOULEZ INC., ELECTRIC VEHICLE RENTAL AGREEMENT</h2>
            <br/>
            <p>This Electric Vehicle Rental Agreement (this <strong>“Agreement”</strong>) is entered into effective as
                of the
                date
                of the booking hereto (the <strong> “Effective Date”</strong>) by and between Joulez Inc.,
                (“Joulez”) and the
                undersigned renter (<strong>“Renter”</strong>). Joulez and Renter may each be individually referred to
                herein as a
                <strong>“Party”</strong> and collectively as the <strong> “Parties.”</strong>
            </p>
            <p><strong>WHEREAS</strong>, Joulez is the owner and/or manager of the Electric Vehicle (the “EV”);
                described in
                Section 1 of this Agreement; and</p>
            <p><strong>WHEREAS</strong>, Renter desires to rent the EV from Joulez subject to the contractual provisions
                set
                forth below and the Terms & Conditions attached to this Agreement; and</p>
            <p><strong>NOW, THEREFORE,</strong> in consideration of the mutual promises contained here, the Parties
                hereby
                agree as follows:
            </p>
            <ol className='fontColorFCC'>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>EV:</strong></u>
                        &nbsp;The EV that is the subject of this Agreement is the following specified
                        EV,
                        along with any
                        accessories and equipment related to the EV such as keys and plates:
                    </p>
                    <p>EV Mileage, Condition & Damage to be documented by photos/video at pickup and return of EV.</p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Rental:</strong></u>
                        &nbsp;Joulez hereby rents the EV to Renter subject to the following
                        contractual provisions.
                        Renter shall not have any ownership interest in the EV as a result of this Agreement. Renter
                        is
                        not
                        Joulez’s agent and has no authority to bind Joulez or transfer Renter’s rights and
                        obligations
                        under
                        this Agreement.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Term/Payment:</strong></u>
                        &nbsp;Please click on My Trips to see the term
                        details, receipt and payment information.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Excess Mileage:</strong></u>
                        &nbsp;Mileage on the EV by default is limited to 200 miles/day unless Unlimited
                        Mileage is selected. Any mileage on the EV in excess of this limitation shall be subject to an
                        excess
                        mileage surcharge.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Tolls and Supercharging:</strong></u>
                        &nbsp;Tolls and Supercharging will be AUTOMATICALLY billed back
                        directly to the credit card on file and a summary of the charges will be sent over via email.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Scope of Use:</strong></u>
                        &nbsp;Renter shall use the EV only for personal or routine business use excluding rideshare
                        operations, and operate the EV only on properly maintained roads and parking lots. Renter shall
                        comply with all applicable laws related to holding and maintaining licensure and insurance and
                        laws pertaining to operation of the EV during the term of this Agreement. Rental shall exercise
                        ordinary care to avoid damage to the EV and shall not use the EV in a way likely to result in
                        damage.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Authorized Drivers:</strong></u>
                        &nbsp;The persons who are authorized as a Renter to operate the EV during the term
                        of this Agreement are listed with their Driver’s License at the end of this Agreement. Renter
                        shall
                        not sublease the EV or allow any other person not identified in this Agreement as an authorized
                        operator to operate the EV.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Insurance:</strong></u>
                        &nbsp;Renter must provide Joulez with proof of insurance sufficient to cover damage to the
                        EV as of the Effective Date, as well as personal injury to the Renter, passengers in the EV, and
                        other persons or property if Protection Coverage was declined. If the EV is damaged
                        or destroyed while it is in possession of Renter, Renter agrees to pay any required insurance
                        deductible and assign all rights to collect insurance proceeds to Joulez. Tesla vehicles are set
                        to a
                        limit of 85 MPH and Chill Mode. Joulez requires a copy of Driver’s Additional Insurance Coverage
                        to remove this limitation. Please attach a copy of your insurance coverage via email.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Alterations:</strong></u>
                        &nbsp;Apart from ordinary repairs and maintenance by qualified persons, Renter shall make
                        no alterations whatsoever to the EV without the prior written consent of Joulez. Unless
                        otherwise
                        agreed by Joulez, any such approved alterations shall be the property of Joulez and become part
                        of
                        the EV subject to this Agreement.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Property in the EV:</strong></u>
                        &nbsp;Joulez is not responsible for loss, theft, or damage to any of Renter’s property
                        (or that of any other passenger, driver, or occupant of the EV during the term of this
                        Agreement)
                        in or on the EV or received by Joulez under any circumstances.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Loss and Damage:</strong></u>
                        &nbsp;Renter shall be directly responsible to Joulez for any loss or damage to the EV
                        from any cause whatsoever (apart from any loss or damage attributable to the gross negligence or
                        willful misconduct of Joulez or its representatives) occurring after delivery of the EV to
                        Renter and
                        before possession of the EV is returned to Joulez. Under New York law, Renter has the right the
                        inspect the EV to assess any claimed damages.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Smoking:</strong></u>
                        &nbsp;Smoking of all forms (cigars, cigarettes, marijuana) is prohibited in all Joulez vehicles.
                        A $250 cleaning fee will be charged to the credit card on booking for smoking in one of
                        our
                        vehicles.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Pets:</strong></u>
                        &nbsp;Only registered service dogs are allowed in our vehicles without a pet fee. A non-service
                        animal/pet can be transported in our vehicle for a pet fee . They must be crated and
                        there
                        must be a mat or towel laid down between the seat and the crate. A $150 cleaning fee will be
                        charged to the credit card on booking for any excessive cleaning fees required. Any interior
                        damage
                        caused by a pet to the EV will result in a claim being filed for the cost of repairs to
                        the
                        interior.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Child Seats:</strong></u>
                        &nbsp;Child safety seats are provided upon request for an additional per-day fee.
                        Any excessive cleaning fees or damage to a child safety seat will be billed back to the customer
                        for
                        up to the cost of the child safety seat of $250. Joulez will not be held liable for any
                        manufacturer
                        defects or improper usage of the child safety seats in the vehicles.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Parking Passes & Key Cards:</strong></u>
                        &nbsp;Joulez vehicles are parked in garages that require a physical parking
                        pass to enter and exit the garage. You are responsible for returning the parking pass to Joulez
                        upon
                        completion of your trip. If the parking pass is not returned with the vehicle, there is a $75
                        lost
                        parking pass fee. Teslas have a key card that comes with the vehicle. Any Tesla key card that is
                        lost will incur a $150 lost key card fee to cover the cost of deactivating the old key card,
                        ordering
                        a new key card and programming the key card to the Tesla.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Indemnity:</strong></u>
                        &nbsp;Regardless of insurance coverage, Renter shall fully indemnify the Joulez for any loss,
                        damage, and legal actions, including reasonable attorney’s fees that Joulez suffers due to
                        Renter’s
                        use of EV during the term of this Agreement, including but not limited to, damage to the EV,
                        damage to the property of others, injury to Renter, and injury to others. Renter shall also pay
                        for
                        any parking tickets, moving violations, or other citations received during the term if this
                        Agreement. This provision survives the termination of this Agreement.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Warranties:</strong></u>
                        &nbsp;Joulez represents that to the best of his knowledge and belief that the EV is in good
                        condition and safe for ordinary operation of the EV.
                        Renter represents that Renter shall not (a) operate the EV in violation of any laws or for an
                        illegal
                        purpose and that if Renter does, Renter is responsible for all associated, tickets, fines, and
                        fees; (b)
                        use the EV to push or tow another EV; (c) use the EV for any race or competition; or (d) operate
                        the EV in a negligent manner. Renter represents that Renter is legally entitled to operate a
                        motor
                        EV under the laws of this jurisdiction.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Termination:</strong></u>
                        &nbsp;Joulez reserves the right to terminate this Agreement upon any failure of Renter to
                        comply with the provisions of this Agreement, including nonpayment of fees, misuse of the EV, or
                        violation of the law. All of Renter’s obligations under this Agreement, including Renter’s
                        obligations to return the EV and pay applicable fees, shall survive termination under this
                        Section.
                        Upon termination, Joulez may repossess of the EV, without demand or notice, wherever it may be
                        located, without any court order or other process of law.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Limitation of Liability:</strong></u>
                        &nbsp;TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE
                        LAW, JOULEZ SHALL NOT BE LIABLE TO RENTER UNDER ANY LEGAL THEORY,
                        WHETHER IN AN ACTION BASED ON A CONTRACT, TORT (INCLUDING
                        NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE PROVIDED BY STATUTE OR LAW,
                        (1) FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL, OR
                        STATUTORY DAMAGES, OR ANY DAMAGES RESULTING FROM LOST PROFITS,
                        INTERRUPTION OF BUSINESS, OR LOSS OF GOODWILL, EVEN IF JOULEZ HAD BEEN
                        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR (2) FOR DAMAGES RELATED
                        TO OR ARISING OUT OF THIS AGREEMENT IN AN AMOUNT THAT EXCEEDS THE
                        RENT ACTUALLY PAID TO JOULEZ UNDER THIS AGREEMENT.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Arbitration:</strong></u>
                        &nbsp;Before asserting a claim in any proceeding whatsoever, the Parties agree that each
                        shall give the other Party written notice 30 days in advance and make a reasonable, good faith
                        effort
                        to resolve the claim. In the event that the Parties cannot amicably resolve the claim, the
                        Parties
                        agree to submit the claim to arbitration. The arbitration proceeding shall be conducted in New
                        York
                        City, New York, in accordance with the rules of the American Arbitration Association then in
                        effect
                        with one arbitrator to be selected by mutual agreement of the Parties. If the Parties cannot
                        agree on
                        an arbitrator, then the American Arbitration Association shall select an arbitrator from the
                        National
                        Panel of Arbitrators. The laws of the State of New York shall apply to the arbitration
                        proceedings.
                        The Parties agree that the arbitrator cannot award punitive damages to either Party and agree to
                        be
                        bound by the arbitrator’s findings. Judgment upon the award rendered by the arbitrator may be
                        entered in any court having jurisdiction.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Governing Law:</strong></u>
                        &nbsp;If any dispute arising from this Agreement is filed in any court, the laws of the
                        State of New York shall govern this Agreement without regard to any conflict of law principles.
                        No action, arising out of the transactions under this Agreement may be brought by either Party
                        more than one year after the cause of action has accrued.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Notice:</strong></u>
                        &nbsp;Any notice or other communication required or permitted hereunder shall be given in
                        writing to the other Party at the address stated below, or at such other address as shall be
                        given by
                        either Party to the other in writing.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Severability & Waiver:</strong></u>
                        &nbsp;In the event that any portion of this Agreement is held to be unenforceable,
                        the unenforceable portion shall be amended to reflect, to the greatest extent permitted under
                        applicable law, the original intent of the Parties, and the remainder of the provisions shall
                        remain
                        in full force and effect.
                    </p>
                    <p>
                        The waiver by either Party of a breach of any provision of the Agreement shall not operate or be
                        construed as a waiver of any subsequent breach and any waiver must be in writing and signed by
                        an authorized Affiliate of the Parties hereto
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Interpretation:</strong></u>
                        &nbsp;Headings are inserted for the convenience of the Parties only and are not to be
                        considered when interpreting this Agreement. Words in the singular mean and include the plural
                        and vice versa. Words in the masculine means and include the feminine and vice versa.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Survival:</strong></u>
                        &nbsp;All contractual provisions of this Agreement that should, by their nature, survive the
                        termination of this Agreement, shall so survive.
                    </p>
                </li>
                <li>
                    <p>
                        <u><strong className='fontcolorFFF'>Entire Agreement:</strong></u>
                        &nbsp;This Agreement constitutes the entire agreement between the Parties and
                        supersedes any prior understanding or representation of any kind preceding the date of this
                        Agreement. There are no other promises, conditions, understandings or other agreements, whether
                        oral or written, relating to the subject matter of this Agreement. Unless otherwise provided
                        herein,
                        this Agreement may only be modified in writing and must be signed by both Joulez and Renter.
                    </p>
                </li>
            </ol>
            <p>
                <strong className='fontcolorFFF'> IN WITNESS WHEREOF</strong>, the Parties have signed this Agreement intending to be legally
                bound by the provisions set forth herein and the Terms & Conditions in Exhibit A as of the
                Effective Date.
            </p>
        </div>


        <div className="col-md-12 terms-section">
            <p>&copy; { currentYear } Joulez Inc., All rights reserved.</p>
        </div>

    </div>
</div>
      </div>
  )
}

export default RentalAgreement