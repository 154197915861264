import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const userToken =
    useSelector((store) => store?.userDetailsSlice?.userDetails?.token) ||
    localStorage.getItem("AccessToken");
  if (userToken === "" || userToken === undefined || userToken === null) {
    return <Navigate to="/" />; // Redirect to home or login page
  }

  return children;
};

export default ProtectedRoute;
