import { createSlice } from "@reduxjs/toolkit";

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState: {
    userDetails: {
      token: localStorage.getItem("AccessToken") === null ? "" : localStorage.getItem("AccessToken"),
    },
    // profileDetails: {
    //   currentUserDetails: JSON.parse(localStorage.getItem("CurrentUserDetails")) === null ? " " : JSON.parse(localStorage.getItem("CurrentUserDetails"))
    // },


  },
  reducers: {
    setCurrentUserDetails: (state, action) => {
      state.userDetails.token = action.payload;
    },
  },
});

export const { setCurrentUserDetails } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
