// import React, { useEffect, useRef, useState } from "react";
// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
// import { format, isSameDay } from "date-fns";
// import { useSelector } from "react-redux";

// const CommonCalendar = ({ onDateSelect }) => {
//   const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
//   const [selectedDate, setSelectedDate] = useState(null);

//   const [isCalendarOpen, setIsCalendarOpen] = useState(true); // Track if calendar is open
//   const calendarRef = useRef(null); // Reference to the calendar element

//   // Close the calendar when clicking outside of it
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (calendarRef.current && !calendarRef.current.contains(event.target)) {
//         setIsCalendarOpen(false); // Close the calendar when clicking outside
//       }
//     };

//     // Add event listener for clicks outside
//     document.addEventListener("mousedown", handleClickOutside);

//     // Clean up the event listener on component unmount
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const handleDateClick = (date) => {
//     // Set the selected date (DOB)
//     setSelectedDate(date);
//     const formattedDate = format(date, "MM/dd/yyyy");
//     onDateSelect(formattedDate); // Pass selected date to parent
//   };

//   const tileClassName = ({ date, view }) => {
//     if (view === "month") {
//       // Disable future dates, as DOB should be in the past
//       const currentDate = new Date();
//       if (date > currentDate) {
//         return "future-date";
//       }

//       // Apply a custom class to the selected date
//       if (selectedDate && isSameDay(date, selectedDate)) {
//         return "selected-date"; // This will be applied to the selected date
//       }
//     }
//   };

//   // If the calendar is not open, don't render it
//   if (!isCalendarOpen) return null;

//   return (
//     <div ref={calendarRef}>
//       <Calendar
//         onClickDay={handleDateClick}
//         value={selectedDate}
//         minDate={new Date(1900, 0, 1)} // Setting a minimum date to avoid unrealistic DOBs
//         maxDate={new Date()} // Prevent selecting future dates
//         className={`react-calendar ${
//           isDarkMode ? "darkMode" : "lightGreyMode"
//         }`}
//         tileClassName={tileClassName}
//         showDoubleView={false}
//       />
//     </div>
//   );
// };

// export default CommonCalendar;

// import React, { useEffect, useRef, useState } from "react";
// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
// import { format, isSameDay } from "date-fns";
// import { useSelector } from "react-redux";
// import "bootstrap/dist/css/bootstrap.min.css";

// const CommonCalendar = ({ onDateSelect, placeholder, handleBlur }) => {
//   const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [isCalendarOpen, setIsCalendarOpen] = useState(false);
//   const inputRef = useRef(null); // Reference to the input field
//   const calendarRef = useRef(null); // Reference to the calendar popup

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         calendarRef.current &&
//         !calendarRef.current.contains(event.target) &&
//         inputRef.current &&
//         !inputRef.current.contains(event.target)
//       ) {
//         setIsCalendarOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const handleDateClick = (date) => {
//     setSelectedDate(date);
//     const formattedDate = format(date, "MM/dd/yyyy");
//     onDateSelect(formattedDate); // Pass selected date to parent
//     setIsCalendarOpen(false); // Close calendar after date selection
//   };

//   const tileClassName = ({ date, view }) => {
//     if (view === "month") {
//       const currentDate = new Date();
//       if (date > currentDate) {
//         return "future-date";
//       }
//       if (selectedDate && isSameDay(date, selectedDate)) {
//         return "selected-date";
//       }
//     }
//   };

//   return (
//     <div style={{ position: "relative", display: "inline-block", width:"100%" }}>
//       {/* Input field to trigger the calendar */}
//       <input
//         ref={inputRef}
//         type="text"
//         className="form-control"
//         value={selectedDate ? format(selectedDate, "MM/dd/yyyy") : ""}
//         placeholder={placeholder}
//         readOnly
//         onClick={() => setIsCalendarOpen(true)}
//       />

//       {/* Calendar Popup */}
//       {isCalendarOpen && (
//         <div
//           ref={calendarRef}
//           style={{
//             position: "absolute",
//             top: "100%", // Position the popup below the input
//             left: 0,
//             zIndex: 1050, // Higher z-index to appear on top
//             boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             borderRadius: "8px",
//             border: "1px solid #725CE1",
//             marginTop:"16px",
//             marginLeft:"10px"
//           }}
//         >
//           <Calendar
//             onClickDay={handleDateClick}
//             value={selectedDate}
//             minDate={new Date(1900, 0, 1)}
//             maxDate={new Date()}
//             className={`react-calendar borderRadius8 ${
//               isDarkMode ? "darkMode" : "lightGreyMode"
//             }`}
//             tileClassName={tileClassName}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default CommonCalendar;

import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format, isSameDay, setMonth, setYear } from "date-fns";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";

const CommonCalendar = ({
  onDateSelect,
  placeholder,
  handleBlur,
  name,
  value,
  maxDate,
  minDate = new Date(1900, 0, 1),
  defaultViewDate = setMonth(setYear(new Date(), 2006), new Date().getMonth()),
}) => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const [selectedDate, setSelectedDate] = useState(value);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const inputRef = useRef(null);
  const calendarRef = useRef(null);
  const [defaultDate, setDefaultDate] = useState("")

  useEffect(() => {
    // Ensure value is always a Date object
    const dateValue = value ? (typeof value === 'string' ? new Date(value) : value) : null;
    setDefaultDate(dateValue);
  }, [value]);;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDateClick = (date) => {
    setSelectedDate(date);
    const formattedDate = format(date, "MM/dd/yyyy");
    onDateSelect(formattedDate);
    setIsCalendarOpen(false);
    handleBlur({
      target: {
        name,
        value: formattedDate,
      },
    });
  };

  //const defaultViewDate = setMonth(setYear(new Date(), 2006), new Date().getMonth());

  // Get today's date
  // Set the default view to November of 2006 with today's month
  //const defaultViewDate = setMonth(setYear(new Date(), 2006), today.getMonth());

  // Calculate the max date for 2006 (limit it to today's date in 2006)
  //const maxDateIn2006 = new Date(2006, today.getMonth(), Math.min(today.getDate(), 31));

  return (
    <div
      style={{ position: "relative", display: "inline-block", width: "100%" }}
    >
      <input
        ref={inputRef}
        type="text"
        className="form-control bg-transparent outlineNone border3B3936 supportFormFocus text-white"
        name={name} // Add name for handleBlur
        value={selectedDate ? format(selectedDate, "MM/dd/yyyy") : ""}
        placeholder={placeholder}
        readOnly
        onClick={() => setIsCalendarOpen(true)}
        onBlur={handleBlur} // Trigger handleBlur on blur
      />

      {isCalendarOpen && (
        <div
          ref={calendarRef}
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            zIndex: 1050,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            border: "1px solid #725CE1",
            marginTop: "16px",
            marginLeft: "5px",
          }}
        >
          <Calendar
            onClickDay={handleDateClick}
            value={selectedDate}
            defaultActiveStartDate={defaultDate  || defaultViewDate} // Set default view
            minDate={minDate}
            maxDate={maxDate}
            className={`react-calendar borderRadius8 position-fixed top30 ${
              isDarkMode ? "darkMode" : "lightGreyMode"
            }`}
            tileClassName={({ date, view }) =>
              view === "month" && selectedDate && isSameDay(date, selectedDate)
                ? "selected-date"
                : ""
            }
          />
        </div>
      )}
    </div>
  );
};

export default CommonCalendar;
