import { createSlice } from '@reduxjs/toolkit';

const buttonSlice = createSlice({
  name: 'buttons',
  initialState: {
    disableSearchButton: false,
    bookingScreenLoginButton: false,
    bookingScreenSignUpButton: false,
    bookingScreenNextButton: false
  },
  reducers: {
    setDisableSearchButton: (state, action) => {
      state.disableSearchButton = action.payload;
    },
    setOpenLoginDesktopModal: (state, action) => {
      state.bookingScreenLoginButton = action.payload
    },
    setOpenSignUpDesktopModal: (state, action) => {
      state.bookingScreenSignUpButton = action.payload
    },
    setOpenProceedBookingDesktopModal: (state, action) => {
      state.bookingScreenNextButton = action.payload
    }
  },
});

export const { setDisableSearchButton, setOpenLoginDesktopModal, setOpenSignUpDesktopModal, setOpenProceedBookingDesktopModal } = buttonSlice.actions;
export default buttonSlice.reducer;
