import axios from "axios";
import React, { useEffect, useState } from "react";
import {useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../api";
import { useDispatch } from "react-redux";
import {
  setOpenSignUpDesktopModal,
} from "../Store/buttonSlice";
import { setCurrentPage } from "../Store/pageSlice";

const VerifyEmail = () => {
  const token = localStorage.getItem("AccessToken");
  const [firstDiv, setFirstDiv] = useState(false);
  const [secondDiv, setSecondDiv] = useState(false);
  const [infoMessage, setinfoMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  
  useEffect(() => {
    getUserVerified();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage("verify-email"));
  }, [dispatch]);

  //to get User details
  const getUserVerified = async () => {
    try {
      const userResponse = await axios.get(
        `${BASE_URL}user/verifyEmail/open${location?.search}`
      );
      setinfoMessage(userResponse?.data?.message);
      setFirstDiv(true);
    } catch (error) {
      setSecondDiv(true);
      setinfoMessage(error.error.message);
    }
  };

  return (
    <div className="d-flex justify-content-center  bg23 pt-5 min-vh-100">
            <div className="d-flex justify-content-center align-items-center">
              {firstDiv && (
                <div className="my-5">
                  <h5 className="text-center text-white">{infoMessage}</h5>
                  <div className="mt-4 text-center">
                   {(token === null || token === ""  || token=== undefined || !token) &&  <button 
                      onClick={() => {
                        dispatch(setOpenSignUpDesktopModal(true));
                      }}
                      className="bgCE1 text-white p-2 outlineNone border-0 borderRadius8 mx-2"
                    >
                      Continue to login
                    </button>
                    }
                    </div>
                  <div className="d-flex justify-content-center align-align-items-center cursorPointer"> 
                  <div
                    onClick={() => {
                      navigate("/");
                    }}
                    className="mt-3 fontAntarctica text-white thankyou-button"
                  >
                    Home
                  </div>
                  </div>
                </div>
              )}

              {secondDiv && (
                <div className="my-5">
                  <h5 className="text-msg1 card-title text-center text-white">
                    {infoMessage}
                  </h5>
                  <div className="d-flex justify-content-center align-align-items-center cursorPointer">
                  <div
                    onClick={() => {
                      navigate("/");
                    }}
                    className=" mt-3 fontAntarctica"
                  >
                    Home
                  </div>
                  </div>
                </div>
              )}
            </div>
    </div>
  );
};

export default VerifyEmail;
