import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import appStore from "./Store/appStore";
import appRouter from "./appRouter";
function App() {
  return (
    <div>
      <Provider store={appStore}>
        <RouterProvider router={appRouter} />
      </Provider>
    </div>
  );
}

export default App;
