const SpinnerLoader = () => {
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        <div>
          {/* <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div> */}
          <div className="spinner-border text-light" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpinnerLoader;
