import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../Store/pageSlice";

const NotFound = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentPage("*"));
  }, [dispatch]);

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100 text-white bg23">
      <h1>404 - Page Not Found</h1>
    </div>
  );
};

export default NotFound;
