import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import { useEffect, useRef } from "react";

const Overlay = ({ dataBsTitle = "", children, direction }) => {
  const tooltipRef = useRef(null);
  useEffect(() => {
    // Select all elements that have the attribute data-bs-toggle="tooltip"
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );

    // Initialize Bootstrap tooltips on the selected elements
    const tooltipList = tooltipTriggerList?.map((tooltipTriggerEl) => {
      return new bootstrapBundleMin.Tooltip(tooltipTriggerEl, {
        trigger: "click focus",
      });
    });

    // Function to handle clicks outside of the tooltip element
    const handleClickOutside = (event) => {
      // Check if the clicked element is outside the tooltip element
      if (tooltipRef?.current && !tooltipRef?.current?.contains(event?.target)) {
        // Hide all tooltips if the click is outside the tooltip element
        tooltipList.forEach((tooltip) => tooltip.hide());
      }
    };

    // Add a click event listener to the document to detect clicks outside the tooltip
    document.addEventListener("click", handleClickOutside);

    // Cleanup function to remove the event listener and dispose of the tooltips
    return () => {
      document.removeEventListener("click", handleClickOutside);
      // Dispose of all tooltips to free up resources
      tooltipList.forEach((tooltip) => tooltip.dispose());
    };
  }, []);

  return (
    <div ref={tooltipRef}>
      <div
        data-bs-toggle="tooltip"
        data-bs-placement={direction}
        data-bs-title={dataBsTitle || ""}
        data-bs-custom-class="custom-tooltip"
        className="cursorPointer"
      >
        {children}
      </div>
    </div>
  );
};

export default Overlay;
