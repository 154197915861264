import React, { Fragment, useEffect } from "react";
import { setCurrentPage } from "../Store/pageSlice";
import { useDispatch, useSelector } from "react-redux";
import images from "../../src/assets/Images";
import chargeIcon from "../assets/svg/chargeIcon.svg";
import customerCentricityIcon from "../assets/svg/customerCentricityIcon.svg";
import sustainabilityIcon from "../assets/svg/sustainbilityIcon.svg";
import ReactPlayer from "react-player";
import CarouselContainer from "../Components/CarouselContainer";
import { content1, content2 } from "../data/content";

const AboutUs = () => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const { title, description } = content2;
  const { locations } = content1;

  useEffect(() => {
    dispatch(setCurrentPage("about"));
  }, [dispatch]);

  return (
    <div>
      <div className="position-relative w-100 bg191916">
        <img
          className="w-100 object-fit-cover aboutUsBGPage_image"
          src={images?.AboutUsImage}
          alt="AboutUsPageBGImage"
        />
        <div className=" position-absolute top-50 start-50 translate-middle-x text-center fontcolorFFF">
          <div className="fontAtacama aboutPageHeading">About Us</div>
          {/* <div className="fontAntarctica fontsize22">
            New York | Los Angeles | Chicago
          </div> */}
          <div className="text-center d-flex justify-content-center fontAntarctica">
            {locations.map((location, index) => {
              return (
                <Fragment key={index}>
                  <p className="px-2">{location}</p>
                  {index < locations.length - 1 && <span>|</span>}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <div className="darkMode p-3">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h2 className="py-2 fontAtacama aboutPageHeading py-5">Our Story</h2>
          <div className="video-container">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=K8wvUm4rLco"
              width="100%"
              height="100%"
            />
          </div>
          <div className="col-12 col-lg-5 py-3 text-center fontAntarctica fontSize17">
            Joulez began with a singular vision: transforming the car rental
            experience. Joulez sits at the crossroads of sustainability, luxury,
            and convenience, offering a one-of-a-kind rental experience. By
            truly empathizing with our guests and creating a customer-centric
            process, we’ve been able to complete 5,000+ trips with 5 stars
            across the board.
          </div>
        </div>
      </div>
      <div className="darkGreyMode p-5">
        <div className="d-flex flex-column flex-lg-row justify-content-center w-100">
          <div className="d-flex flex-column justify-content-between align-items-center mx-5 my-2">
            <div>7K+</div>
            <div className="text-center">Cities across US</div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center mx-5 my-2">
            <div>3K+</div>
            <div className="text-center">Vehicles</div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center mx-5 my-2">
            <div>7K+</div>
            <div className="text-center">Vehicles on rent</div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center mx-5 my-2">
            <div>3M+</div>
            <div className="text-center">Customers</div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center mx-5 my-2">
            <div>$37k+</div>
            <div className="text-center">
              average annual earnings per US Host
            </div>
          </div>
        </div>
      </div>
      <div className="darkMode p-5">
        <div className="d-flex flex-column justify-content-center align-items-center p-3">
          <h2 className="py-3 fontAtacama aboutPageHeading">Our Values</h2>
          <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center w-100">
            <div className="AboutPageBox bg3B3936 fontAntarctica m-3">
              <img src={chargeIcon} alt="chargeIcon" />
              <div className="fontSize17">Innovation</div>
              <div className="fontSize13">
                Joulez stands at the forefront of the EV rental industry,
                ensuring you get the latest in modern luxury and convenience.
              </div>
            </div>
            <div className="AboutPageBox bg3B3936 m-3">
              <img src={customerCentricityIcon} alt="chargeIcon" />
              <div className="fontSize17">Customer Centricity</div>
              <div className="fontSize13">
                Your journey, our focus. We shape our services to fit your
                desires, ensuring an experience tailored just for you.
              </div>
            </div>
            <div className="AboutPageBox bg3B3936 m-3">
              <img src={sustainabilityIcon} alt="chargeIcon" />
              <div className="fontSize17">sustainability</div>
              <div className="fontSize13">
                Driving luxury without the carbon footprint. With every Joulez
                ride, you're embracing a greener tomorrow.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="darkGreyMode p-5">
        <div className="d-flex flex-column justify-content-center align-items-center p-3">
          <h2 className="py-3 fontAtacama aboutPageHeading text-center">
            Our Leadership
          </h2>
          <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center w-100">
            <div className="text-center">
              <img
                className="m-3 LeaderShipImages"
                src={images.micahBergdaleImage}
                alt="founderImage"
              />
              <div className="fontWeight400-A">Micah Bergdale</div>
              <div className="fontWeight400-A">Founder & CEO</div>
            </div>
            <div className="text-center">
              <img
                className="m-3 LeaderShipImages"
                src={images.robinsonMorriImage}
                alt="founderImage"
              />
              <div>Robinson Mori</div>
              <div>VP of Operations</div>
            </div>
            <div className="text-center">
              <img
                className="m-3 LeaderShipImages"
                src={images.tanielArturImage}
                alt="founderImage"
              />
              <div>Taniel Artur</div>
              <div>Sales Adminstratoe</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          isDarkMode ? "darkMode fontcolorFFF" : "lightMode fontcolor000"
        } w-100`}
      >
        <div className="col-12 col-md-7 m-auto text-center p-3">
          <div className="fontAtacama discoverFontSize p-3">{title}</div>
          <div className="fontAntarctica fontSize17">{description}</div>
        </div>
      </div>
      <div>
        <CarouselContainer />
      </div>
    </div>
  );
};

export default AboutUs;
