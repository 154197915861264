import React, { useState } from "react";
import amazonIcon from "../assets/svg/amazon_icon.svg";
import { LoginSocialAmazon } from "reactjs-social-login";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { BASE_URL } from "../api";
import { toast } from "react-toastify";
import { setCurrentUserDetails } from "../Store/userDetailsSlice";
import { useDispatch } from "react-redux";
//const REDIRECT_API_URL = "https://dev.drivejoulez.com:8443/joulez-service";

const SocialLogins = ({handleCloseAmazonSigninModal}) => {
  const [provider, setProvider] = useState("");
  const dispatch = useDispatch();
  const [socialUser, setSocialUser] = useState({
    provider: "",
    id: "",
    email: "",
    name: "",
    photoUrl: "",
    firstName: "",
    lastName: "",
    authToken: "",
    idToken: "",
    authorizationCode: "",
    response: null,
  });
  const [signUp, setSignUp] = useState({
    email: "",
    password: "",
    firstName: "",
    middleName: "",
    lastName: "",
    countryCode: "",
    phoneNumber: "",
    phoneNumberCountryCode: "",
    dob: "",
    loginType: "CUSTOM",
    id: "",
  });

  const handleResolve = ({ provider, data }) => {
    try {
      if (data?.access_token) {
        fetchUserProfile(data.access_token);
        setProvider(provider);
    
      } else {
        console.error("Access token not provided");
      }
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  };
  const fetchUserProfile = async (accessToken) => {
    try {
      const response = await fetch("https://api.amazon.com/user/profile", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const userData = await response.json();
        setSocialUser(userData);
      // Populate signupUser details
      const updatedSignupUser = {
        ...signUp,
        id: userData?.user_id,
        firstName: userData?.name,
       // lastName: userData?.data?.family_name,
        email: userData?.email,
        loginType:"AMAZON"
      };
      setSignUp(updatedSignupUser);
      try {
        const response = await axios.post(`${BASE_URL}user/socialLogin/open`,updatedSignupUser); // Replace with your API
        const { jwt } = response.data.data;
        // Store JWT in Local storage
        localStorage.setItem("AccessToken", jwt);
        // Decode JWT to get user details
        const userDTO = jwtDecode(jwt);
        userDTO.profileImage = userData.picture;
        // Fetch current user and update application state
        const userDataResponse = await axios.get(`${BASE_URL}user/currentUser`,
            {
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        //onLogin(true);
        const userData1 = userDataResponse.data;
        localStorage.setItem("CurrentUserDetails",JSON.stringify(userData1?.data));
        dispatch(setCurrentUserDetails(jwt));
        toast.success(response.data.data.message);
        if (response.data.data.message === "Successfully signed up") {
          this.callAnalytics();
        }
        handlePostLoginLogic(jwt, userData1);
        //  <SpinnerLoader />
        handleCloseAmazonSigninModal() // Close modal on successful login
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
      } else {
        console.error("Failed to fetch user profile:", response.status);
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  const handlePostLoginLogic = async (jwt, userData) => {
    const selectedCar = localStorage.getItem("selectedCar");
    const bookingInputs = localStorage.getItem("bookingInputs");
    if (selectedCar && bookingInputs) {
      try {
        const parsedBookingInputs = JSON.parse(bookingInputs);
        const estimationResponse = await axios.post(
          `${BASE_URL}booking/estimatedPrice`,
          parsedBookingInputs,
          { headers: { Authorization: `Bearer ${jwt}` } }
        );
        localStorage.setItem(
          "bookingInputs",
          JSON.stringify(estimationResponse?.data?.data)
        );
      } catch (error) {
        toast.error(
          error.response?.data?.message || "Error fetching estimation price."
        );
      }
    }
  };
  
  return (
    <>
      <LoginSocialAmazon
        isOnlyGetToken
        client_id="amzn1.application-oa2-client.c8c5064253244f63b6f8050420139df7"
        redirect_uri={BASE_URL}
        onResolve={handleResolve}
        onReject={(err) => {
        }}
      >
        <div className="p-2 border3B3936 borderRadius8 cursor social-icon">
          <img src={amazonIcon} alt="amazon_icon" />
        </div>
      </LoginSocialAmazon>
    </>
  );
};

export default SocialLogins;
