import React from "react";
import { useSelector } from "react-redux";

const MyTripShimmer = () => {
  const isDarkMode = useSelector((store) => store?.currentModeStatus?.darkMode);

  const renderTripItem = () => (
    <div className="borderC780 borderRadius12 mb-4 placeholder-glow">
      <div className="d-flex justify-content-between align-items-center col-12 p-3">
        <div className="col-2 p-1 d-flex justify-content-center align-items-center placeholder-glow">
          <div className="placeholder col-5 p-3 borderRadius4 placeholder-lg"></div>
        </div>
        <div className="col-5 p-1">
          <div className="d-flex flex-column">
            <div className="placeholder col-8 borderRadius4 mb-2"></div>
            <div className="placeholder col-2 borderRadius4"></div>
          </div>
        </div>
        <div className="col-3 p-1">
          <div className="d-flex flex-column">
            <div className="placeholder col-4 borderRadius4 mb-2"></div>
            <div className="placeholder col-5 borderRadius4"></div>
          </div>
        </div>
        <div className="col-2 p-1 text-end">
          <div className="placeholder col-10 borderRadius4 mb-2"></div>
          <div className="placeholder col-6 borderRadius4"></div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`min-vh-100 ${isDarkMode ? "darkMode" : "bgFEFDFD"}`}>
      <div>
        {/* Render trip items 10 times */}
        {[...Array(10)].map((_, index) => (
          <div key={index}>{renderTripItem()}</div>
        ))}
      </div>
    </div>
  );
};

export default MyTripShimmer;
