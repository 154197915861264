import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import thankYouImage from "../assets/Images/thankyoudesktop.jpg";
import SpinnerLoader from "../Components/SpinnerLoader";
import { setCurrentPage } from "../Store/pageSlice";

const Thankyou = () => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const location = useLocation();
  
  useEffect(() => {
    dispatch(setCurrentPage("thankyou"));
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);

    let storedUserDetails;
    setTimeout(() => {
      storedUserDetails = JSON.parse(
        localStorage.getItem("CurrentUserDetails")
      );
      if (storedUserDetails) {
        // Parse and update state
        setUserDetails(storedUserDetails);
      }
    }, 3000);

  }, [userDetails]);

  return (
    <>
      {!userDetails ? (
        <div className="overlay">
          <div className="spinner-container">
            <SpinnerLoader />
          </div>
        </div>
      ) : (
        <div
          className="vh-100 d-flex mobileThankYou justify-content-center fontWeight400"
          style={{
            backgroundImage: `url(${thankYouImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="thankyou-container  text-dark  p-4 fontAntarctica">
            <h1 className="display-5 thankyou-heading mb-3">
              <strong>Thank You!</strong>
            </h1>
            <p className="mb-2 thankyou-text fontAntarctica">
              Your submission has been received!
            </p>
            {!userDetails?.emailVerified &&
              userDetails?.emailVerified !== null && (
                <p className=" mb-2 thankyou-text fontAntarctica">
                  A verification email has been sent to your registered email.
                </p>
              )}
            {!userDetails?.emailVerified &&
              userDetails?.emailVerified !== null && (
                <p className="mb-3 thankyou-text fontAntarctica">
                  Please verify before proceeding with the booking.
                </p>
              )}
            <button
              onClick={() => {
                navigate("/");
              }}
              className="btn btn-primary mt-3 fontAntarctica bg725CE1 thankyou-button"
            >
              Back to Home
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Thankyou;
