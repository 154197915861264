// import { createSlice } from "@reduxjs/toolkit";
// import { format } from "date-fns";

// const formattedDate = format(new Date(`${JSON.parse(localStorage?.getItem("allLocationsTimeZones"))[0]?.timeSlots?.currentDate}T00:00:00`), 'MMM dd, yyyy');

// const tomorrowDate = new Date(formattedDate);
// tomorrowDate.setDate(tomorrowDate.getDate() + 1);


// const locationTimeData = JSON.parse(localStorage?.getItem("allLocationsTimeZones")) || []

// const dateAndTimeSlice = createSlice({
//   name: "dateAndTime",
//   initialState: {
//     pickupAndDropDates: {
//       pickupDate: format(formattedDate, "MMM dd, yyyy"),
//       dropDate: format(tomorrowDate, "MMM dd, yyyy"),
//     },
//     pickupAndDropTimings: {
//       pickupTime: locationTimeData[0]?.timeSlots?.currentDateTimeSlots[0],
//       dropTime: locationTimeData[0]?.timeSlots?.currentDateTimeSlots[0],
//     },
//   },
//   reducers: {
//     addPickupAndDropDates: (state, action) => {
//       const { pickupDate, dropDate="Select Date" } = action.payload;
//       state.pickupAndDropDates.pickupDate = pickupDate;
//       state.pickupAndDropDates.dropDate = dropDate;
//     },
//     addPickupAndDropTimings: (state, action) => {
//       const { pickupTime, dropTime } = action.payload;
//       if (pickupTime !== undefined) {
//         state.pickupAndDropTimings.pickupTime = pickupTime;
//       }
//       if (dropTime !== undefined) {
//         state.pickupAndDropTimings.dropTime = dropTime;
//       }
//     },
//   },
// });

// export const { addPickupAndDropDates, addPickupAndDropTimings } =
//   dateAndTimeSlice.actions;

// export default dateAndTimeSlice.reducer;


// import { createSlice } from "@reduxjs/toolkit";
// import { format } from "date-fns";

// const locationTimeData = JSON.parse(localStorage.getItem("allLocationsTimeZones")) || [];

// // Check if locationTimeData has the expected structure
// const currentDate = locationTimeData?.timeSlots?.currentDate || new Date().toISOString().split('T')[0];
// const formattedDate = format(new Date(`${currentDate}T00:00:00`), 'MMM dd, yyyy');

// const tomorrowDate = new Date(formattedDate);
// tomorrowDate.setDate(tomorrowDate.getDate() + 1);
// const initialPickupTime = locationTimeData?.timeSlots?.currentDateTimeSlots[0] || "Select Time";
// const initialDropTime = locationTimeData?.timeSlots?.currentDateTimeSlots[0] || "Select Time";

// const dateAndTimeSlice = createSlice({
//   name: "dateAndTime",
//   initialState: {
//     pickupAndDropDates: {
//       pickupDate: formattedDate,
//       dropDate: format(tomorrowDate, "MMM dd, yyyy"),
//     },
//     pickupAndDropTimings: {
//       pickupTime: initialPickupTime,
//       dropTime: initialDropTime,
//     },
//   },
//   reducers: {
//     addPickupAndDropDates: (state, action) => {
//       const { pickupDate, dropDate = "Select Date" } = action.payload;
//       state.pickupAndDropDates.pickupDate = pickupDate;
//       state.pickupAndDropDates.dropDate = dropDate;
//     },
//     addPickupAndDropTimings: (state, action) => {
//       const { pickupTime, dropTime } = action.payload;
//       if (pickupTime !== undefined) {
//         state.pickupAndDropTimings.pickupTime = pickupTime;
//       }
//       if (dropTime !== undefined) {
//         state.pickupAndDropTimings.dropTime = dropTime;
//       }
//     },
//   },
// });

// export const { addPickupAndDropDates, addPickupAndDropTimings } =
//   dateAndTimeSlice.actions;

// export default dateAndTimeSlice.reducer;


import { createSlice } from "@reduxjs/toolkit";
import { format, addHours, addMinutes, startOfHour } from "date-fns";
import { toZonedTime } from "date-fns-tz"; // Corrected import

// Function to get current time in the Bronx, add 2 hours, and round to next hour if needed
const getBronxTimeAfterTwoHours = () => {
  const bronxTimeZone = "America/New_York";
  const currentTimeInBronx = toZonedTime(new Date(), bronxTimeZone); // Get current time in Bronx timezone

  // Add 2 hours to the current time
  let timeAfterTwoHours = addHours(currentTimeInBronx, 2);

  // Round to next full hour if the current time is not exactly on the hour
  if (currentTimeInBronx.getMinutes() > 0) {
    timeAfterTwoHours = startOfHour(addHours(timeAfterTwoHours, 1)); // Round to next hour
  }

  // Return formatted time as 'hh:mm a'
  return timeAfterTwoHours;
};

// Retrieve the locationTimeData from localStorage
const locationTimeData = JSON.parse(localStorage.getItem("allLocationsTimeZones")) || [];

// Check if locationTimeData has the expected structure
const currentDate = locationTimeData?.timeSlots?.currentDate || new Date().toISOString().split('T')[0];
const formattedDate = format(new Date(`${currentDate}T00:00:00`), "MMM dd, yyyy");

const tomorrowDate = new Date(formattedDate);
tomorrowDate.setDate(tomorrowDate.getDate() + 1);
// Get the initial pickup and drop times based on the current time in the Bronx
const initialPickupTime = getBronxTimeAfterTwoHours();
const initialDropTime = format(addMinutes(initialPickupTime, 0), "hh:mm a");

const dateAndTimeSlice = createSlice({
  name: "dateAndTime",
  initialState: {
    pickupAndDropDates: {
      pickupDate: formattedDate,
      dropDate: format(tomorrowDate, "MMM dd, yyyy"),
    },
    pickupAndDropTimings: {
      pickupTime: format(initialPickupTime, "hh:mm a"),
      dropTime: initialDropTime,
    },
  },
  reducers: {
    addPickupAndDropDates: (state, action) => {
      const { pickupDate, dropDate = "Select Date" } = action.payload;
      state.pickupAndDropDates.pickupDate = pickupDate;
      state.pickupAndDropDates.dropDate = dropDate;
    },
    addPickupAndDropTimings: (state, action) => {
      const { pickupTime, dropTime } = action.payload;
      if (pickupTime !== undefined) {
        state.pickupAndDropTimings.pickupTime = pickupTime;
      }
      if (dropTime !== undefined) {
        state.pickupAndDropTimings.dropTime = dropTime;
      }
    },
  },
});

export const { addPickupAndDropDates, addPickupAndDropTimings } =
  dateAndTimeSlice.actions;

export default dateAndTimeSlice.reducer;
