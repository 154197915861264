import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../Store/pageSlice';

const PrivacyPolicy = () => {
    const dispatch = useDispatch()
    const currentYear = new Date().getFullYear();
    const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      useEffect(() => {
        dispatch(setCurrentPage("privacy-policy"));
      }, [dispatch]);

  return (
    <div className={`${
        isDarkMode ? "darkGreyMode" : "lightGreyMode"
      } pt-5`}>
        <div className="container footer_adjust Pt-5 pp-privacy-policy fontColorFCC">
    <div className="row">
        <div className="col-md-12 mt-3 pp-section">
            <h2 className="text-center mb-3 title">Privacy Policy</h2>
            <br/>
            <p>Our Privacy Policy was last updated on July 11, 2024</p>
            <p>This Privacy Policy (this “Policy”) describes the practices of Joulez Inc., (“Joulez”) for collecting, using, maintaining, disclosing, and otherwise processing (collectively, “Processing”) the information that we collect from you, as well as how we protect and secure your data.  If you have additional questions regarding your rights under this Policy or any agreement you may have with Joulez regarding your data, please contact us at <a href="mailto:legal@drivejoulez.com" className='fontColorCE1 text-decoration-none'>legal@drivejoulez.com</a>.  <strong>Please read this Policy carefully to understand our Processing of your information and how we will treat it.</strong></p>

            <p>This Policy generally applies to the information that Joulez Processes.  However, this Policy is subordinate to any specific terms and conditions set forth in any terms of use, end user license agreements, or other agreements you may have with Joulez relating to a particular product, service, or data.  Further, this Policy applies only to information Joulez Processes.  It does not apply to information collected by Joulez’s business partners, which may have their own privacy policies that apply to you.  </p>
        </div>

        <div className="col-md-12 pp-section">
            <h3 className="pp-heading fontcolorFFF">1. What information does Joulez collect? </h3>
            <p>The information Joulez collects can be described as Personal Information and Non-Personal Information, as well as Vehicle Data (which may or may not be personally identifiable).  We collect this information when you provide it to us or when you use our products or services, including our websites and mobile apps.</p>
            <p>“Personal Information” is information that can be used to identify, locate, or contact you, a natural person, as well as any other information about you that we may connect with Personal Information.  For example, Personal Information that we collect can include: </p>
            <ul>
                <li>ID verification information including your name, phone number, physical address, email address, mailing address, driver’s license number, social security number, passport information, username, and password; </li>
                <li>Relationship information such as your preferences or potential interest in Joulez products and services; </li>
                <li>Transactional information such as purchases, services requested, customer service inquiries, and other customer account information; and </li>
                <li>Financial information such as credit card, banking, or financing information.</li>
                <li>Insurance information; driving history and vehicle data; GPS information</li>
            </ul>

            <br/>

            <p>“Non-Personal Information,” on the other hand, is information that does not identify you as a natural person and is not identifiable to you a natural person.  For example, Non-Personal Information that we collect includes:</p>
            <ul>
                <li>Cookies and other similar tracking technologies used in a non-identifiable way; </li>
                <li>Information we anonymize by rendering it unidentifiable to a natural person; </li>
                <li>Information we aggregate by combining it with other data in such a way that no natural person can be identified or linked to any specific information; and </li>
                <li>The name, form, contact details, transaction history, or financial information of legal persons. </li>
            </ul>
            <p>“Vehicle Data”: Please note that the vehicles you operate may collect personal information or nonpersonal information about you.  Joulez is not responsible for these activities, and such collection and use is subject to the vehicle manufacturers’ policies and notices.</p>
        </div>

        <div className="col-md-12 pp-section">
            <h3 className="pp-heading fontcolorFFF">2. Why does Joulez collect this information? </h3>
            <p>Joulez collects and otherwise Processes Personal Information and Operations Data in identifiable forms for a variety of purposes, depending on the information being Processed. In particular, Joulez Processes this information to perform under its contractual obligations to you, providing the products and services you have purchased and Processing information as may be necessary to provide you with the products and services and support you in their use. Joulez also Processes information given its legitimate interest in improving its products and services, enhancing customer experience, and identifying and marketing certain products and services to you. Finally, Joulez may Process information as necessary to comply with a legal obligation.</p>
            <p>To summarize, Joulez Processes Personal Information and Operations Data in identifiable form for the following purposes: </p>
            <ul>
                <li>To fulfill orders for Joulez products and services; </li>
                <li>To operate, deliver, maintain, or otherwise provide the Joulez products and services you request; </li>
                <li>To support Joulez products and services; </li>
                <li>To set up and maintain your customer account and allow you to interact with Joulez online;</li>
                <li>To personalize your user experience;</li>
                <li>To provide you with customer service by resolving disputes, addressing complaints, and troubleshooting any technical problems encountered; </li> 
                <li>To measure and understand the effectiveness of Joulez products and services; </li>
                <li>To improve Joulez products and services and develop new ones; </li>
                <li>To comply with applicable laws and regulations; and </li>
                <li>To communicate offers for products and services, including surveys and offers based on your interests and usage of Joulez Products and Services, administer promotional events, and engage in other marketing activities that may be of interest to you.  We will provide you the opportunity to opt-out of marketing information or market research inquiries, but we will still need to gather certain information to provide our products and services to you.  </li>
            </ul>

            <p>If we collect or Process your information for any other purposes, we will disclose it to you at the time it is collected.</p>
            <p>With respect to Non-Personal Information and Operations Data not in identifiable form, because this information does not identify a natural person Joulez may use and Process it for any purpose.  Joulez expressly reserves the right to use anonymized or aggregated information to improve or develop its products and services, create and publish reports, conduct statistical analyses about customers and equipment, monitor industry trends, and otherwise engage in activities that do not result in the disclosure of identifiable information. </p>  
            <p>To be clear, Joulez is committed to respecting its customer’s privacy and will only Process your Personal Information or Operations Data in identifiable form for the reasons disclosed herein, and then only in the context of your customer relationship with Joulez. Joulez will not sell, rent, or lease your Personal Information in identifiable form to others. </p>
        </div>

        <div className="col-md-12 pp-section">
            <h3 className="pp-heading fontcolorFFF">3. Will Joulez share this information with third parties?  </h3>
            <p>To provide certain products and services, Joulez may share information with third parties who provide services to us, such as the manufacturers, distributors, and operators of hardware and software used in our service. Joulez may also share information with third parties we engage to assist us in providing you with our products and services, as well as third party advertising and social media companies to help us  promote our products and services. <u>Any third party with whom we share identifiable information will only have access necessary to perform specific tasks on our behalf, will only use the information for this purpose, and will protect your information at least to the same extent that we do.</u></p>
            <p>Joulez may share information to comply with our legal obligations, such as to respond to lawful requests and legal processes.  In the event we are asked to disclose your Personal Information, we will attempt to validate the request and inform you of it.</p>  
            <p>Joulez may share information for security and safety purposes, including identity verification, dispute resolution, fraud detection and prevention, and to identify unsafe or dangerous driving behavior.</p>
            <p>Finally, Joulez may disclose information where needed to affect the sale or transfer of its assets, enforce its rights, protect its property and technology, or protect the rights, property, or safety of others, or as needed to support external auditing, compliance, and corporate governance functions.  </p>
            <p>If Joulez needs to share your information for a purpose not identified here, we will obtain your consent and disclose this purpose at the time of obtaining your consent.  For example, Joulez may wish to use your information in demonstrations or other marketing materials and will contact you before doing so. </p>
        </div>

        <div className="col-md-12 pp-section">
            <h3 className="pp-heading fontcolorFFF">4. How long will Joulez keep my information? </h3>
            <p>Joulez keeps different kinds of information for different lengths of time, depending on the purpose for which it was processed and your specific situation.  Thus, Joulez will retain information as long as necessary to accomplish the relevant purpose, but no longer. </p>
        </div>

        <div className="col-md-12 pp-section">
            <h3 className="pp-heading fontcolorFFF">5. How does Joulez keep my information secure? </h3>
            <p>Taking into account the nature of the information gathered, Joulez maintains administrative, technical, and physical controls that are designed to safeguard Personal Information and Operations Data in identifiable form. </p>
        </div>


        <div className="col-md-12 pp-section">
            <h3 className="pp-heading fontcolorFFF">6. What are my choices with respect to my information? </h3>
            <p>Your first choice is always to limit the information you provide.  You may also opt-out of certain marketing information by visiting our website and communicating your choices to us, or by clicking “unsubscribe” at the bottom of marketing emails you might receive.</p>  
            <p>Regarding cookies and other tracking technologies, you can manage these by adjusting the settings on your browser, commonly referred to as the browser’s “Do Not Track” settings.  All browsers are different, so you may need to visit the “help” section of your browser to learn more about cookie preferences and other privacy settings that may be available.  You can also manage how your mobile device and mobile browser share location information with Joulez, as well as how your mobile browser handles cookies and related technologies by adjusting your mobile device privacy and security settings.  Please refer to instructions provided by your mobile service provider or the manufacturer of your device to learn more.  In general, Joulez will comply with your browser’s “Do Not Track” settings.  </p>
            <p>Joulez complies with applicable law regarding your ability to access, correct, and delete your identifiable information. If you have an online account, you may be able to log into your account to access and update certain information provided. </p> 
            <p>Subject to the specific terms of your agreement related to the collection of Operations Data, you may be able to opt-out of sharing this information with Joulez.  If you choose to do so, you may no longer have access to this data and would no longer be able to use certain products or services that rely on this data. </p> 
            <p>Joulez does not intend to offer services or collect Personal Information from children aged 16 and under.  If you believe a child is providing us Personal Information, please contact us directly so we may investigate and delete it. </p> 
            <p>For assistance with exercising your personal information choices, or if you have any questions about these choices, please contact us at <a href="mailto:legal@drivejoulez.com" className='fontColorCE1 text-decoration-none'>legal@drivejoulez.com</a> or <a href="tel:+19292315297">+1-929-231-5297</a></p>
        </div>

        <div className="col-md-12 pp-section">
            <h3 className="pp-heading fontcolorFFF">7. For EU residents: </h3>
            <p>The General Data Protection Regulation (“GDPR”), particularly Articles 15 to 21, affords you a number of rights as a data subject. We encourage you to read and learn about those rights on your own, but the following will help you to begin to understand those rights.</p>

            <ul className="list-unstyled ml-1">
                <li>a. Access: This allows you to request from us information about your personal information, including what data we have and how and why it is being processed.</li>
                <li>b. Rectification: In the event that some or all of the personal information that we have concerning you is inaccurate, this gives you the right to have those inaccuracies rectified. </li>
                <li>c. Erasure: In some cases, you may have the right to request that we erase your personal information in our possession.</li>
                <li>d. Restriction of Processing: This gives you the right, in some circumstances, to have us process your personal information only with your consent.</li>
                <li>e. Objection to Processing: Where we are processing your personal information on the grounds of its legitimate interests, or to carry out tasks in the public interest or to exercise official authority, you have a right to object to the processing on grounds relating to your particular situation. In cases where you object to our processing of your personal data for direct marketing purposes, the processing will cease.</li>
                <li>f. Data Portability: This allows you to request a copy of your personal information that it has on file and to transfer that information to someone else without any interference from us. In some cases, you may be able to request that we transfer your personal information directly to a third party on your behalf.</li>
            </ul>

            <p>In addition, to the extent that we process any of your personal information on the basis of your consent, you have the right to withdraw that consent at any time, without affecting the lawfulness of processing that was based on your consent before its withdrawal.</p>
            <p>If you are a natural person residing in the EU and at any time you believe we have violated one of your rights listed above, or if you believe we are in violation of one or more of the provisions of the GDPR, you have the right to lodge a complaint with a Data Protection Authority. More information about the Data Protection Authorities can be found at <a href="https://commission.europa.eu/law/law-topic/data-protection/reform/what-are-data-protection-authorities-dpas_en" target="_blank" rel="noopener noreferrer"
            className='fontColorCE1 text-decoration-none' >https://commission.europa.eu/law/law-topic/data-protection/reform/what-are-data-protection-authorities-dpas_en.</a></p>
        </div> 

        <div className="col-md-12 pp-section">
            <h3 className="pp-heading fontcolorFFF">8. For California residents:  </h3>
            <strong>This notice does not apply to employment-related personal information collected from California-based employees, job applicants, contractors, or similar individuals.  </strong>
            <p>The California Consumer Privacy Act (the “CCPA”) affords those who reside in the State of California certain rights as a data subject.  If you are a resident of the State of California, we encourage you to read and learn about those rights on your own, but the following will help you to begin to understand them: </p>
            <ul>
                <li>Access: You have the right to request that we disclose certain information to you about our collection and use of your personal information. </li> 
                <li>Erasure: In some cases, you may have the right to request that we erase your personal  information in our possession.</li>  
                <li>Data Portability: This allows you to request a copy of your personal information that we may have and transfer that information to someone else without any interference from us.  In some cases, you may be able to request that we transfer your personal information directly to a third party on your behalf. </li>
                <li>The CCPA further provides California residents the right to direct us not to sell your personal information.  WE DO NOT SELL YOUR PERSONAL INFORMATION.</li>
            </ul>
            <p>We will not discriminate against you for exercising any of your CCPA rights.</p>
            <p>In addition, California’s “Shine the Light” law permits you to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes.</p>
            <p>To make a request regarding any of these rights or to ask questions or provide comments about this Privacy Notice for California Residents, please contact us via support@musicleague.com.</p>
            <p>It is our policy to post any changes to this Privacy Notice on this page.  We may or may not contact you directly concerning significant changes.  We encourage you to visit this page periodically and check for changes.  To the extent permitted by law, your continued use of our services after a change is deemed to be your consent to any such change.</p>
        </div>

        <div className="col-md-12 pp-section">
            <h3 className="pp-heading fontcolorFFF">9. For international residents:</h3>
            <p>Joulez is headquartered in the United States of America. It and its authorized processors may transfer your information to the United States of America and access it from the Unites States of America for the Purposes described in this Policy. Joulez protects the privacy and security of Personal Information in the manner described in this Policy regardless of where it is collected, stored, accessed, or otherwise Processed. </p>
        </div>

        <div className="col-md-12 pp-section">
            <h3 className="pp-heading fontcolorFFF">10. Does this Policy ever change? </h3>
            <p>Joulez may post changes to this Policy on this page.  Please refer to the “last updated” date above.  We may or may not contact your directly concerning significant changes, and therefore we encourage you to visit this page periodically and check.  By using Joulez products and services after a change to this Policy, you are deemed to consent to any changes.</p>
        </div>

        <div className="col-md-12 pp-section">
            <address>
                Joulez Inc.,<br/>
                {/* 1100 Grand Concourse #1L<br/> */}
                1225 Randall Ave. FL3<br/>
                {/* Bronx, NY 10456 */}
                Bronx, NY 10474
            </address>
        </div>

        <div className="col-md-12 pp-section">
            <p>&copy; { currentYear } Joulez, Inc.  All rights reserved.</p>
        </div>


    </div>
</div>

      </div>
    
  )
}

export default PrivacyPolicy