// import React, { useEffect, useState, useRef } from "react";
// import { useSwipeable } from "react-swipeable";
// import closeIcon from "../assets/svg/closeIcon.svg";
// import notchIcon from "../assets/svg/notchIcon.svg";

// const ModalContainer = ({
//   children,
//   isVisible,
//   onClose,
//   height = "100%",
//   zindex = 1000,
//   modalTopHeading,
//   hideZindex,
// }) => {
//   const [isDragging, setIsDragging] = useState(false);
//   const [translateY, setTranslateY] = useState(isVisible ? 0 : "100%");
//   const modalBodyRef = useRef(null);
//   const notchRef = useRef(null);

//   useEffect(() => {
//     const body = document.body;
//     if (isVisible || hideZindex) {
//       body.style.overflow = "hidden";
//       setTranslateY(0); // Reset translation when opening
//     } else {
//       body.style.overflow = "visible";
//     }

//     return () => {
//       body.style.overflow = "visible";
//     };
//   }, [isVisible, hideZindex]);

//   // Check if the modal content is at the top or bottom
//   const isAtTop = () => modalBodyRef.current?.scrollTop === 0;
//   const isAtBottom = () => {
//     return modalBodyRef.current
//       ? modalBodyRef.current.scrollHeight ===
//         modalBodyRef.current.scrollTop + modalBodyRef.current.clientHeight
//       : false;
//   };

//   const handlers = useSwipeable({
//     onSwiping: ({ deltaY, event }) => {
//       // Allow swiping only if there's enough content
//       const contentHeight = modalBodyRef.current.scrollHeight;
//       const isScrollable = contentHeight > modalBodyRef.current.clientHeight;

//       if (notchRef.current && notchRef.current.contains(event.target)) {
//         setTranslateY(deltaY);
//         setIsDragging(true);
//       } else if (deltaY > 0 && isAtBottom() && isScrollable) {
//         // Allow swiping down only when at the bottom
//         setTranslateY(deltaY);
//         setIsDragging(true);
//       } else if (deltaY < 0 && isAtTop() && isScrollable) {
//         // Allow swiping up only when at the top and there is more content
//         setTranslateY(deltaY);
//         setIsDragging(true);
//       }
//     },
//     onSwipedDown: ({ event }) => {
//       if (notchRef.current && notchRef.current.contains(event.target)) {
//         setIsDragging(false);
//         onClose();
//       } else if (isAtBottom()) {
//         setIsDragging(false);
//         onClose();
//       }
//     },
//     onSwipedUp: ({ event }) => {
//       if (notchRef.current && notchRef.current.contains(event.target)) {
//         setIsDragging(false);
//         setTranslateY(0);
//       } else if (isAtTop()) {
//         setIsDragging(false);
//         setTranslateY(0);
//       }
//     },
//     preventDefaultTouchmoveEvent: true,
//     trackMouse: true,
//   });

//   return (
//     isVisible && (
//       <div
//         className={`modal-content-container ${isDragging ? "dragging" : ""}`}
//         style={{
//           transform: `translateY(${translateY}px)`,
//           height: `${height}`,
//           zIndex: `${zindex}`,
//         }}
//         onClick={(e) => e.stopPropagation()}
//         {...handlers}
//       >
//         <div ref={notchRef}>
//           <div className="d-flex justify-content-center align-items-center">
//             <img className="notchImage" src={notchIcon} alt="Notch Icon" />
//           </div>
//           <div>
//             <div className="d-flex justify-content-between align-items-center p-2">
//               <div className="fontSize17 fontAntarctica">{modalTopHeading}</div>
//               <div>
//                 <img
//                   className="closeImage"
//                   src={closeIcon}
//                   alt="Close Icon"
//                   onClick={onClose}
//                 />
//               </div>
//             </div>
//             <div className="w-100 d-flex justify-content-center">
//               <div className="modalBorder"></div>
//             </div>
//           </div>
//         </div>
//         <div ref={modalBodyRef} className="modal-body customizedScrollBar">
//           {children}
//         </div>
//       </div>
//     )
//   );
// };

// export default ModalContainer;


// import React, { useState, useEffect, useRef } from "react";
// import { useSwipeable } from "react-swipeable";
// import closeIcon from "../assets/svg/closeIcon.svg";
// import notchIcon from "../assets/svg/notchIcon.svg";

// const ModalContainer = ({
//   children,
//   isVisible,
//   onClose,
//   height = "100%", // Default height for bottom sheet
//   zindex = 1000, // Default zIndex for visibility
//   modalTopHeading,
//   hideZindex,
// }) => {
//   const [isDragging, setIsDragging] = useState(false);
//   const [translateY, setTranslateY] = useState(isVisible ? 0 : "100%"); // Default to hidden
//   const modalBodyRef = useRef(null);
//   const notchRef = useRef(null);

//   // Ensure the body doesn't scroll when modal is visible
//   useEffect(() => {
//     const body = document.body;
//     if (isVisible || hideZindex) {
//       body.style.overflow = "hidden";
//       setTranslateY(0); // Reset translation when opening
//     } else {
//       body.style.overflow = "visible";
//     }

//     return () => {
//       body.style.overflow = "visible";
//     };
//   }, [isVisible, hideZindex]);

//   // Check if the modal content is at the top or bottom of the modal body
//   const isAtTop = () => modalBodyRef.current?.scrollTop === 0;
//   const isAtBottom = () => {
//     return modalBodyRef.current
//       ? modalBodyRef.current.scrollHeight ===
//         modalBodyRef.current.scrollTop + modalBodyRef.current.clientHeight
//       : false;
//   };

//   // Swipe gesture handlers to drag the modal up or down
//   const handlers = useSwipeable({
//     onSwiping: ({ deltaY, event }) => {
//       const contentHeight = modalBodyRef.current.scrollHeight;
//       const isScrollable = contentHeight > modalBodyRef.current.clientHeight;

//       if (notchRef.current && notchRef.current.contains(event.target)) {
//         setTranslateY(deltaY); // Track swipe movement
//         setIsDragging(true);
//       } else if (deltaY > 0 && isAtBottom() && isScrollable) {
//         // Allow swiping down only when at the bottom and there's more content to scroll
//         setTranslateY(deltaY);
//         setIsDragging(true);
//       } else if (deltaY < 0 && isAtTop() && isScrollable) {
//         // Allow swiping up only when at the top and there's more content to scroll
//         setTranslateY(deltaY);
//         setIsDragging(true);
//       }
//     },
//     onSwipedDown: ({ event }) => {
//       if (notchRef.current && notchRef.current.contains(event.target)) {
//         setIsDragging(false);
//         onClose(); // Close the modal when swiped down
//       } else if (isAtBottom()) {
//         setIsDragging(false);
//         onClose(); // Close the modal when at the bottom
//       }
//     },
//     onSwipedUp: ({ event }) => {
//       if (notchRef.current && notchRef.current.contains(event.target)) {
//         setIsDragging(false);
//         setTranslateY(0); // Reset position if swiped up
//       } else if (isAtTop()) {
//         setIsDragging(false);
//         setTranslateY(0); // Reset position if swiped up
//       }
//     },
//     preventDefaultTouchmoveEvent: true,
//     trackMouse: true, // Support for mouse dragging
//   });

//   return (
//     isVisible && (
//       <div
//         className={`modal-content-container ${isDragging ? "dragging" : ""}`}
//         style={{
//           transform: `translateY(${translateY}px)`,
//           height: `${height}`,
//           zIndex: `${zindex}`,
//           position: "fixed",
//           bottom: 0,
//           left: 0,
//           right: 0,
//           transition: "transform 0.3s ease-out", // Smooth transition
//         }}
//         onClick={(e) => e.stopPropagation()} // Prevent clicks outside from closing the modal
//         {...handlers}
//       >
//         <div ref={notchRef}>
//           <div className="d-flex justify-content-center align-items-center">
//             <img className="notchImage" src={notchIcon} alt="Notch Icon" />
//           </div>
//           <div>
//             <div className="d-flex justify-content-between align-items-center p-2">
//               <div className="fontSize17 fontAntarctica">{modalTopHeading}</div>
//               <div>
//                 <img
//                   className="closeImage"
//                   src={closeIcon}
//                   alt="Close Icon"
//                   onClick={onClose}
//                 />
//               </div>
//             </div>
//             <div className="w-100 d-flex justify-content-center">
//               <div className="modalBorder"></div>
//             </div>
//           </div>
//         </div>
//         <div ref={modalBodyRef} className="modal-body customizedScrollBar">
//           {children}
//         </div>
//       </div>
//     )
//   );
// };

// export default ModalContainer;


// import React, { useState, useEffect, useRef } from "react";
// import { useSwipeable } from "react-swipeable";
// import { Offcanvas } from "react-bootstrap"; // Bootstrap Offcanvas
// import closeIcon from "../assets/svg/closeIcon.svg";
// import notchIcon from "../assets/svg/notchIcon.svg";
// import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

// const ModalContainer = ({
//   children,
//   isVisible,
//   onClose,
//   height = "100%", // Default height for bottom sheet
//   zindex = 1000, // Default zIndex for visibility
//   modalTopHeading,
//   hideZindex,
// }) => {
//   const [isDragging, setIsDragging] = useState(false);
//   const [translateY, setTranslateY] = useState(isVisible ? 0 : "100%"); // Default to hidden
//   const offcanvasRef = useRef(null); // Ref for Offcanvas element
//   const modalBodyRef = useRef(null); // Ref for modal body
//   const notchRef = useRef(null); // Ref for notch (swipe area)

//   // Ensure the body doesn't scroll when offcanvas is visible
//   useEffect(() => {
//     const body = document.body;
//     if (isVisible || hideZindex) {
//       body.style.overflow = "hidden";
//     } else {
//       body.style.overflow = "visible";
//     }

//     return () => {
//       body.style.overflow = "visible";
//     };
//   }, [isVisible, hideZindex]);

//   // Check if the modal is at the top or bottom
//   const isAtBottom = () => {
//     return translateY >= 0; // At the bottom when translateY is 0
//   };

//   // Swipe gesture handlers to drag the modal up or down
//   const handlers = useSwipeable({
//     onSwiping: ({ deltaY, event }) => {
//       if (notchRef.current && notchRef.current.contains(event.target)) {
//         setTranslateY((prev) => Math.max(prev + deltaY, 0)); // Prevent dragging up
//         setIsDragging(true);
//       }
//     },
//     onSwipedDown: () => {
//       if (isAtBottom()) {
//         setIsDragging(false);
//         onClose(); // Close the offcanvas when swiped down
//       }
//     },
//     onSwipedUp: () => {
//       setIsDragging(false);
//       setTranslateY(0); // Reset position if swiped up
//     },
//     preventDefaultTouchmoveEvent: true,
//     trackMouse: true, // Support for mouse dragging
//   });

//   // Handle closing the offcanvas when dragging from the notch
//   const handleNotchClick = () => {
//     setTranslateY(translateY + 100); // Slight drag when clicked
//     if (translateY >= 200) {
//       onClose(); // Close when dragged down enough
//     }
//   };

//   return (
//     <Offcanvas
//       show={isVisible}
//       onHide={onClose}
//       placement="bottom"
//       style={{
//         zIndex: zindex,
//         height: height,
//         position: "fixed",
//         bottom: 0,
//         left: 0,
//         right: 0,
//         transform: `translateY(${translateY}px)`,
//         transition: "transform 0.3s ease-out", // Smooth transition
//       }}
//       backdrop={false} // Disable backdrop if you don't want it
//       scroll={false} // Disable scrolling if desired
//       ref={offcanvasRef}
//       {...handlers}
//     >
//       <div className="offcanvas-header d-flex flex-column text-white bg23" ref={notchRef}>
//         {/* Notch for drag */}
//         <div onClick={handleNotchClick}>
//           <img className="notchImage" src={notchIcon} alt="Notch Icon" />
//         </div>
//         <div className="d-flex justify-content-between align-items-center p-2 w-100">
//           <div className="fontSize17 fontAntarctica">{modalTopHeading}</div>
//           <div>
//             <img
//               className="closeImage"
//               src={closeIcon}
//               alt="Close Icon"
//               onClick={onClose}
//             />
//           </div>
//         </div>
//       </div>
//       <div ref={modalBodyRef} className="offcanvas-body bg23 customizedScrollBar">
//         {children}
//       </div>
//     </Offcanvas>
//   );
// };

// export default ModalContainer;

// import React, { useState, useEffect, useRef } from "react";
// import { Offcanvas } from "react-bootstrap";
// import closeIcon from "../assets/svg/closeIcon.svg";
// import notchIcon from "../assets/svg/notchIcon.svg";
// import "bootstrap/dist/css/bootstrap.min.css";

// const ModalContainer = ({
//   children,
//   isVisible,
//   onClose,
//   height = "100%",
//   zindex = 1000,
//   modalTopHeading,
//   hideZindex,
// }) => {
//   const [isDragging, setIsDragging] = useState(false);
//   const [translateY, setTranslateY] = useState(isVisible ? 0 : "100%");
//   const [startY, setStartY] = useState(0);
//   const offcanvasRef = useRef(null);
//   const modalBodyRef = useRef(null);
//   const notchRef = useRef(null);

//   useEffect(() => {
//     if (isVisible) {
//       setTranslateY(0);
//     } else {
//       setTranslateY("100%");
//     }
//   }, [isVisible]);

//   const handleDragStart = (e) => {
//     setIsDragging(true);
//     setStartY(e.touches ? e.touches[0].clientY : e.clientY); // Record start position
//   };

//   const handleDragMove = (e) => {
//     if (!isDragging) return;

//     const currentY = e.touches ? e.touches[0].clientY : e.clientY;
//     const deltaY = currentY - startY;
//     setTranslateY((prev) => Math.max(prev + deltaY, 0));
//   };

//   const handleDragEnd = () => {
//     setIsDragging(false);
//     if (translateY >= 200) {
//       onClose(); // Close when dragged down enough
//     } else {
//       setTranslateY(0); // Reset to top if not enough drag
//     }
//   };

//   return (
//     <Offcanvas
//       show={isVisible}
//       onHide={onClose}
//       placement="bottom"
//       style={{
//         zIndex: zindex,
//         height: height,
//         position: "fixed",
//         bottom: 0,
//         left: 0,
//         right: 0,
//         transform: `translateY(${translateY}px)`,
//         transition: isDragging ? "none" : "transform 0.4s cubic-bezier(0.4, 0, 0.2, 1)", // Smooth easing
//       }}
//       backdrop={false}
//       scroll={false}
//       ref={offcanvasRef}
//     >
//       <div
//         className="offcanvas-header d-flex flex-column text-white bg23"
//         ref={notchRef}
//         onMouseDown={handleDragStart}
//         onMouseMove={handleDragMove}
//         onMouseUp={handleDragEnd}
//         onTouchStart={handleDragStart}
//         onTouchMove={handleDragMove}
//         onTouchEnd={handleDragEnd}
//       >
//         <div>
//           <img className="notchImage" src={notchIcon} alt="Notch Icon" />
//         </div>
//         <div className="d-flex justify-content-between align-items-center p-2 w-100">
//           <div className="fontSize17 fontAntarctica">{modalTopHeading}</div>
//           <div>
//             <img
//               className="closeImage"
//               src={closeIcon}
//               alt="Close Icon"
//               onClick={onClose}
//             />
//           </div>
//         </div>
//       </div>
//       <div ref={modalBodyRef} className="offcanvas-body bg23 customizedScrollBar">
//         {children}
//       </div>
//     </Offcanvas>
//   );
// };

// export default ModalContainer;

// import React, { useState, useEffect, useRef } from "react";
// import { Offcanvas } from "react-bootstrap";
// import { useSpring, animated } from "@react-spring/web";
// import closeIcon from "../assets/svg/closeIcon.svg";
// import notchIcon from "../assets/svg/notchIcon.svg";
// import "bootstrap/dist/css/bootstrap.min.css";

// const ModalContainer = ({
//   children,
//   isVisible,
//   onClose,
//   height = "100%",
//   zindex = 1000,
//   modalTopHeading,
//   hideZindex,
// }) => {
//   const [isDragging, setIsDragging] = useState(false);
//   const [startY, setStartY] = useState(0);
//   const offcanvasRef = useRef(null);
//   const modalBodyRef = useRef(null);
//   const notchRef = useRef(null);

//   // Spring animation for translateY
//   const [{ y }, api] = useSpring(() => ({
//     y: isVisible ? 0 : 100, // Start from full view or hidden based on visibility
//     config: { tension: 170, friction: 20 }, // Adjust for smoothness
//   }));

//   // Update animation based on visibility
//   useEffect(() => {
//     api.start({ y: isVisible ? 0 : 100 });
//   }, [isVisible, api]);

//   const handleDragStart = (e) => {
//     setIsDragging(true);
//     setStartY(e.touches ? e.touches[0].clientY : e.clientY); // Record start position
//   };

//   const handleDragMove = (e) => {
//     if (!isDragging) return;

//     const currentY = e.touches ? e.touches[0].clientY : e.clientY;
//     const deltaY = currentY - startY;
//     api.start({ y: Math.min(Math.max(y.get() + deltaY, 0), 100) }); // Clamp between 0 and 100
//   };

//   const handleDragEnd = () => {
//     setIsDragging(false);
//     if (y.get() > 50) {
//       onClose(); // Close if dragged down halfway
//     } else {
//       api.start({ y: 0 }); // Snap back if not dragged enough
//     }
//   };

//   return (
//     <Offcanvas
//       show={isVisible}
//       onHide={onClose}
//       placement="bottom"
//       style={{
//         zIndex: zindex,
//         height: height,
//         position: "fixed",
//         bottom: 0,
//         left: 0,
//         right: 0,
//         backgrounudColor: "#262523"
//       }}
//       backdrop={false}
//       scroll={false}
//       ref={offcanvasRef}
//     >
//       <animated.div
//         className="offcanvas-header d-flex flex-column text-white bg23"
//         ref={notchRef}
//         style={{
//           transform: y.to((value) => `translateY(${value}%)`), // Animated translateY
//         }}
//         onMouseDown={handleDragStart}
//         onMouseMove={handleDragMove}
//         onMouseUp={handleDragEnd}
//         onTouchStart={handleDragStart}
//         onTouchMove={handleDragMove}
//         onTouchEnd={handleDragEnd}
//       >
//         <div>
//           <img className="notchImage" src={notchIcon} alt="Notch Icon" />
//         </div>
//         <div className="d-flex justify-content-between align-items-center p-2 w-100">
//           <div className="fontSize17 fontAntarctica">{modalTopHeading}</div>
//           <div>
//             <img
//               className="closeImage"
//               src={closeIcon}
//               alt="Close Icon"
//               onClick={onClose}
//             />
//           </div>
//         </div>
//       </animated.div>
//       <div ref={modalBodyRef} className="offcanvas-body bg23 customizedScrollBar">
//         {children}
//       </div>
//     </Offcanvas>
//   );
// };

// export default ModalContainer;

import React, { useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { useSpring, animated } from "@react-spring/web";
import closeIcon from "../assets/svg/closeIcon.svg";
import redCloseIcon from "../assets/svg/redCloseIcon.svg"
import notchIcon from "../assets/svg/notchIcon.svg";
import "bootstrap/dist/css/bootstrap.min.css";

const ModalContainer = ({
  children,
  isVisible,
  onClose,
  height = "100%",
  zindex = 1000,
  modalTopHeading,
  hideZindex,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);

  // Spring animation for translateY
  const [{ y }, api] = useSpring(() => ({
    y: isVisible ? 0 : 100, // 0 = fully open, 100 = fully closed
    config: { tension: 170, friction: 20 }, // Adjust for smoothness
  }));

  // Update animation based on visibility
  useEffect(() => {
    api.start({ y: isVisible ? 0 : 100 });
  }, [isVisible, api]);

  const handleDragStart = (e) => {
    setIsDragging(true);
    setStartY(e.touches ? e.touches[0].clientY : e.clientY); // Record start position
  };

  const handleDragMove = (e) => {
    if (!isDragging) return;

    const currentY = e.touches ? e.touches[0].clientY : e.clientY;
    const deltaY = currentY - startY;
    api.start({ y: Math.min(Math.max(y.get() + deltaY, 0), 100) }); // Clamp between 0 and 100
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    if (y.get() > 50) {
      onClose(); // Close if dragged down halfway
    } else {
      api.start({ y: 0 }); // Snap back if not dragged enough
    }
  };

  const bookingInputs = JSON.parse(localStorage.getItem("bookingInputs"));
  const isRentalCoverageModal =  localStorage.getItem("isRentalCoverageModal")
  //${isRentalCoverageModal ? "overflow-hidden" : "customizedScrollBar overflow-scroll"}
  // console.log(isRentalCoverageModal);
  
  return (
    <animated.div
      style={{
        transform: y.to((value) => `translateY(${value}%)`), // Apply animated translateY to entire modal
        zIndex: zindex,
        height: height,
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        display: isVisible ? "block" : "none", // Ensure it displays only when visible
      }}
    >
      <Offcanvas
        show={isVisible}
        onHide={onClose}
        placement="bottom"
        style={{
          height: `${height}`, // Ensure full height for Offcanvas
          backgroundColor: "#262523",
        }}
        backdrop={false}
        scroll={false}
      >
        <div
          className="offcanvas-header d-flex flex-column text-white bg23"
          onMouseDown={handleDragStart}
          onMouseMove={handleDragMove}
          onMouseUp={handleDragEnd}
          onTouchStart={handleDragStart}
          onTouchMove={handleDragMove}
          onTouchEnd={handleDragEnd}
        >
          <div>
            <img className="notchImage" src={notchIcon} alt="Notch Icon" />
          </div>
          <div className="d-flex justify-content-between align-items-center p-2 w-100">
            <div className="fontSize17 fontAntarctica">{modalTopHeading}</div>
            <div>
              <img
                className="closeImage"
                src={redCloseIcon}
                alt="Close Icon"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
        <div className={`offcanvas-body bg23 ${isRentalCoverageModal === "true" ? "overflow-hidden" : "customizedScrollBar overflow-scroll"}`}>
          {children}
        </div>
      </Offcanvas>
    </animated.div>
  );
};

export default ModalContainer;
