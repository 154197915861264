import React from "react";
import { useSelector } from "react-redux";

const ShimmerUI = () => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const renderShimmerCards = (count) => {
    let shimmerCards = [];
    for (let i = 0; i < count; i++) {
      shimmerCards.push(
        <div
          key={i}
          className={`card col-3 col-sm-12 col-md-3 col-lg-3  p-3 ${
            isDarkMode ? "bg3B3936 text-white" : "lightMode"
          }`}
          aria-hidden="true"
        >
          <div className="placeholder-glow col-12 d-flex justify-content-between">
            <div className="d-flex flex-column placeholder-glow col-9 mb-3">
              <p className="placeholder  col-4 rounded rounded-1 m-1"></p>
              <p className="placeholder  col-2 rounded rounded-1 m-1"></p>
            </div>
            <div className="d-flex flex-column placeholder-glow col-12">
              <p className="placeholder  col-3 rounded rounded-1 m-1"></p>
              <p className="placeholder  col-2 rounded rounded-1 m-1 mx-4"></p>
            </div>
          </div>
          <div className="card-img-top shimmer card-img-top-shimmer"></div>
          <div className="card-title pt-3 placeholder-glow d-flex justify-content-between">
            <div className="placeholder-glow col-9">
              <p className="placeholder col-2 rounded rounded-1 m-1"></p>
              <p className="placeholder col-3 rounded rounded-1 m-1"></p>
            </div>
            <div className="placeholder-glow col-12">
              <p className="placeholder col-3 rounded rounded-1 mb-0"></p>
            </div>
          </div>
        </div>
      );
    }
    return shimmerCards;
  };

  return (
    <div className="d-flex justify-content-center align-items-start p-2 m-2 paddingTopCarList">
      <div className="d-flex justify-content-center w-100">
        <div className="d-flex justify-content-center justify-content-sm-center justify-content-md-start flex-wrap col-md-8 carsCardContainer customizedScrollBar fontSize13 overflow-x-hidden">
          {renderShimmerCards(6)}
        </div>
        <div className="py-2 d-flex flex-column justify-content-start">
          <div className="border3B3936 rounded rounded-4">
            <div className="filterBoxContainer d-none d-lg-block">
              <div className="p-3 width100 placeholder-glow">
                <span className="placeholder  col-4 rounded rounded-1 m-1"></span>
                <div className="placeholder-glow col-12">
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                </div>
                <hr className="bg3B3936 placeholder-glow " />
                <span className="placeholder col-4 rounded rounded-1 m-1"></span>
                <div className="placeholder-glow col-12">
                  <span className="placeholder  col-2 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-2 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-2 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-2 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-2 rounded rounded-1 m-1"></span>
                </div>
                <hr className="bg3B3936 placeholder-glow col-12" />
                <span className="placeholder  col-4 rounded rounded-1 m-1"></span>
                <div className="placeholder-glow col-12">
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                </div>
                <hr className="bg3B3936 placeholder-glow col-8" />
                <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                <div className="placeholder-glow col-12">
                  <span className="placeholder  col-2 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-2 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-2 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-2 rounded rounded-1 m-1"></span>
                </div>
                <hr className="bg3B3936 placeholder-glow" />
                <span className="placeholder  col-5 rounded rounded-1 m-1"></span>
                <div className="placeholder-glow col-12">
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                </div>
                <hr className="bg3B3936 placeholder-glow" />
                <span className="placeholder  col-5 rounded rounded-1 m-1"></span>
                <div className="placeholder-glow col-12">
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                  <span className="placeholder  col-3 rounded rounded-1 m-1"></span>
                </div>
                <hr className="bg3B3936 placeholder-glow" />
                <div className="placeholder-glow col-12 d-flex justify-content-between align-items-center p-2">
                  <button
                    className={`btn placeholder col-5 rounded rounded-3 ${
                      isDarkMode ? "btn-dark" : "btn-light"
                    }`}
                  ></button>
                  <button
                    className={`btn placeholder col-5 rounded rounded-3 ${
                      isDarkMode ? "btn-dark" : "btn-light"
                    }`}
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShimmerUI;
