import { useSelector } from "react-redux";
import {content2} from "../data/content"
import { content1 } from "../data/content";
import { Fragment } from "react";

const DiscoverContainer = ({marginTop}) => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const {title, description} = content2
  const { title1, title2, locations } = content1;
  return (
    <div className={`${isDarkMode ? "darkMode fontcolorFFF" : "lightMode fontcolor000"} w-100 ${marginTop}`}>
      <div className="col-12 col-md-7 m-auto text-center p-3" >
        {/* <div className="fontWeight400-A fs-3 p-3">{title}</div> */}
        <div>
              <div className="fontAntarctica EvRentalText fontWeight400 p-1 p-lg-3 mb-1 d-none d-lg-block">
                <div>{title1}</div>
                <div>{title2}</div>
              </div>
              <div className="fontAntarctica EvRentalText fontWeight400 p-1 p-lg-3 mb-1 d-block d-lg-none">
                <div>{title1} {title2}</div>
              </div>
              <div className="text-center d-flex justify-content-center fontAntarctica fontSize17">
                {locations.map((location, index) => {
                  return (
                    <Fragment key={index}>
                      <p className="px-2">{location}</p>
                      {index < locations.length - 1 && <span>|</span>}
                    </Fragment>
                  );
                })}
              </div>
            </div>
        <div className="fontAntarctica fontSize17 fontColorE0DCD3">{description}</div>
      </div>
    </div>
  )
}

export default DiscoverContainer
